import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const ModalPage = (props) => {
    const handleClose = () => props.setOpen(false)
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        overflow : "hidden",
        maxHeight: props.height ?? "90vh",
        width: props.width ?? 400,
        bgcolor: 'background.paper',
        border: '1px solid #ccc',
        boxShadow: 24,
        padding : props.padding ?? '1.75rem 1.1rem',
        borderRadius : '15px'
      };
      
    return (
        <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className='z-[9999999]'
        >
            <Box sx={style}>
               {props.children}
            </Box>
        </Modal>
    )
}

export default ModalPage