import { createSlice } from '@reduxjs/toolkit';

const initialVehicleState = {
    vehicleImei: null,
    vehiclePlate: null,
    vehicleName: null,
    vehicleBrand: null,
    vehicleModel: null,
    vehicleFuelTankCapacity: null, 
};

const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: initialVehicleState,
  reducers: {
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
  },
});

export default vehicleSlice;
