import React, { useEffect, useState, useRef } from 'react';
import { Button, Container, Nav } from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import { subDays } from 'date-fns';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Col, Row, Form } from 'react-bootstrap';
import BarChart from '../Charts/BarChart';
import './index.css';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import ClusteredChart from '../Charts/ClusteredChart';
import { tr } from 'date-fns/locale';
import { DayPicker } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';
const XLSX = require('xlsx');

const VehicleReports = () => {
  const chartReports = [];
  const clusteredChartReports = [];
  const [reports, setReports] = useState([]);
  const [chartReportsState, setChartReports] = useState();
  const [loader, setLoader] = useState(false);
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [posStack, setPosStack] = useState([]);
  const [clusteredChartReportsState, setClusteredChartReportsState] =
    useState();
  const [buttonClickCount, setButtonClickCount] = useState(0);

  const getDevices = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumcihazlar?companyName=${localStorage.getItem(
        'userCompany'
      )}&userRole=${localStorage.getItem('userRole')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
        setDevices(response.data);
      });
  };

  useEffect(() => {
    getDevices();
  }, []);

  const pastMonth = new Date();
  const defaultSelected = {
    from: subDays(pastMonth, 6),
    to: pastMonth,
  };
  const [range, setRange] = useState(defaultSelected);

  // useRef() kullanarak Tamamla butonuna bir referans oluşturma
  const buttonRef = useRef();

  // Cihazın seçilip seçilmediğini kontrol etmek için bir durum değişkeni
  const [isDeviceSelected, setIsDeviceSelected] = useState(false);

  // useEffect() kullanarak cihaz seçildiğinde "isDeviceSelected" durumunun true değerine dönüştürülmesi
  useEffect(() => {
    if (selectedDevice) {
      setIsDeviceSelected(true);
    } else {
      setIsDeviceSelected(false);
    }
  }, [selectedDevice]);

  const date1 = new Date(range?.from);
  const date2 = new Date(range?.to);

  let diffMinutes;
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  if (
    typeof range?.to !== 'undefined' &&
    typeof range?.from !== 'undefined' &&
    range.to !== range.from
  ) {
    diffMinutes = Math.floor(timeDiff / (1000 * 3600 * 24));
    if (typeof diffMinutes === 'NaN') diffMinutes = 1;
    else diffMinutes = diffMinutes + 1;
  } else if (range?.to === range?.from || typeof range?.to === 'undefined') {
    diffMinutes = 1;
  }

  let excelArray = [];
  async function handleGetLogs() {
    setButtonClickCount(1)
    if (typeof range.from === 'undefined') return;
    setLoader(true);
    setChartReports(null);
    setClusteredChartReportsState(null);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/arac/raporlar`,
        {
          vehicleImei: selectedDevice,
          startDate:
            moment(range.from).format('YYYY-MM-DD 00:00:00') ??
            moment(new Date()).format('YYYY-MM-DD 00:00:00'),
          endDate:
            (range.to && moment(range.to).format('YYYY-MM-DD 23:59:59')) ??
            moment(range.from).format('YYYY-MM-DD 23:59:59'),
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        console.log("vehiclereports=>",res);
        setLoader(false);
        setReports(res.data);

        res.data.map((item, index) => {
          setPosStack((prev) => {
            return [...prev, item.posStack];
          });
          if (
            parseInt(item.hardbreakcount) +
            parseInt(item.hardturncount) +
            parseInt(item.speedlimitviolationcount) +
            parseInt(item.suddenaccelerationcount) !=
            0
          ) {
            chartReports.push({
              vehicleplate: item.vehicleplate,
              date: moment(item.date).format('YYYY-MM-DD'),
              // item.reporttype == 'single'
              //     ? item.date.split(' ')[1] == '07:00:00'
              //       ? item.date.split(' ')[0] + ' Sabah'
              //       : item.date.split(' ')[0] + ' Akşam'
              //     : moment(item.date).add(1, 's').format('YYYY-MM-DD HH:mm:ss'),
              hardbreakcount: parseInt(item.hardbreakcount),
              hardturncount: parseInt(item.hardturncount),
              speedlimitviolationcount: parseInt(item.speedlimitviolationcount),
              suddenaccelerationcount: parseInt(item.suddenaccelerationcount),
            });
          }

          clusteredChartReports.push({
            vehicleplate: item.vehicleplate,
            totalkm: parseInt(item.totalkm),
            date: moment(item.date).format('YYYY-MM-DD'),
            'Aktif Çalışma Süresi': parseInt(item.totalactiveworkingtime),
            'Çalışma Süresi': parseInt(item.totalworkingtime),
            'Rölantide Çalışma Süresi':
              item.totalworkingtime - item.totalactiveworkingtime,
          });
          excelArray.push({
            'Araç Plakası': item.vehicleplate,
            'Vardiya Tarihi': moment(item.date).format('YYYY-MM-DD'),
            'Vardiya Saati': moment(item.date).format('HH:mm'),
            'Vardiya Başlangıç Saati': item.shiftStartingTime
              ? moment(item.shiftStartingTime).format('HH:mm')
              : 'Yok',
            'Vardiya Bitiş Saati': item.shiftEndingTime
              ? moment(item.shiftEndingTime).format('HH:mm')
              : 'Yok',
            'Toplam Yol': parseFloat(item.totalkm).toFixed(2),
            'Toplam Çalışma Süresi':
              Math.floor(item.totalworkingtime / 60) +
              ' Saat ' +
              (parseInt(item.totalworkingtime) % 60) +
              ' Dakika',
            'Toplam Aktif Çalışma Süresi':
              Math.floor(item.totalactiveworkingtime / 60) +
              ' Saat ' +
              (parseInt(item.totalactiveworkingtime) % 60) +
              ' Dakika',
            'Rölantide Çalışma Süresi':
              Math.floor(
                (item.totalworkingtime - item.totalactiveworkingtime) / 60
              ) +
              ' Saat ' +
              (parseInt(item.totalworkingtime - item.totalactiveworkingtime) %
                60) +
              ' Dakika',
            'Yaktı Tüketimi': `${parseInt(item.fuelConsumption)}`,
            'Motor Saati Farkı': `${parseInt(item.enginehourdiff)}:${String(item.enginehourdiff % 1).split('.')[1] !== undefined
                ? Math.floor(
                  (parseFloat(item.enginehourdiff % 1)
                    .toFixed(2)
                    .split('.')[1] *
                    6) /
                  10
                )
                : 0
              }`,
            'Ani Fren Sayısı': item.hardbreakcount,
            'Sert Dönüş Sayısı': item.hardturncount,
            'Hız Limiti İhlali': item.speedlimitviolationcount,
            'Ani Hızlanma Sayısı': item.suddenaccelerationcount,
            'Yakıt Tüketimi': item.fuelConsumption,
          });
        });

        //////////////////////////
        let totalCount = 0;
        chartReports.forEach((item) => {
          totalCount +=
            item.hardbreakcount +
            item.hardturncount +
            item.speedlimitviolationcount +
            item.suddenaccelerationcount;
        });

        totalCount === 0 ? setChartReports(null) : setChartReports(chartReports);
        clusteredChartReports.length === 0
          ? setClusteredChartReportsState(null)
          : setClusteredChartReportsState(clusteredChartReports);

        //////////////////////////////////////////////////////
        let totalCountClustred = 0;
        clusteredChartReports.forEach((item) => {
          totalCountClustred += item.totalkm;
        });
        totalCountClustred === 0
          ? setClusteredChartReportsState(null)
          : setClusteredChartReportsState(clusteredChartReports);

        // setineChartReports(lineChartReports);
        // setClusteredChartReportsState(clusteredChartReports);
        generateGraphs();
      });
  }

  //Generate Graphs
  const generateGraphs = () => {
    for (let i = 0; i < chartReports.length; i++) {
      if (chartReports[i].hardbreakcount) console.log('var');
    }
  };

  //Create Excel File
  const handleExportExcel = async () => {
    let filename = `${moment(new Date()).format(
      'DD-MM-YYYY HH:mm'
    )} AraçRaporları.xlsx`;
    await handleGetLogs();
    var ws = XLSX.utils.json_to_sheet(excelArray);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reports Data');
    XLSX.writeFile(wb, filename);
  };

  const handleSetSelectedDevice = (e) => {
    setSelectedDevice(e.target.value);
  };

  let footer = (
    <>
      <button
        className='btn btn-primary mt-5 mb-1 w-100'
        onClick={() => setRange({})}
      >
        Seçimi Sıfırla
      </button>
      <button
        ref={buttonRef}
        className='btn w-100 btn-primary mt-5 mb-1'
        onClick={handleGetLogs}
        disabled={!isDeviceSelected}
      >
        Getir
      </button>
      <button
        ref={buttonRef}
        className='btn w-100 btn-success mt-5 mb-1'
        onClick={handleExportExcel}
        disabled={!isDeviceSelected}
      >
        Excel Dosyası Oluştur
      </button>
    </>
  );

  const columns = [
    {
      name: 'Araç Plakası',
      selector: (row) => row.vehicleplate,
    },
    {
      name: 'Vardiya Tarihi',
      selector: (row) => moment(row.reportDate).format('YYYY-MM-DD'),
      sortable: true,
    },
    {
      name: 'Vardiya Saati',
      selector: (row) => moment(row.reportDate).format('HH:mm'),
      sortable: true,
    },
    {
      name: 'Vardiya Başlangıç Saati',
      selector: (row) => row.shiftStartingTime,
      sortable: true,
    },
    {
      name: 'Vardiya Bitiş Saati',
      selector: (row) => row.shiftEndingTime,
      sortable: true,
    },
    {
      name: 'Toplam Yol (KM)',
      selector: (row) => row.totalkm,
      sortable: true,
    },
    {
      name: 'Toplam Çalışma',
      selector: (row) => addSifir(row.totalworkingtime),
      sortable: true,
    },
    {
      name: 'Toplam Aktif Çalışma',
      selector: (row) => addSifir(row.totalactiveworkingtime),
      sortable: true,
    },
    {
      name: 'Toplam Rölantide Çalışma',
      selector: (row) => addSifir(row.totalengineworkingtime),
      sortable: true,
    },
    {
      name: 'Yakıt Tüketimi (L)',
      selector: (row) => row.fuelConsumption + ' L',
    },
    {
      name: 'Yakıt Tüketimi (Ortalama)',
      selector: (row) => parseFloat(row.totalworkingtime) > 0 ? row.fuelConsumptionPerHour.toFixed(2) + ' L/H' : 0 + ' L/H',
    },
    {
      name: 'Motor Saat farkı (H)',
      selector: (row) => addSifir(row.enginespeeddifference),
      sortable: true,
    },
    // {
    //   name: 'Tur Sayısı',
    //   cell: (row) =>
    //     (row.posStack.poly1.dateValues || row.posStack.poly2.dateValues) &&
    //       row.posStack.poly1.dateValues[row.reportDate] ? (
    //       row.shiftType === 'Sabah' ? (
    //         <p>
    //           {row.posStack.poly1.dateValues[row.reportDate]?.v1 >
    //             row.posStack.poly2.dateValues[row.reportDate]?.v1
    //             ? row.posStack.poly2.dateValues[row.reportDate]
    //               ?.v1
    //             : row.posStack.poly1.dateValues[row.reportDate]
    //               ?.v1}
    //         </p>
    //       ) : (
    //         <p>
    //           {row.posStack.poly1.dateValues[row.reportDate]?.v2 >
    //             row.posStack.poly2.dateValues[row.reportDate]?.v2
    //             ? row.posStack.poly2.dateValues[row.reportDate]
    //               ?.v2
    //             : row.posStack.poly1.dateValues[row.reportDate]
    //               ?.v2}
    //         </p>
    //       )
    //     ) : (
    //       0
    //     ),
    //   sortable: true,
    // },
    {
      name: 'Ani Fren',
      selector: (row) => row.hardbreakcount,
      sortable: true,
    },
    {
      name: 'Sert Dönüş',
      selector: (row) => row.hardturncount,
      sortable: true,
    },
    {
      name: 'Hız Sınırı Aşımı',
      selector: (row) => row.speedlimitviolationcount,
      sortable: true,
    },
    {
      name: 'Ani Hızlanma',
      selector: (row) => row.suddenaccelerationcount,
      sortable: true,
    },
  ];
  const addSifir = (b) => {
    let a = b.split(':');
    if (a[0] < 10) a[0] = '0' + a[0];
    if (a[1] < 10) a[1] = '0' + a[1];
    return a[0] + ':' + a[1];
  };

  let data;
  if (reports.length > 0) {
    data = reports.map((item, index) => {
      return {
        vehicleplate: item.vehicleplate,
        reportDate: moment(item.date).format('YYYY-MM-DD HH:mm'),
        shiftType: item.reporttype === 'single' ? item.shiftType : null,
        totalkm: parseFloat(item.totalkm).toFixed(2),
        totalworkingtime:
          Math.floor(item.totalworkingtime / 60) +
          ':' +
          (parseInt(item.totalworkingtime) % 60),
        totalactiveworkingtime:
          Math.floor(item.totalactiveworkingtime / 60) +
          ':' +
          (parseInt(item.totalactiveworkingtime) % 60),
        totalengineworkingtime:
          Math.floor(
            (item.totalworkingtime - item.totalactiveworkingtime) / 60
          ) +
          ':' +
          (parseInt(item.totalworkingtime - item.totalactiveworkingtime) % 60),
        hardbreakcount: item.hardbreakcount,
        hardturncount: item.hardturncount,
        speedlimitviolationcount: item.speedlimitviolationcount,
        suddenaccelerationcount: item.suddenaccelerationcount,
        enginespeeddifference: `${parseInt(item.enginehourdiff)}:${String(item.enginehourdiff % 1).split('.')[1] !== undefined
            ? Math.floor(
              (parseFloat(item.enginehourdiff % 1)
                .toFixed(2)
                .split('.')[1] *
                6) /
              10
            )
            : 0
          }`,
        shiftStartingTime: item.shiftStartingTime
          ? moment(item.shiftStartingTime).format('HH:mm')
          : 'Yok',
        fuelConsumption: parseInt(item.fuelConsumption),
        fuelConsumptionPerHour: parseFloat(item.fuelConsumption) / parseFloat(item.enginehourdiff),
        posStack: item.posStack,
        shiftEndingTime: item.shiftEndingTime
          ? moment(item.shiftEndingTime).format('HH:mm')
          : 'Yok',
      };
    });
  }

  let customStyles = {
    rows: {
      style: {
        minHeight: '42px', // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        fontSize: '13px',
        color: '#000',
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };

  const modifiersStyles = {
    selected: {
      color: 'white',
      backgroundColor: '#0d6dfd',
    },
  };

  const classNames = {
    ...styles,
    head: 'custom-head',
    disabled: 'custom-disabled',
  };

  const handleSetChange = (e) => {
    // if(e.to == undefined) {
    //   // e.to = e.from
    // }
    setRange(e);
  };

  return (
    <>
      <div className='content mt-[3.75rem]'>
        <Container>
          <div className='wrapper'>
            <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
              Araç-Rapor Sayfası
            </h3>
            <Row>
              <Col xs={6} sm={12} md={6} lg={4} xl={3}>
                <Form>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Label>Araç Seçiniz</Form.Label>
                    <Form.Select onChange={handleSetSelectedDevice}>
                      <option selected disabled>
                        Araç Seçiniz
                      </option>
                      {devices.length > 0 &&
                        devices.map((device) => {
                          return (
                            <option value={device.deviceImei} key={device._id}>
                              {device.deviceName}
                            </option>
                          );
                        })}
                    </Form.Select>
                    <Nav.Link target='_blank' href={'/arac/raporlar/all'}>
                      <Button
                        className='btn btn-sm mt-3'
                        variant='outline-primary'
                      >
                        Tüm Araçlar
                      </Button>
                    </Nav.Link>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs={6} sm={12} md={6} lg={4} xl={3}>
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loader}
                >
                  <CircularProgress color='inherit' />
                </Backdrop>
                <div className='d-flex justify-content-center'>
                  <DayPicker
                    locale={tr}
                    classNames={classNames}
                    className='md:mx-auto'
                    mode='range'
                    selected={range}
                    onSelect={handleSetChange}
                    modifiersStyles={modifiersStyles}
                    modifiers={range}
                    // max={7}
                    toDate={new Date()}
                    footer={footer}
                  />
                </div>
              </Col>
              <Col>
                {clusteredChartReportsState ? (
                  <ClusteredChart
                    fieldValue='date'
                    data={clusteredChartReportsState}
                  />
                ) : (
                  <div className='h-full flex justify-center items-center bg-gradient-to-b from-slate-100 to-blue-100'>
                    <div className='text-center'>
                      <h1 className='text-2xl font-bold'>
                        {buttonClickCount === 0 ? (
                          <p>
                            Raporları üretmek için "Araç" seçimi yaptıktan sonra
                            "Getir" butonuna tıklayınız.
                          </p>
                        ) : (
                          <p>Seçili tarihler arasında veri bulunamadı.</p>
                        )}
                      </h1>
                      {/* <p className="text-slate-500"> <span className='animate-pulse cursor-pointer select-none text-sky-700'>Buraya</span> tıklayarak ya da sol üst köşeden farklı bir tarih seçebilirsiniz..</p> */}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              {chartReportsState && excelArray && (
                <BarChart fieldValue='date' data={chartReportsState} />
                // <ClusteredChart
                // fieldValue='date'
                // data={clusteredChartReportsState}
                // />
              )}
            </Row>
            <Row>
              {reports.length > 0 && (
                <Row lg='9'>
                  <h1 className='font-cinzel text-center my-[2rem] text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700 card-title'>
                    Raporlar
                  </h1>
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    responsive
                    customStyles={customStyles}
                  />
                </Row>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default VehicleReports;
