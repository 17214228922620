import React, { useEffect, useState } from 'react'
import Slider from '@mui/material/Slider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import ClearIcon from '@mui/icons-material/Clear';
import moment from "moment"

const ControlArea = ({ data, counter, setCounter, date }) => {
    function valuetext(value) {
        return `${value}°C`;
    }
    const [playSpeed, setPlaySpeed] = useState(750);
    const [intervalId, setIntervalId] = useState(null);
    const [animateStatus, setAnimateStatus] = useState("stop");

    // stop play when page changed or refreshed or date changed
    useEffect(() => {
            clearInterval(intervalId);
            setCounter(1);
            setPlaySpeed(750)
            setAnimateStatus("stop");
    }, [date])

    const controlAnimate = (control, playspeed = 750) => {
        if (control === "start") {
            const id = setInterval(() => {
                setCounter((prevCounter) => {
                    if (prevCounter > data.length - 2) {
                        clearInterval(id);
                        setPlaySpeed(750)
                        setAnimateStatus("stop");
                        return 1;
                    } else {
                        return prevCounter + 1;
                    }
                });
            }, playspeed);
            setIntervalId(id);
            setAnimateStatus("play");
        } else if (control === "pause") {
            clearInterval(intervalId);
            setAnimateStatus("pause");
        } else if (control === "stop") {
            clearInterval(intervalId);
            setCounter(1);
            setPlaySpeed(750)
            setAnimateStatus("stop");
        } else if (control === "updateRefreshTime") {
            clearInterval(intervalId);
            const id = setInterval(() => {
                setCounter((prevCounter) => {
                    if (prevCounter > data.length - 2) {
                        clearInterval(id);
                        setPlaySpeed(750)
                        setAnimateStatus("stop");
                        return 1;
                    } else {
                        return prevCounter + 1;
                    }
                });
            }, playspeed);
            setIntervalId(id);
        }
    };

    const handlePlaySpeed = () => {
        if (animateStatus != "play") return;
        if (playSpeed == 750) {
            setPlaySpeed(500);
        } else if (playSpeed == 500) {
            setPlaySpeed(250);
        } else if (playSpeed == 250) {
            setPlaySpeed(750);
        }
        if (animateStatus === "play")
            controlAnimate("updateRefreshTime", playSpeed == 750 ? 500 : playSpeed == 500 ? 250 : 750);
    };

    const animateText = (status) => {
        if (status === "play") {
            return moment(data[counter - 1].deviceDate).format("HH:mm:ss");
        } else if (status === "pause") {
            return "Paused"
        } else if (status === "Stop") {
            return ""
        }
    }

    return (
        <div className='absolute bottom-3 w-[95%] left-1/2 -translate-x-1/2 rounded-lg h-12 boxColor z-[999] flex gap-6 px-6 items-center justify-center'>
            <div className="controls flex gap-3">
                {(animateStatus === "stop" || animateStatus === "pause") && <PlayArrowIcon className='cursor-pointer hover:text-red-300' onClick={() => controlAnimate("start")} />}
                {animateStatus === "play" && <PauseIcon className='cursor-pointer hover:text-red-300' onClick={() => controlAnimate("pause")} />}
                <StopIcon className='cursor-pointer hover:text-red-300' onClick={() => controlAnimate("stop")} />
                <span className='cursor-pointer select-none' onClick={handlePlaySpeed}>{playSpeed == 750 ? "1x" : playSpeed == 500 ? "2x" : "3x"}</span>
            </div>
            <div className="animateText">
                {animateText(animateStatus)}
            </div>
            <div className="trackbar w-full">
                <Slider
                    aria-label="Date"
                    defaultValue={30}
                    getAriaValueText={valuetext}
                    valueLabelDisplay="auto"
                    size='small'
                    color="error"
                    step={1}
                    marks
                    min={1}
                    max={data.length}
                    label="Seconds"
                    value={counter}
                    onChange={(e, value) => setCounter(value)}
                />
            </div>
        </div>
    )
}

export default ControlArea