import Login from './components/Login';
import Register from './components/Register';
import Groups from './components/Groups';
import Companies from './components/Companies';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Users from './components/Users';
import Devices from './components/Devices';
import DevicesData from './components/DevicesData';
import Unauthorized from './components/Unauthorized';
import Test from './components/Test';
import Device from './components/Device';
import Logs from './components/Logs';
import GpsLogs from './components/GpsLogs';
import VehicleList from './components/Vehicles/VehicleList';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import NextDashboard from './components/NextDashboard';
import Shifts from './components/Shifts';

import DriverReports from './components/DriverReports';
import Drivers from './components/Drivers';

import MapZones from './components/MapZones';
import AlarmHistory from './components/AlarmHistory';
import MovingHistory from './components/MovingHistory';
import MovingTrack from './components/MovingTrack';
import UserSettings from './components/UserSettings';
import AllTimeDriverReports from './components/DriverReports/AllTimeDriverReports';
import { useEffect } from 'react';
import ShiftLogs from './components/ShiftReports';
import VehicleReports from './components/VehicleReports/VehicleReports';
import VehicleReportsAll from './components/VehicleReports/VehicleReportsAll';
import { isExpired } from 'react-jwt';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) navigate('/giris');
    const token = localStorage.getItem('token');
    const isMyTokenExpired = isExpired(token);
    if (isMyTokenExpired) {
      localStorage.removeItem('token');
      localStorage.removeItem('userRole');
      localStorage.removeItem('user');
      localStorage.removeItem('companyLogo');
      navigate('/giris');
    }
  }, [navigate]);
  return (
    <div className='App'>
      {/* <Navigation /> */}
      {location.pathname !== '/giris' && location.pathname !== '/test' && (
        <>
          <Header />
          <Sidebar />
        </>
      )}

      <Routes>
        {/* App Routes */}
        <Route path='/giris' exact element={<Login />} />
        <Route path='/' element={<NextDashboard />} />
        <Route path='/kayitol' exact element={<Register />} />
        <Route path='/departmanlar' exact element={<Groups />} />
        <Route path='/logs' exact element={<Logs />} />
        <Route path='/gpslogs' exact element={<GpsLogs />} />
        <Route path='/firmalar' exact element={<Companies />} />
        <Route path='/cihazlar' exact element={<Devices />} />
        <Route path='/devices/:id' exact element={<Device />} />
        <Route path='/devicesdata' exact element={<DevicesData />} />
        <Route path='/kullanicilar' exact element={<Users />} />
        <Route path='/suruculer' exact element={<Drivers />} />
        <Route path='/unauthorized' exact element={<Unauthorized />} />
        <Route path='/test' exact element={<Test />} />
        <Route path='/araclar/liste' exact element={<VehicleList />} />
        <Route path='/vardiyalar' exact element={<Shifts />} />
        {/* <Route path='/arac/raporlar' exact element={<Reports />} /> */}
        {/* <Route path='/arac/raporlar/alltime' exact element={<AllTimeReports />} /> */}
        <Route path='/arac/raporlar' exact element={<VehicleReports />} />
        <Route
          path='/arac/raporlar/all'
          exact
          element={<VehicleReportsAll />}
        />
        <Route path='/surucu/raporlar' exact element={<DriverReports />} />
        <Route
          path='/surucu/raporlar/all'
          exact
          element={<AllTimeDriverReports />}
        />
        <Route path='/arac/bolgetanimlama' exact element={<MapZones />} />
        {/* <Route path='/arac/gecmis/' exact element={<DeviceHistoryInfo />} /> */}
        <Route path='/arac/alarm/gecmis' exact element={<AlarmHistory />} />
        <Route path='/arac/hareket/gecmis' exact element={<MovingHistory />} />
        <Route path='/vehicles/movetrack' exact element={<MovingTrack />} />
        <Route path='/kullanici/ayarlar' exact element={<UserSettings />} />
        <Route path='/vardiya/raporlar' exact element={<ShiftLogs />} />
        <Route path='/test' exact element={<Test />} />
      </Routes>
    </div>
  );
}

export default App;
