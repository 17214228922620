import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
  _id: null,
  userEmail: null,
  userPassword: null,
  userRole: null,
  userAuthorizedPersonel: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
  },
});

export default userSlice;
