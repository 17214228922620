import axios from 'axios';

//Get All Users
const getAllDevicesTestData = async () => {
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/devicetestdata`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => JSON.parse(response.data[0].data).records)
};

export default getAllDevicesTestData;