import React, { useEffect, useRef, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Button } from '@mui/material';

import { Container } from '@mui/system';
import ModalPage from '../Modal';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Form from 'react-bootstrap/Form';
import MapPolygone from './MapPolygone';
import { MapContainer, TileLayer, ZoomControl, Polygon } from 'react-leaflet';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import Snackbar from '../Snackbar';
import { Col, Row } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/Info';

const GpsHistory = () => {
  const position = [41.2845016, 28.7746];
  const [loader, setLoader] = useState(false);

  const [positions, setPositions] = useState([]);
  const [positions2, setPositions2] = useState([]);
  const [responseData, setResponseData] = useState(null);

  const [devices, setDevices] = useState([]);

  const getDevices = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumcihazlar?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
        setDevices(response.data);
      });
  };

  useEffect(() => {
    getDevices();
  }, []);

  const [snack, setSnack] = useState({ message: null, show: false });
  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/tumbolgeler?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(function (response) {
        setPositions(JSON.parse(response.data[0]?.zoneCoords));
        setPositions2(JSON.parse(response.data[1]?.zoneCoords));
        setResponseData(response.data);
        setLoader(false);
      });
  }, []);
  const handleActiveChange = (e, which) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/bolge/duzenle`,
        { zoneId: responseData[which]._id, isActive: e.target.checked },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then(function (response) {
        setSnack((prev) => {
          return { ...prev, show: true, message: 'Success' };
        });
      });
  };

  const [mapOpen, setMapOpen] = useState(false);
  const [mapOpen2, setMapOpen2] = useState(false);
  const polygonOptions = {
    color: 'red',
    fillColor: '#f03',
    fillOpacity: 0.5,
  };

  function findCenter(coords) {
    if (coords == null) return position;
    let longitudes = coords.map(coord => coord[0]);
    let latitudes = coords.map(coord => coord[1]);

    let totalLongitude = longitudes.reduce((a, b) => a + b, 0);
    let totalLatitude = latitudes.reduce((a, b) => a + b, 0);

    let avgLongitude = totalLongitude / coords.length;
    let avgLatitude = totalLatitude / coords.length;

    let avgCoords = [avgLongitude, avgLatitude];

    return avgCoords;
  }

  const handleUpdateCoords = (coords) => {
    let which;
    if (mapOpen == true) which = 0;
    if (mapOpen2 == true) which = 1;
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/bolge/duzenle`,
        { zoneId: responseData[which]._id, zoneCoords: JSON.stringify(coords) },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then(function (response) {
        setLoader(false);
        setSnack((prev) => {
          return { ...prev, show: true, message: 'Success' };
        });
      });
  };

  const handleMapOpen = (e) => {
    let selectedMap = e.target.dataset.map;
    if (selectedMap == 1) setMapOpen(true);
    if (selectedMap == 2) setMapOpen2(true);
  };

  if (!responseData)
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    );

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      <Snackbar data={snack} setData={setSnack} />
      <div className='content mt-[3.5rem]'>

        <ModalPage open={mapOpen} setOpen={setMapOpen} width='70vw'>
          <span className='text-[0.8em] absolute w-[85%] h-auto'><InfoIcon /> Haritada çizmeye başlayacağınız yere çift tıklayarak çizime başlayabilir, sonrasında tek tıklayarak çizimi devam ettirebilir, son olarak tekrar çift tıklayarak uçları birleştirip çizimi bitirebilirsiniz.</span>
          <Button onClick={() => setMapOpen(false)} className='ml-auto block'>
            Kapat
          </Button>
          <div className='overflow-hidden mt-3'>
            <MapPolygone
              centerFunc={findCenter}
              positions={positions}
              setPositions={setPositions}
              position={position}
              setMapOpen={setMapOpen}
              updateCoords={handleUpdateCoords}
            />
          </div>
        </ModalPage>

        <ModalPage open={mapOpen2} setOpen={setMapOpen2} width='70vw'>
          <span className='text-[0.8em] absolute w-[85%] h-auto'><InfoIcon /> Haritada çizmeye başlayacağınız yere çift tıklayarak çizime başlayabilir, sonrasında tek tıklayarak çizimi devam ettirebilir, son olarak tekrar çift tıklayarak uçları birleştirip çizimi bitirebilirsiniz.</span>
          <Button onClick={() => setMapOpen2(false)} className='ml-auto block'>
            Kapat
          </Button>
          <div className='overflow-hidden mt-3'>
            <MapPolygone
              centerFunc={findCenter}
              positions={positions2}
              setPositions={setPositions2}
              position={position}
              setMapOpen={setMapOpen2}
              updateCoords={handleUpdateCoords}
            />
          </div>
        </ModalPage>

        <Container>
          <div className='wrapper'>
            <h1 className='font-cinzel mb-5 text-[1.5rem] relative after:bottom-[-5px] after:w-[100%] after:bg-red-700 after:h-[3px] after:absolute after:left-0'>
              Bölge Tanımlama
            </h1>
            {/*             <Row>
              <Col sm='3' md='3' lg='3'>
                <Form>
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Select>
                      <option>Cihaz Seç</option>
                      {devices.length > 0 &&
                        devices.map((device) => {
                          return (
                            <option key={device._id}>
                              {device.deviceName}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>
            </Row> */}
            <div className='flex flex-1 justify-center gap-3'>
              <div className='zone1 w-[48%]'>
                <Card className='shadow-lg'>
                  <CardContent>
                    <MapContainer
                      center={findCenter(positions ?? null)}
                      zoom={14}
                      className='w-full h-[300px] cursor-crosshair'
                      doubleClickZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                      />
                      {positions.length > 1 && (
                        <Polygon
                          positions={positions}
                          pathOptions={polygonOptions}
                        />
                      )}
                      <ZoomControl position='topright' />
                    </MapContainer>
                  </CardContent>
                  <CardContent>
                    <div className='font-cinzel font-bold block'>
                      Bölge Adı :{' '}
                      <span className='font-normal'>
                        {responseData[0].zoneName}
                      </span>
                    </div>
                    <span className='font-cinzel font-bold'>Durum : </span>
                    <FormGroup className='inline'>
                      <FormControlLabel
                        onChange={(e) => handleActiveChange(e, 0)}
                        control={
                          <Switch defaultChecked={responseData[0].isActive} />
                        }
                      />
                    </FormGroup>
                  </CardContent>
                  <CardActions>
                    <Button data-map='1' size='small' onClick={handleMapOpen}>
                      Yeni Bölge Seç
                    </Button>
                  </CardActions>
                </Card>
              </div>

              <div className='zone2 w-[48%]'>
                <Card className='shadow-lg'>
                  <CardContent>
                    <MapContainer
                      center={findCenter(positions2 ?? null)}
                      zoom={14}
                      className='w-full h-[300px] cursor-crosshair'
                      doubleClickZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                      />
                      {positions2.length > 1 && (
                        <Polygon
                          positions={positions2}
                          pathOptions={polygonOptions}
                        />
                      )}
                      <ZoomControl position='topright' />
                    </MapContainer>
                  </CardContent>
                  <CardContent>
                    <div className='font-cinzel font-bold block'>
                      Bölge Adı :{' '}
                      <span className='font-normal'>
                        {responseData[1].zoneName}
                      </span>
                    </div>
                    <span className='font-cinzel font-bold'>Durum : </span>
                    <FormGroup className='inline'>
                      <FormControlLabel
                        onChange={(e) => handleActiveChange(e, 1)}
                        control={
                          <Switch defaultChecked={responseData[1].isActive} />
                        }
                      />
                    </FormGroup>
                  </CardContent>
                  <CardActions>
                    <Button data-map='2' onClick={handleMapOpen} size='small'>
                      Yeni Bölge Seç
                    </Button>
                  </CardActions>
                </Card>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default GpsHistory;
