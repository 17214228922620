import groupSlice from '../slices/groupSlice';
import getAllGroups from '../../handlers/getAllGroups';

export const groupActions = groupSlice.actions;

//Get all groups
export const fetchGroups = () => {
  return async (dispatch) => {
    const response = await getAllGroups();
    dispatch(groupActions.setGroups(response));
  };
};