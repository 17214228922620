import React from "react";
import Nav from "react-bootstrap/Nav";

import './Sidebar.css';

const Header = () => {

  return (
    <>
      <section id="wrapper">
        <nav className="navbar navbar-expand-md">
          <div className="container-fluid mx-2 h-[48px]">
            <div className="navbar-header">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#toggle-navbar"
                aria-controls="toggle-navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="uil-bars text-white"></i>
              </button>
              <Nav.Link className="navbar-brand font-bold" href="/">
                <img alt="logo" src="/images/logo4en.png" width={490} height="85" style={{ width: 175, height: "auto" }}></img>
              </Nav.Link>
            </div>

          </div>
        </nav>


      </section>
    </>
  );
};

export default Header;
