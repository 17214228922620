import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { useNavigate, useParams } from 'react-router-dom';
import mqtt from 'mqtt/dist/mqtt';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import * as L from 'leaflet';
import axios from 'axios';
import { Circle, MapContainer, Polyline, TileLayer } from 'react-leaflet';
import moment from 'moment';
import Button from 'react-bootstrap/esm/Button';
import { subDays } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../redux/hooks/hooks';
import { fetchDevices } from '../redux/actions/deviceActions';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LanguageIcon from '@mui/icons-material/Language';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import './Device.css';

import $ from 'jquery';

$.DataTable = require('datatables.net');
const XLSX = require('xlsx');

export default function Device() {
  const { id } = useParams();
  const [gpsData, setGpsData] = useState([]);
  const position = [41.2745016, 28.766];
  const limeOptions = { color: '#7ed11f' };
  const circle1Center = [41.282521, 28.778597];
  const circle1Radius = 140;
  const circle2Center = [41.274704, 28.766498];
  const circle2Radius = 300;
  const [inCircle1, setInCircle1] = useState(0);
  const [inCircle2, setInCircle2] = useState(0);
  const [ setCircle1Count] = useState(0);
  const [ setCircle2Count] = useState(0);
  const pastMonth = new Date();
  const defaultSelected = {
    from: subDays(pastMonth, 70),
    to: pastMonth,
  };
  const [range ] = useState(defaultSelected);
  const navigate = useNavigate();
  const dispatch = useAppDispatch(); //Define dispatch hook
  const devices = useAppSelector((state) => state.device.devices); //Get all devices from the state
  const [vehicleData, setVehicleData] = useState(null);
  const [ setVehicleLastData] = useState(null);
  let myArr = [];
  const deviceImei =
    devices && devices.find((item) => item._id === id).deviceImei;
    const vehiclePlate =
    vehicleData &&
    vehicleData.find((item) => item.vehicleImei === deviceImei).vehiclePlate;
  const [lastTenData, setLastTenData] = useState([]);

  const isInCircle = (coord) => {
    const myCoordinate = L.latLng(coord[0], coord[1]);
    const isInsideCircle1 =
      myCoordinate.distanceTo(L.latLng(circle1Center)) <= circle1Radius;
    const isInsideCircle2 =
      myCoordinate.distanceTo(L.latLng(circle2Center)) <= circle2Radius;

    if (isInsideCircle1 && inCircle1 === 0) {
      setInCircle1(1);
      setCircle1Count((prevCount) => prevCount + 1);
    }
    if (!isInsideCircle1) {
      setInCircle1(0);
    }

    if (isInsideCircle2 && inCircle2 === 0) {
      setInCircle2(1);
      setCircle2Count((prevCount) => prevCount + 1);
    }
    if (!isInsideCircle2) {
      setInCircle2(0);
    }
    return [isInsideCircle1, isInsideCircle2];
  };

  const handleGetGpsData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/tumgpsverisi?deviceImei=${deviceImei}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        setGpsData((prevGpsData) => [
          ...prevGpsData,
          ...response.data?.map((el, idx) => {
            if(!JSON.parse(el.data).latitude && !JSON.parse(el.data).longitude) {
              return [0,0]
            }
            const timestamp = new Date(el.deviceDate);
            const utcTimestamp = moment.utc(timestamp);
            const localTimestamp = utcTimestamp.local();
            const startTimestamp = moment('2023-03-02T08:00:00');
            const endTimestamp = moment('2023-03-02T20:00:00');

            const isBetween = localTimestamp.isBetween(
              startTimestamp,
              endTimestamp
            );
            if (isBetween) {
              isInCircle([
                JSON.parse(el.data).latitude,
                JSON.parse(el.data).longitude,
              ]);
            }

            return [
              JSON.parse(el.data).latitude,
              JSON.parse(el.data).longitude,
            ];
          }),
        ]);
      });
  };

  useEffect(() => {
    handleGetGpsData();
    //Get Last Ten Data
    const getLastTenData = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/veriler/son10?deviceImei=${deviceImei}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization:
                'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          setLastTenData(response.data);
        });
    };
    getLastTenData();

    const options = {
      host: 'ws://213.238.190.87',
      port: 8083,
      username: 'test2',
      password: '1234',
    };

    const client = mqtt.connect(options.host, options);

    client.on('connect', () => {
      console.log('connected to MQTT broker');
      client.subscribe(`igaMqtt/${deviceImei}`, (err) => {
        if (!err) {
          console.log('subscribed to topic');
        }
      });
    });

    client.on('message', (topic, payload) => {
      console.log('topic=>', topic, JSON.parse(payload));
      lastTenData.unshift(JSON.parse(payload));
      setLastTenData((prev) => [...lastTenData, ...prev]);
    });

    return () => {
      client.end();
    };
  }, [devices]);

  //Create Excel File
  const handleExportExcel = async () => {
    setLoader(true);
    await getAllData();
    let filename = `${moment(new Date()).format('DD-MM-YYYY HH:mm')} CihazLogları.xlsx`;
    var ws = XLSX.utils.json_to_sheet(myArr);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, filename);
  };

  //Get all raw data
  const getAllData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/tumveriler?startDate=${range.from}&endDate=${range.to}&deviceImei=${deviceImei}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        setLoader(false);

        response.data.forEach((item) => {
          myArr.push({
            date: item.deviceDate,
            deviceImei: item.deviceImei,
            altitude: item.altitude + 'ft',
            longitude: item.longitude + "'",
            latitude: item.latitude + "'",
            gpsSpeed: item.gpsspeed + 'km/h',
            gpsSignalPower: item.gpssignalpower,
            gpsangle: item.gpsangle + '°',
            satellites: item.satellites,
            totalKm: item.totalkm + 'km',
            totalMeter: item.totalmeter + 'm',
            vehicleSpeed: item.vehiclespeed + 'km/h',
            trailerAirPressure: item.trailerairpressure + 'kPa',
            roomTemp: item.roomtemp + 'C°',
            movement: item.movement,
            kickDownSwitch: item.kickdownswitch,
            ignition: item.ignition,
            breakStatus: item.breakstatus,
            handBreakStatus: item.handbreakstatus,
            gearBoxOilTemp: item.gearboxoiltemp + 'C°',
            gasPedalPosition: item.gaspedalposition,
            fuelTemp: item.fueltemp + 'C°',
            fuelLevel: item.fuellevel + '%',
            fuelConsumption: item.fuelconsumption + 'l/h',
            externalVoltage: item.externalvoltage,
            engineTorque: item.enginetorque + '%',
            engineOilTemp: item.engineoiltemp + 'C°',
            engineOilPressure: item.engineoilpressure + 'kPa',
            engineOilLevel: item.engineoillevel + '%',
            engineLoad: item.engineload + '%',
            engineHour: item.enginehour + 'h',
            engineCycle: item.enginecycle + 'rpm',
            engineCoolingWaterTemp: item.enginecoolingwatertemp + 'C°',
            breakPedalPercentage: item.breakpedalpercentage + '%',
            breakAirPressure1: item.breakairpressure1 + 'kPa',
            breakAirPressure2: item.breakairpressure2 + 'kPa',
            barometricPressure: item.barometricpressure + 'kPa',
          });
        });
      });
  };


  //Get and Update users array on every render
  useEffect(() => {
    try {
      dispatch(fetchDevices());
    } catch (err) {
      console.log(err);
      navigate('/giris');
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    $('#myTable').DataTable().destroy();
    $('#myTable').DataTable({
      language: {
        url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/tr.json',
      },
      scrollX: false,
      columns: [
        {
          data: 'deviceImei',
        },
        {
          data: 'deviceName',
        },
        {
          data: 'deviceGroupId',
        },
        {
          data: 'deviceType',
        },
        {
          data: 'isActive',
        },
      ],
      data: devices,
    });
  }, [devices]);

  useEffect(() => {
    const getVehicleData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/tumaraclar?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization:
                'Bearer ' + localStorage.getItem('token'),
            },
          }
        );
        setVehicleData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getVehicleData();
  }, []);

  useEffect(() => {
    if (vehicleData !== null) {
      const imeiList = [];
      vehicleData.forEach((el) => imeiList.push(el.vehicleImei));

      try {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/sonveri/imei`,
            { imei: JSON.stringify(imeiList) },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization:
                  'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then((response) => {
            setVehicleLastData(response.data);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [setVehicleLastData, vehicleData]);

  const [loader, setLoader] = useState(false);

  return (
    <div className='content mt-[3.75rem] mb-[3.75rem]'>
      <Container>
        <div className='wrapper'>
        <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loader}
                >
                  <CircularProgress color='inherit' />
                </Backdrop>
          {/* <!-- Small boxes (Stat box) --> */}
          <div className='row'>
            <div className='col-lg-4 col-6'>
              {/* <!-- small box --> */}
              <div className='small-box bg-info'>
                <div className='inner'>
                  <h3>
                    <div className=''>{vehiclePlate}</div>
                  </h3>

                  <p>Araç Plaka</p>
                </div>
                <div className='icon'>
                  <i className='ion'>
                    {' '}
                    <LocalShippingIcon />{' '}
                  </i>
                </div>
                {/* <NavLink to="#" className="small-box-footer">More info <ArrowCircleRightIcon /> </NavLink> */}
              </div>
            </div>
            {/* <!-- ./col --> */}
            <div className='col-lg-4 col-6'>
              {/* <!-- small box --> */}
              <div className='small-box bg-success'>
                <div className='inner'>
                  {lastTenData.length > 0 && lastTenData[0].ignition === 0 ? (
                    <h3>OFFLINE</h3>
                  ) : (
                    <h3>ONLINE</h3>
                  )}

                  <p>Durum</p>
                </div>
                <div className='icon'>
                  <i className='ion'>
                    {' '}
                    <LanguageIcon />{' '}
                  </i>
                </div>
              </div>
            </div>

            <div className='col-lg-4 col-6'>
              <div className='small-box bg-danger'>
                <div className='inner'>
                  <h3>65</h3>

                  <p>Günlük Alarm Sayısı</p>
                </div>
                <div className='icon'>
                  <i className='ion'>
                    {' '}
                    <NotificationsActiveIcon />{' '}
                  </i>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <Row className='mt-[2rem]'>
            <Col className='col-lg-6 connectedSortable ui-sortable mt-2'>
            <h1 className='font-cinzel text-center mb-[2rem] text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700 card-title'>
                      {/* Device Logs */}
                      Harita 
                    </h1>
              <MapContainer
                center={position}
                zoom={14}
                className='mapLayer my-4'
                style={{ height: '400px', width: '100%', borderRadius: '15px' }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />
                <Polyline pathOptions={limeOptions} positions={gpsData} />
                <Circle center={circle1Center} radius={circle1Radius}></Circle>
                <Circle center={circle2Center} radius={circle2Radius}></Circle>
              </MapContainer>
            </Col>

            <Col
              className='col-lg-6 connectedSortable ui-sortable mt-2'
              style={{ alignItems: 'center', textAlign: 'center' }}
            >
              <Row className='mt-[0rem]'>
                {lastTenData.length > 0 && (
                  <Col>
                    <h1 className='font-cinzel text-center mb-[2rem] text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700 card-title'>
                      {/* Device Logs */}
                      Cihaz Logları
                    </h1>
                    <table
                      id='ioTable'
                      className='table table-bordered table-hover dataTables-example w-100'
                    >
                      <thead>
                        <tr className='table-success table-bordered'>
                          <td>Toplam (Km)</td>
                          <td>Motor Saati (H)</td>
                          <td>Motor Devri (Rpm)</td>
                          <td>Yakıt Tüketimi{' '} (L/h)</td>
                          <td>Yakıt Seviyesi</td>
                          <td>Araç Hızı (Km/h)</td>
                        </tr>
                      </thead>
                      <tbody>
                      {lastTenData.map((item, index) => {
                        if (index > 9) {
                          return null;
                        } else {
                          return (
                            <tr key={index}>
                              <td>{parseFloat(item.totalkm).toFixed(2)}</td>
                              <td>{parseFloat(item.enginehour).toFixed(2)}</td>
                              <td>{parseFloat(item.enginecycle).toFixed(2)}</td>
                              <td>{parseFloat(item.fuelconsumption).toFixed(2)}</td>
                              <td>{parseFloat(item.fuellevel).toFixed(2)} %</td>
                              <td>{parseFloat(item.vehiclespeed).toFixed(2)}</td>
                            </tr>
                          );
                        }
                      })}


                      </tbody>
                    </table>
                  </Col>
                )}
              </Row>
              <Button
                onClick={handleExportExcel}
                variant='outline-success'
                size='sm'
                className='my-[1rem]'
              >
                {/* Export Excel */}
                Excel Dosyası Oluştur
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
