import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import "./Register.css";

export default function Register() {
  const [_id, setId] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userAuthorizedPersonel, setUserAuthorizedPersonel] = useState(null);
  const navigate = useNavigate();

  // const handleIdChange = (e) => {
  //   const _id = e.target.value;
  //   setId(_id);
  // };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setUserEmail(email);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setUserPassword(password);
  };

  // const handleRoleChange = (e) => {
  //   const role = e.target.value;
  //   setUserRole(role);
  // };

  // const handleAuthorizedPersonelChange = (e) => {
  //   const authPersonel = e.target.value;
  //   setUserAuthorizedPersonel(authPersonel);
  // };

  const handleClick = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/kayitol`,
          {
            _id: _id,
            userEmail: userEmail,
            userPassword: userPassword,
            userRole: userRole,
            userAuthorizedPersonel: userAuthorizedPersonel,
          },
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then(() => navigate("/giris"));
    } catch (err) {
      console.log(err);
      navigate("/kayitol");
    }
  };

  return (
    <div className="content mt-[3.75rem]">
      <Container className="">
        <div className="wrapper">
          <h1 className="font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700">
            Yeni Admin Ekle
          </h1>



          <Form onSubmit={handleClick}>
            <Form.Group className="my-3"  controlId="formBasicEmail">
              <Form.Label>Kullanıcı Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="E-mail"
                onChange={handleEmailChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Kullancı Parola</Form.Label>
              <Form.Control
                type="password"
                placeholder="Parola"
                onChange={handlePasswordChange}
              />
            </Form.Group>

            <Button variant="outline-primary" type="submit" className="my-3">
              Ekle
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
}
