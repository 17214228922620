import axios from 'axios';

//Get All Users
const getAllCompanies = async () => {
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/tumfirmalar`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => response.data);
};

export default getAllCompanies;
