import React from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet"
import moment from "moment"
import { useNavigate } from 'react-router-dom';
/* LEAFLET */
import { MapContainer, TileLayer, ZoomControl, Marker, Popup, } from "react-leaflet";
/* LEAFLET */
import Button from '@mui/material/Button';
import { useEffect } from "react";

function Map({ carsData, cars, mapRef, markerRef, focusVehicle, setFocusVehicle, mapUrl }) {
  //const position = [41.2845016, 28.7986];
  let position = [carsData[0]?.latitude ?? 41.2845016 , carsData[0]?.longitude ?? 28.7986];


  //console.log(carsData)
  const navigate = useNavigate();

function getDirection(latlng1, latlng2) {
  const [lat1, lng1] = latlng1;
  const [lat2, lng2] = latlng2;

  // Radyan dönüşümü için kullanılacak olan sabit
  const RADIANS = Math.PI / 180;

  // İki koordinat arasındaki açıyı hesapla
  const y = Math.sin((lng2 - lng1) * RADIANS) * Math.cos(lat2 * RADIANS);
  const x =
    Math.cos(lat1 * RADIANS) * Math.sin(lat2 * RADIANS) -
    Math.sin(lat1 * RADIANS) *
      Math.cos(lat2 * RADIANS) *
      Math.cos((lng2 - lng1) * RADIANS);

  const bearing = Math.atan2(y, x) / RADIANS;

  // CSS'te kullanmak üzere açıyı döndür
  return bearing;
}

  const setIcon = (car, classArr) => {
    const classes = Object.values(classArr).join(" ")
    const plateNo = cars.find(x => x.vehicleImei === car.deviceImei)?.vehiclePlate;
    const svgCode = `
    <div className="carIcon">
      <span style="position:absolute;font-weight:bold;font-size:0.73rem;display:block;background:rgb(59 130 246 / 0.75);padding:0 10px;font-size:10px;width:max-content;color:white;top:-0.95rem;left:-20px;border-radius:15px">${plateNo} - ${car.ignition === 1 ? parseFloat(car.vehiclespeed ?? 0).toFixed(1)+' Km/s' : 0+' Km/s'}</span>
      <div class="carArrow ${(car.ignition === 0 || parseFloat(car.vehiclespeed ?? 0).toFixed(1) === 0) ? 'd-none' : ''}" style="${classArr.rotate ? 'transform:rotate(' + classArr.rotate + 'deg)' : '0deg'}"></div>
        <div className="car" style="${(classArr.rotate > 90 || classArr.rotate) < -90 ? 'transform:scaleX(-1)' : ''}">
          <svg viewBox="-11.75 -10.014 126 108.985" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" class="${classes}">
            <path d="M5,36.12h14.635c-1.315,1.744-2.096,3.88-2.096,6.236H7.865L5,40.365V36.12z"/>
            <path d="M27.962,34.727c-4.226,0-7.656,3.425-7.656,7.63c0,4.232,3.431,7.644,7.656,7.644c4.212,0,7.63-3.411,7.63-7.644  C35.592,38.151,32.174,34.727,27.962,34.727z M27.962,46.523c-2.312,0-4.187-1.861-4.187-4.167c0-2.292,1.875-4.153,4.187-4.153  c2.292,0,4.167,1.861,4.167,4.153C32.129,44.662,30.254,46.523,27.962,46.523z"/>
            <path d="M81.26,34.726c-4.229,0-7.66,3.43-7.66,7.63c0,4.23,3.431,7.64,7.66,7.64c4.21,0,7.641-3.41,7.641-7.64  C88.9,38.156,85.47,34.726,81.26,34.726z M81.26,46.526c-2.3,0-4.18-1.86-4.18-4.17c0-2.29,1.88-4.15,4.18-4.15  c2.29,0,4.16,1.86,4.16,4.15C85.42,44.666,83.55,46.526,81.26,46.526z"/>
            <path d="M99,20.841L93.461,4.18L84.73,0H67.31v36.12H36.27c1.31,1.75,2.1,3.88,2.1,6.25h32.46c0-5.75,4.67-10.4,10.43-10.4  c4.04,0,7.53,2.31,9.26,5.69c0.62,1.19,1.021,2.54,1.101,3.98c0.01,0.23,0.05,0.47,0.05,0.73H100V25L99,20.841z M72.22,20.841v-12.5  h18.461l4.16,12.5H72.22z"/>
            <polygon fill="none" points="72.22,20.841 72.22,8.341 90.681,8.341 94.841,20.841 "/>
            <rect x="1.5" y="0" width="63" height="33.652"/>
            <path d="M 52.311 -67.415 L 72.777 -50.307 L 31.844 -50.307 L 52.311 -67.415 Z" style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, -1, 0, 0)" bx:shape="triangle 31.844 -67.415 40.933 17.108 0.5 0 1@754d418d"/>
          </svg>
        </div>
      </div>
    `;

    const customIcon = L.divIcon({
      html: svgCode,
      className: "transition duration-500 ease-all",
      popupAnchor: [-5, -25],
      iconAnchor: [30, 30],
      iconSize: [55, 55],
    });
    return customIcon;
  }


  function setCarColor(car) {

    let classes = {
      "animate": "",
      "color": "fill-white",
      "rotate": "0",
    }
    let map;
    switch (mapUrl) {
      case "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png":
        map = "osm";
        break;
      case "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png":
        map = "topo";
        break;
      case "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}":
        map = "sat";
        break;

      default:
        break;
    }


    if (car.ignition === 1 && car.vehiclespeed > 2)
      classes.animate = "bounce";
    else classes.animate = "";

    if (car.ignition === 1)
      if (car.vehiclespeed > 2 && car.vehiclespeed < 25)
        classes.color = map === "sat" ? "fill-lime-400" : "fill-green-800";
      else if (car.ignition === 1 && car.vehiclespeed > 25)
        classes.color = map==="sat" ? "fill-red-400" : "fill-red-800";
      else classes.color = map === "sat" ? "fill-yellow-400" : "fill-orange-700";
    else classes.color = map === "sat" ? "fill-white" : "fill-blue-800";
    //classes.rotate = 310-calcAngle({lat : car.prevLatitude, lng: car.prevLongitude}, {lat : car.latitude, lng : car.longitude})
    if (car.ignition === 1)
      classes.rotate = getDirection([car.prevLatitude, car.prevLongitude], [car.latitude, car.longitude]);
    else classes.rotate = 0;

    return classes;
  }

  function handleMarkerClick(e, car) {
    if (mapRef.current) {
      let markerPosition = [e.target.getLatLng().lat, e.target.getLatLng().lng];
      mapRef.current.setView(markerPosition, 16, { AnimationEffect: true, AnimationDuration: 1, pan: { animate: true, duration: 1 } });
      setFocusVehicle(car.deviceImei);
    }
  }

  const formatNumber = (number) => {
    const str = number.toString();
    const groups = str.match(/.{1,3}/g);
    const formattedNumber = groups.join('.');
    return formattedNumber;
  };

if (carsData.length == 0) return "Loading cars...";
  return (
    <MapContainer center={position} zoom={14} className="w-full h-[100vh]" ref={mapRef} >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
        url={mapUrl}
      />
      <ZoomControl position="bottomleft" />
      {carsData.length > 0 && (
        carsData.map((car) => {
          return (
            <Marker
              icon={setIcon(car, setCarColor(car))}
              position={[car.latitude, car.longitude]}
              animate={true} key={car.deviceImei}
              eventHandlers={{ click: e => handleMarkerClick(e, car) }}
              ref={(marker) => (markerRef.current[car.deviceImei] = marker)}
            >

              <Popup>
                {/* <span className="block">Kilometre : <strong>{formatNumber(parseInt(car.totalkm))} km</strong></span>
                <span className="block">Yakıt/Tüketim : <strong>{car.fuellevel}% / {car.ignition === 1 ? car.fuelconsumption : 0}L/h</strong></span>
                <span className="block">Kontak : <strong>{car.ignition === 1 ? "Açık" : "Kapalı"}</strong></span>
                <span className="block">Motor Devri : <strong>{car.ignition === 1 ? parseFloat(car.enginecycle).toFixed(1) : 0}rpm</strong></span>
                <span className="block">Araç Hızı : <strong>{parseFloat(car.vehiclespeed).toFixed(1)} km</strong></span>
                <span className="block">Hararet : <strong>{parseFloat(car.enginecoolingwatertemp).toFixed(1)} °C</strong></span> */}


                <table className='w-[100%]'>
                  <tbody>
                    <tr>
                      <td className='w-min'><span className='font-bold'>Kilometre :</span></td>
                      <td style={{ textAlign: 'right' }}><strong>{formatNumber(parseInt(car.totalkm))} km</strong></td>
                    </tr>
                    <tr>
                      <td className='w-min'><span className='font-bold'>Yakıt/Tüketim :</span></td>
                      <td style={{ textAlign: 'right' }}><strong>{car.fuellevel}% / {car.ignition === 1 ? car.fuelconsumption : 0}L/h</strong></td>
                    </tr>
                    <tr>
                      <td className='w-min'><span className='font-bold'>Kontak :</span></td>
                      <td style={{ textAlign: 'right' }}> <strong>{car.ignition === 1 ? "Açık" : "Kapalı"}</strong></td>
                    </tr>
                    <tr>
                      <td className='w-min'><span className='font-bold'>Motor Devri :</span></td>
                      <td style={{ textAlign: 'right' }}><strong>{car.ignition === 1 ? parseFloat(car.enginecycle).toFixed(1) : 0}rpm</strong></td>
                    </tr>
                    <tr>
                      <td className='w-min'><span className='font-bold'>Araç Hızı :</span></td>
                      <td style={{ textAlign: 'right' }}><strong>{car.ignition === 1 ? parseFloat(car.vehiclespeed).toFixed(1) : 0} km/sa</strong></td>
                    </tr>
                    <tr>
                      <td className='w-min'><span className='font-bold'>Hararet :</span></td>
                      <td style={{ textAlign: 'right' }}><strong>{car.ignition === 1 ? parseFloat(car.enginecoolingwatertemp).toFixed(1): 0} °C</strong></td>
                    </tr>
                  </tbody>
                </table>

                <hr className="my-2" />
                <span className="block">Son Güncelleme</span>
                <span className="font-bold">{moment(car.deviceDate).local().format("YYYY-MM-DD HH:mm")}</span>
                <div className="buttonArea block">
                  <Button onClick={() => navigate('/arac/alarm/gecmis?deviceImei=' + car.deviceImei)} size="small" className="text-white mt-2 text-[0.7rem] text-center py-[0.1rem] px-3" variant="contained" color="error">İhlaller</Button>
                  <Button onClick={() => navigate('/arac/hareket/gecmis?deviceImei=' + car.deviceImei)} size="small" className="text-white mt-2 ml-1 text-[0.7rem] text-center py-[0.1rem] px-3" variant="contained" color="info">Geçmiş</Button>
                </div>
              </Popup>
            </Marker>
          )
        })
      )}
    </MapContainer>
  );
}

export default Map;
