import React, { useEffect, useState } from "react";
import { Col, Row, Container, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Slider from "@mui/material/Slider";
import "./index.css";
import { Input } from "@mui/material";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import SpeedIcon from "@mui/icons-material/Speed";
import AirlineSeatLegroomExtraIcon from "@mui/icons-material/AirlineSeatLegroomExtra";
import AirlineSeatReclineExtraIcon from "@mui/icons-material/AirlineSeatReclineExtra";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import EvStationIcon from '@mui/icons-material/EvStation';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

export default function UserSettings() {
  const userBreaches = [
    {
      id: 1,
      name: "Geç Kalma",
    },
    {
      id: 2,
      name: "Hız İhlali",
    },
    {
      id: 3,
      name: "Ani Fren",
    },
    {
      id: 4,
      name: "Ani Hızlanma",
    },
  ];

  const deviceBreaches = [
    {
      id: 1,
      name: "Yakıt Seviyesi",
    },
    {
      id: 2,
      name: "Yakıt Tüketimi",
    },
    {
      id: 3,
      name: "Hız",
    },
  ];

  // state'i boş bir array olarak ayarla
  const [userValue, setUserValue] = useState([]);

  useEffect(() => {
    // breaches dizisindeki her öğe için başlangıçta bir value objesi oluştur
    const initialValue = userBreaches.map((breach) => ({
      id: breach.id,
      value: 0,
    }));
    setUserValue(initialValue); // oluşan initialValue'yi value state'ine ata
  }, []);

  const handleSliderChange = (event, newValue) => {
    const targetName = event.target.name.replace("slider", "");
    const itemIndex = userValue?.findIndex(
      (item) => item.id === parseInt(targetName)
    );
    let totalValue = userValue
      ?.filter((item) => item.id !== parseInt(targetName))
      .reduce((acc, curr) => acc + curr.value, 0);
    if (totalValue + newValue > 100) {
      setUserValue((prev) =>
        Object.assign([], prev, {
          [itemIndex]: { ...prev[itemIndex], value: 100 - totalValue },
        })
      );
    } else {
      setUserValue((prev) =>
        Object.assign([], prev, {
          [itemIndex]: { ...prev[itemIndex], value: newValue },
        })
      );
    }
  };

  const handleInputChange = (event, id) => {
    let newValue = Number(event.target.value);
    if (newValue < 0) {
      newValue = 0;
    } else if (newValue > 100) {
      newValue = 100;
    }
    let itemIndex = userValue?.findIndex((item) => item.id === id);
    let totalValue = userValue
      ?.filter((item) => item.id !== id)
      .reduce((acc, curr) => acc + curr.value, 0);

    if (totalValue + newValue > 100) {
      setUserValue((prev) =>
        Object.assign([], prev, {
          [itemIndex]: { ...prev[itemIndex], value: 100 - totalValue },
        })
      );
    } else {
      let diff = newValue - userValue[itemIndex]?.value;
      if (diff === 0 || itemIndex === userValue?.length - 1) {
        setUserValue((prev) =>
          Object.assign([], prev, {
            [itemIndex]: { ...prev[itemIndex], value: newValue },
          })
        );
      } else {
        let lastItemIndex = userValue.length - 1;
        setUserValue((prev) =>
          Object.assign([], prev, {
            [itemIndex]: {
              ...prev[itemIndex],
              value: userValue[itemIndex]?.value + diff,
            },
            [lastItemIndex]: {
              ...prev[lastItemIndex],
              value: prev[lastItemIndex].value - diff,
            },
          })
        );
      }
    }
  };

  const handleBlur = () => {
    // Value değerinin doğru aralıkta olmasını kontrol et
    const updatedValue = userValue.map((item) => ({
      ...item,
      value: Math.max(0, Math.min(100, item.value)),
    }));
    setUserValue(updatedValue);
  };

  // state'i boş bir array olarak ayarla
  const [deviceValue, setDeviceValue] = useState([]);

  useEffect(() => {
    // breaches dizisindeki her öğe için başlangıçta bir value objesi oluştur
    const initialValue = userBreaches.map((breach) => ({
      id: breach.id,
      value: 0,
    }));
    setDeviceValue(initialValue); // oluşan initialValue'yi value state'ine ata
  }, []);

  const handleDeviceSliderChange = (event, newValue) => {
    const targetName = event.target.name.replace("slider", "");
    const itemIndex = deviceValue?.findIndex(
      (item) => item.id === parseInt(targetName)
    );
    setDeviceValue((prev) =>
      Object.assign([], prev, {
        [itemIndex]: { ...prev[itemIndex], value: newValue },
      })
    );
  };

  const handleAlarmLimitSubmit = async (e) => {
    e.preventDefault();
    // const fuelLevel = e.target.slider1.value;
    // const fuelComp = e.target.slider2.value;
    // const speedLimit = e.target.slider3.value;
/* 
    await axios.post(
      "http://localhost:5000/api/alarmlimits",
      {
        fuelLevel,
        fuelComp,
        speedLimit,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }); */
  }


  return (
    <>
      <div className="content mt-[3.75rem]">
        <Container>
          <Row>
            <Col>
              <div className="wrapper">
                <Tabs
                  defaultActiveKey="kullaniciPuanlamaAyarlari"
                  id="fill-tab-example"
                  className="mb-3"
                  fill
                >
                  <Tab eventKey="kullaniciPuanlamaAyarlari" title="Kullanıcı Puanlama Ayarları">
                    <Container>
                      <Row>
                        <Col>
                          <div className="wrapper">
                            <h3 className="font-cinzel text-center mt-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700">
                              Kullanıcı Puanlama Ayarları
                            </h3>
                            <Form>
                              {userBreaches.map((breach) => {
                                let icon;
                                switch (breach.name) {
                                  case "Geç Kalma":
                                    icon = <WatchLaterIcon className="" />;
                                    break;
                                  case "Hız İhlali":
                                    icon = <SpeedIcon className="" />;
                                    break;
                                  case "Ani Fren":
                                    icon = <AirlineSeatLegroomExtraIcon className="" />;
                                    break;
                                  case "Ani Hızlanma":
                                    icon = <AirlineSeatReclineExtraIcon className="" />;
                                    break;
                                  default:
                                    icon = null;
                                }
                                return (
                                  <Form.Group
                                    className="d-flex"
                                    key={breach.id}
                                    style={{ marginTop: "30px" }}
                                  >
                                    <Col
                                      className="mx-5 mt-3 d-flex justify-between"
                                      sm="2"
                                      lg="2"
                                      md="2"
                                    >
                                      <Form.Label className="font-semibold">
                                        {breach.name}
                                      </Form.Label>
                                      {icon}
                                    </Col>
                                    <Col
                                      sm="10"
                                      lg="10"
                                      md="10"
                                      className="d-flex justify-content-around"
                                    >
                                      <Slider
                                        className="mr-10 mt-3"
                                        name={`slider${breach.id}`}
                                        value={
                                          userValue.find((item) => item.id === breach.id)
                                            ?.value ?? 0
                                        }
                                        onChange={handleSliderChange}
                                        aria-labelledby="input-slider"
                                      />
                                      <Input
                                        className="mr-12 my-1 font-bold"
                                        value={
                                          userValue.find((item) => item.id === breach.id)
                                            ?.value ?? 0
                                        }
                                        size="small"
                                        onChange={(event) =>
                                          handleInputChange(event, breach.id)
                                        }
                                        onBlur={handleBlur}
                                        inputProps={{
                                          step: 0.1,
                                          min: 0,
                                          max: 100,
                                          type: "number",
                                          "aria-labelledby": "input-slider",
                                        }}
                                      />
                                    </Col>
                                  </Form.Group>
                                );
                              })}
                              <Row className="justify-content-center mt-10">
                                <Button
                                  style={{ width: "auto", height: "auto" }}
                                  variant="outline-primary"
                                  type="submit"
                                >
                                  Kaydet
                                </Button>
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Tab>
                  <Tab eventKey="ihlalAyarlari" title="İhlal Ayarları">
                    <Container>
                      <Row>
                        <Col>
                          <div className="wrapper">
                            <h3 className="font-cinzel text-center mt-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700">
                              İhlal Ayarları
                            </h3>
                            <Form onSubmit={handleAlarmLimitSubmit}>
                              {deviceBreaches.map((breach, index) => {
                                let icon;
                                switch (breach.name) {
                                  case "Yakıt Seviyesi":
                                    icon = <EvStationIcon className="" />;
                                    break;
                                  case "Yakıt Tüketimi":
                                    icon = <LocalGasStationIcon className="" />;
                                    break;
                                  case "Hız":
                                    icon = <SpeedIcon className="" />;
                                    break;
                                  default:
                                    icon = null;
                                }
                                return (
                                  <Form.Group
                                    className="d-flex"
                                    key={breach.id}
                                    style={{ marginTop: "30px" }}
                                  >
                                    <Col
                                      className="mx-5 mt-3 d-flex justify-between"
                                      sm="2"
                                      lg="2"
                                      md="2"
                                    >
                                      <Form.Label className="font-semibold">
                                        {breach.name}
                                      </Form.Label>
                                      {icon}
                                    </Col>
                                    <Col
                                      sm="10"
                                      lg="10"
                                      md="10"
                                      className="d-flex justify-content-around"
                                    >
                                      <Slider
                                        className="mr-10 mt-3"
                                        name={`slider${breach.id}`}
                                        value={
                                          deviceValue.find((item) => item.id === breach.id)
                                            ?.value ?? 0
                                        }
                                        onChange={handleDeviceSliderChange}
                                        step={index === 1 ? 0.1 : 1}
                                        max={index === 1 ? 15 : 100}
                                        aria-labelledby="input-slider"
                                      />
                                      <Input
                                        className="mr-12 my-1 font-bold w-[100px]"
                                        value={
                                          (deviceValue.find((item) => item.id === breach.id)
                                            ?.value ?? 0 ) + (index === 0 ? " %" : index===1 ? " lt/s" : " km/s")
                                        }
                                        size="small"
                                        readOnly
                                      />
                                    </Col>
                                  </Form.Group>
                                );
                              })}
                              <Row className="justify-content-center mt-10">
                                <Button
                                  style={{ width: "auto", height: "auto" }}
                                  variant="outline-primary"
                                  type="submit"
                                >
                                  Kaydet
                                </Button>
                              </Row>
                            </Form>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Tab>

                </Tabs>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}
