import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';
import { fetchDevicesTestData } from '../redux/actions/devicesTestDataActions';

export default function DevicesData() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch(); //Define dispatch hook
  const devicesTestData = useAppSelector((state) => state.deviceData.devicesTestData); //Get all devicesData from the state

  //Get and Update users array on every render
  useEffect(() => {
    try {
      dispatch(fetchDevicesTestData());
    } catch (err) {
      console.log(err);
      navigate('/giris');
    }
  }, [dispatch, navigate]); 

  return <Container className="position-absolute right-20 top-10 mt-3 mb-3 container">
    {devicesTestData && devicesTestData.length > 0 ? <Table striped bordered hover variant='dark'>
        <thead>
          <tr>
            <th>deviceImei</th>
            <th>deviceLongitude</th>
            <th>deviceLatitude</th>
            <th>deviceAltitude</th>
            <th>deviceSpeed</th>
            <th>deviceAngle</th>
          </tr>
        </thead>
        <tbody>
          {devicesTestData.map((item,idx) => {
            return <tr key={item.timestamp}>
              <td>test device{idx}</td>
              <td>{item.gps.longitude}</td>
              <td>{item.gps.latitude}</td>
              <td>{item.gps.altitude}</td>
              <td>{item.gps.speed}</td>
              <td>{item.gps.angle}</td>
            </tr>
          })}
        </tbody>
      </Table> : <p>Loading...</p>}
  </Container>;
}
