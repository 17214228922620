import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';
import {
  fetchCompanies,
  fetchNewCompany,
} from '../redux/actions/companyActions';
import Button from 'react-bootstrap/esm/Button';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import axios from 'axios';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import { IKContext, IKImage, IKUpload } from 'imagekitio-react';
import './Companies.css';
import moment from 'moment';
import { useFormik } from 'formik';
import { companiesSchema } from '../schemas';
const XLSX = require('xlsx');

export default function Companies() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch(); //Define dispatch hook
  const companies = useAppSelector((state) => state.company.companies); //Get all users from the state

  const [responseData, setResponseData] = useState(null);

  // const [company, setCompany] = useState({ showing: false, data: null });
  const [groups, setGroups] = useState([]);
  const [companyGroups, setCompanyGroups] = useState([]);
  const [userAuthorizedPersonel, setUserAuthorizedPersonel] = useState(
    localStorage.getItem('company')
  );
  const [companyLogo, setCompanyLogo] = useState('');

    //company.showing edit ve add için kullanılan modal
    const [company, setCompany] = useState({ showing: false, data: null, addModal: false });

  const [editInitialState, setEditInitialState] = useState({
        companyId: '',
          companyName: '',
          companyLogo: '',
          companyPhone: '',
          companyEmail: '',
          companyAuthorizedPersonel: '',
          isActive: false,
  })

        // Formik Add Companies initialized
      const {values: addValues, errors: addErrors, handleChange: addHandleChange, handleSubmit: addHandleSubmit, handleBlur: addHandleBlur, touched: addTouched, handleChangeImage: addHandleChangeImage } = useFormik({
        initialValues: {
          companyName: '',
          companyLogo: '',
          companyPhone: '',
          companyEmail: '',
          companyAuthorizedPersonel: '',
          isActive: false,
        },
        onSubmit: values => {
          handleAddCompanies(values);
         },
        validationSchema: companiesSchema,

      });

      // Formik Edit Companies initialized
    const { values: editValues, errors: editErrors, handleChange: editHandleChange, handleSubmit: editHandleSubmit, handleBlur: editHandleBlur, touched: editTouched, handleChangeImage: editHandleChangeImage } = useFormik({
      enableReinitialize: true,
      initialValues: editInitialState,
      onSubmit: (values) => {
        handleEditSubmit(values);
        // console.log("Burası => ", values);
      },
      validationSchema: companiesSchema,
    });

      //// Formik Add Companies initialized
      // const {values, errors, handleChange ,handleSubmit} = useFormik({
      //   initialValues: {
      //     companyName: '',
      //     companyPhone: '',
      //     companyEmail: '',
      //     companyAuthorizedPersonel: '',
      //     isActive: false,
      //   },
      //   onSubmit: values => {
      //     handleAddCompanies(values);
      //    },
      //   validationSchema: companiesSchema,

      // });

  useEffect(() => {
    handleGetGroups();
  }, []);

  useEffect(() => {
    // console.log(companyGroups);
  }, [companyGroups]);

  const handleGetGroups = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumfirmalar`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => setGroups(response.data));
  };

  // if (companies) console.log(companies);
  // console.log(company);
  const handleShow = (id) => {
    let editCompany = companies.find((item) => item._id === id);
    setCompany({ showing: true, data: editCompany });
  };
  const handleClose = () => setCompany({ showing: false, data: null });

  const handleAddCompanies = async (e) => {
    // e.preventDefault();

    // console.log(e);

    let formData = {
      companyName: e.companyName,
      companyLogo: companyLogo,
      companyPhone: e.companyPhone,
      companyInternalNo: e.companyInternalNo,
      companyEmail: e.companyEmail,
      companyAuthorizedPersonel: e.companyAuthorizedPersonel,
      companyDevices: e.companyDevices,
      isActive: e.isActive,
      userAuthorizedPersonel: userAuthorizedPersonel,
      companyGroups: companyGroups,
    };
    // console.log('formdata=>', formData)

    // return

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.companyEmail)) return;

      //insert işlemi
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/firma/yeni`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then(function (response) {
          setCompany(setCompany({...company, addModal: false}))
          setResponseData((prevData) => {
            return [...prevData, formData];
          });
          window.location.reload();
        });
      
  };


  //Get and Update users array on every render
  useEffect(() => {
    try {
      const userRole = localStorage.getItem('userRole')
      if(userRole !== "superadmin") navigate('/')
      dispatch(fetchCompanies());
    } catch (err) {
      // console.log(err);
      navigate('/login');
    }
  }, []);

  const handleRemove = (companyId) => {
    // Are u sure delete it function
    // console.log(companyId);
    Swal.fire({
      title: 'Emin misiniz?',
      text: 'Bu işlemi geri alamazsınız!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Vazgeç',
      confirmButtonText: 'Evet, sil!',
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('girdi');
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/firma/sil`,
            {
              companyId: companyId,
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            Swal.fire(
              'Silindi!',
              'Silme işleminiz gerçekleştirildi.',
              'success'
            );
          })
          .then(function (response) {
            window.location.reload();
          });
      }
    });
  };

  const handleSelectedGroups = (e) => {
    if (e.target.checked) {
      setCompanyGroups((prev) => [...prev, e.target.name]);
    } else {
      companyGroups.splice(companyGroups.indexOf(e.target.name), 1);
    }
  };

  const handleClickAlert = () => {
    //   alert('adadasd')
    // console.log('adasda');
    return (
      // <Stack sx={{ width: '100%' }} spacing={2}>

      //     <Alert variant="filled" severity="info">
      //         Bu bir demo sürümüdür — aktif değil!
      //     </Alert>

      // </Stack>
      alert('Demo Sürümünde Aktif Değil!')
    );
  };

  //Create Excel File
  let myArr = [];
  const handleExportExcel = async () => {
    companies.map((item) => {
      myArr.push({
        'Firma Adı': item.companyName,
        'Firma Telefon': item.companyPhone,
        'Firma Email': item.companyEmail,
        'Firma Yetkili Personel': item.companyAuthorizedPersonel,
        Aktiflik: item.isActive == true ? 'Aktif' : 'Pasif',
      });
    });
    let filename = `${moment(new Date()).format('DD-MM-YYYY HH:mm')} Firmalar.xlsx`;
    var ws = XLSX.utils.json_to_sheet(myArr);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, filename);
  };

  const checkEmail = (e) => {
    const email = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email))
      document.querySelector('.emailInput').style.backgroundColor = 'white';
    else
      document.querySelector('.emailInput').style.backgroundColor =
        'rgba(222, 89, 89, 0.5)';
  };

  const urlEndpoint = 'https://ik.imagekit.io/cozum';
  const publicKey = 'public_TQqVNuG5QzkuqqqWfOSRPsvNv2A=';
  const authenticationEndpoint = `${process.env.REACT_APP_BASE_URL}/imagekitauth`;

  const onError = (err) => {
    // console.log('Error', err);
  };

  // const onSuccess = (res) => {
  //   console.log('Success', res);
  // };

  const handleUploadImage = async (res) => {
    // console.log('Success', res.url);
    setCompanyLogo(res.url);
  };

  const handleChangeImage = async (res) => {
    // console.log('Success', res.url);
    setCompanyLogo(res.url);
  }

  useEffect(() => {
    // console.log(companyLogo);
  }, [companyLogo]);




  
  const handleEdit = (index) => {
    // console.log(companies[index])
    setCompany({ showing: true, index: index });
    // setShow({ visible: true, index: index });
    setEditInitialState({
      
      companyId : companies[index]._id,
      companyName: companies[index].companyName,
      companyLogo: companies[index].companyLogo,
      companyPhone: companies[index].companyPhone,
      companyEmail: companies[index].companyEmail,
      companyAuthorizedPersonel: companies[index].companyAuthorizedPersonel,
      isActive: companies[index].isActive,
    })
    // console.log("EDIT", editInitialState);
  };

    
      const handleEditSubmit = async(e) => {
        // console.log(e);
        // e.preventDefault();
        let formData = {
          companyId : e.companyId,
          companyName : e.companyName,
          companyLogo : companyLogo,
          companyPhone : e.companyPhone,
          companyEmail : e.companyEmail,
          companyAuthorizedPersonel: e.companyAuthorizedPersonel,
          isActive: e.isActive,
        };


          //update işlemi
          await axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/firma/duzenle`,formData,
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(function (response) {
            window.location.reload();
          });


        // axios.post(
        //   `${process.env.REACT_APP_BASE_URL}/editcompany`, formData,
        //   {
        //     headers: {
        //       "Content-Type": "application/x-www-form-urlencoded",
        //       Authorization: "Bearer " + localStorage.getItem("token"),
        //     },
        //   }
        // )
        // .then((response) => {
        //   if (response) window.location.reload();
        // });
    
      }
    
      // console.log(formik);

  return (
    <>

      {/* Add Company Modal */}
      {/* <Modal show={company.showing} onHide={handleClose} className='mt-0'> */}
      <Modal show={company.addModal} onHide={() => setCompany({...company, addModal : false})} className='mt-0'>
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
              Yeni Firma Ekle
          </h3>

          <Form onSubmit={ addHandleSubmit }>
            <Form.Group className='mb-3'>
              <Form.Label className="formLabel">Firma Adı</Form.Label>
              <Form.Control
                type='Text'
                name='companyName'
                id='companyName'
                placeholder=' Firma Adı Giriniz'
                value={company.data?.companyName ?? addValues.companyName}
                onBlur = {addHandleBlur}
                onChange={(e) => {
                  if (company.data?.companyName){
                    setCompany(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        companyName: e.target.value
                      }
                    }));
                  } else addHandleChange(e)
                }}
                className={addErrors.companyName ? "companyNameInputError": ""}
                autoFocus
                />

              {/* {addErrors.companyName && <p className="error"> { addErrors.companyName } </p>} */}

              {addTouched.companyName && addErrors.companyName ? (
                <div>
                  {addErrors.companyName && (
                    <p className="error"> {addErrors.companyName} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>

            <Form.Group className='mb-3'>
              <IKContext
                publicKey={publicKey}
                urlEndpoint={urlEndpoint}
                authenticationEndpoint={authenticationEndpoint}
              >
                <Row>
                  <Form.Label className="formLabel">Firma Logosu Seçiniz</Form.Label>

                </Row>
                <IKUpload
                  fileName='test-upload.png'
                  onError={onError}
                  onSuccess={handleUploadImage}
                />
              </IKContext>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className="formLabel">Firma Telefon</Form.Label>
              <Form.Control
                name='companyPhone'
                type='Text'
                id='companyPhone'
                placeholder='Telefon Numarası Giriniz'
                value={company.data?.companyPhone ?? addValues.companyPhone}
                onBlur = {addHandleBlur}
                onChange={(e) => {
                  if (company.data?.companyPhone){
                    setCompany(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        companyPhone: e.target.value
                      }
                    }));
                  } else addHandleChange(e)
                }}
                className={addErrors.companyPhone ? "companyPhoneInputError": ""}
              />
              {/* {addErrors.companyPhone && <p className="error"> { addErrors.companyPhone } </p>} */}

              {addTouched.companyPhone && addErrors.companyPhone ? (
                <div>
                  {addErrors.companyPhone && (
                    <p className="error"> {addErrors.companyPhone} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className="formLabel">Firma E-mail</Form.Label>
              <Form.Control
                name='companyEmail'
                type='Email'
                id='companyEmail'
                placeholder='E-mail Adresi Giriniz'
                value={company.data?.companyEmail ?? addValues.companyEmail}
                onBlur = {addHandleBlur}
                // onChange={checkEmail}
                // className='emailInput'
                onChange={(e) => {
                  if (company.data?.companyEmail){
                    setCompany(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        companyEmail: e.target.value
                      }
                    }));
                  } else addHandleChange(e)
                }}
                className={addErrors.companyEmail ? "companyEmailInputError": ""}
              />
              {/* {addErrors.companyEmail && <p className="error"> { addErrors.companyEmail } </p>} */}

              {addTouched.companyEmail && addErrors.companyEmail ? (
                <div>
                  {addErrors.companyEmail && (
                    <p className="error"> {addErrors.companyEmail} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>
            <Form.Group
              className='mb-3'
            >
              <Form.Label className="formLabel">Firma Yetkili Personel</Form.Label>
              <Form.Control
                name='companyAuthorizedPersonel'
                type='Text'
                id='companyAuthorizedPersonel'
                placeholder=' Yetkili Personeli Giriniz'
                value={company.data?.companyAuthorizedPersonel ?? addValues.companyAuthorizedPersonel}
                onBlur = {addHandleBlur}
                onChange={(e) => {
                  if (company.data?.companyAuthorizedPersonel){
                    setCompany(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        companyAuthorizedPersonel: e.target.value
                      }
                    }));
                  } else addHandleChange(e)
                }}
                className={addErrors.companyAuthorizedPersonel ? "companyAuthorizedPersonelInputError": ""}
              />
              {/* {addErrors.companyAuthorizedPersonel && <p className="error"> { addErrors.companyAuthorizedPersonel } </p>} */}

              {addTouched.companyAuthorizedPersonel && addErrors.companyAuthorizedPersonel ? (
                <div>
                  {addErrors.companyAuthorizedPersonel && (
                    <p className="error"> {addErrors.companyAuthorizedPersonel} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>

            <Form.Group
              className='mb-3'
            >
              <Form.Label className='mr-3 formLabel'> Aktif </Form.Label>
              <FormControlLabel
                control={
                  <Switch
                    name='isActive'
                    id='isActive'
                    value={company.data?.isActive ?? addValues.isActive}
                    onChange={(e) => {
                      if (company.data?.isActive){
                        setCompany(prevState => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            isActive: e.target.value
                          }
                        }));
                      } else addHandleChange(e)
                    }}
                  />
                }
              />
            </Form.Group>
            <div className='d-flex justify-content-between '>
              <Button variant='outline-secondary' onClick={handleClose}>
                Vazgeç
              </Button>
              <Button variant='outline-primary' type='submit'>
                {/* Değişiklikleri Kaydet */}
                Ekle
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/* Company Modal */}
      
      {/* Edit Company Modal */}
      <Modal show={company.showing} onHide={handleClose} className='mt-0'>
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
              Firma Düzenle
          </h3>

          <Form onSubmit={ editHandleSubmit }>
            <Form.Group className='mb-3'>
              <Form.Label className="formLabel">Firma Adı</Form.Label>
              <Form.Control
                type='Text'
                name='companyName'
                id='companyName'
                placeholder=' Firma Adı Giriniz'
                value={company.data?.companyName ?? editValues.companyName}
                onBlur = {editHandleBlur}
                onChange={(e) => {
                  if (company.data?.companyName){
                    setCompany(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        companyName: e.target.value
                      }
                    }));
                  } else editHandleChange(e)
                }}
                className={editErrors.companyName ? "companyNameInputError": ""}
                autoFocus
              />
              {/* {editErrors.companyName && <p className="error"> { editErrors.companyName } </p>} */}

              {editTouched.companyName && editErrors.companyName ? (
                <div>
                  {editErrors.companyName && (
                    <p className="error"> {editErrors.companyName} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>

            <Form.Group className='mb-3'>
              <IKContext
                publicKey={publicKey}
                urlEndpoint={urlEndpoint}
                authenticationEndpoint={authenticationEndpoint}
              >
                <Row>
                  <Form.Label className="formLabel">Firma Logosu Seçiniz</Form.Label>

                </Row>
                <IKUpload
                  fileName='test-upload.png'
                  onError={onError}
                  onSuccess={handleUploadImage}
                  // onChange={handleChangeImage}
                />
              </IKContext>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className="formLabel">Firma Telefon</Form.Label>
              <Form.Control
                name='companyPhone'
                type='Text'
                id='companyPhone'
                placeholder='Telefon Numarası Giriniz'
                value={company.data?.companyPhone ?? editValues.companyPhone}
                onBlur = {editHandleBlur}
                onChange={(e) => {
                  if (company.data?.companyPhone){
                    setCompany(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        companyPhone: e.target.value
                      }
                    }));
                  } else editHandleChange(e)
                }}
                className={editErrors.companyPhone ? "companyPhoneInputError": ""}
              />
              {/* {editErrors.companyPhone && <p className="error"> { editErrors.companyPhone } </p>} */}

              {editTouched.companyPhone && editErrors.companyPhone ? (
                <div>
                  {editErrors.companyPhone && (
                    <p className="error"> {editErrors.companyPhone} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className="formLabel">Firma E-mail</Form.Label>
              <Form.Control
                name='companyEmail'
                type='Email'
                id='companyEmail'
                placeholder='E-mail Adresi Giriniz'
                value={company.data?.companyEmail ?? editValues.companyEmail}
                onBlur = {editHandleBlur}
                // onChange={checkEmail}
                // className='emailInput'
                onChange={(e) => {
                  if (company.data?.companyEmail){
                    setCompany(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        companyEmail: e.target.value
                      }
                    }));
                  } else editHandleChange(e)
                }}
                className={editErrors.companyEmail ? "companyEmailInputError": ""}
              />
              {/* {editErrors.companyEmail && <p className="error"> { editErrors.companyEmail } </p>} */}

              {editTouched.companyEmail && editErrors.companyEmail ? (
                <div>
                  {editErrors.companyEmail && (
                    <p className="error"> {editErrors.companyEmail} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>
            <Form.Group
              className='mb-3'
            >
              <Form.Label className="formLabel">Firma Yetkili Personel</Form.Label>
              <Form.Control
                name='companyAuthorizedPersonel'
                type='Text'
                id='companyAuthorizedPersonel'
                placeholder=' Yetkili Personeli Giriniz'
                value={company.data?.companyAuthorizedPersonel ?? editValues.companyAuthorizedPersonel}
                onBlur = {editHandleBlur}
                onChange={(e) => {
                  if (company.data?.companyAuthorizedPersonel){
                    setCompany(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        companyAuthorizedPersonel: e.target.value
                      }
                    }));
                  } else editHandleChange(e)
                }}
                className={editErrors.companyAuthorizedPersonel ? "companyAuthorizedPersonelInputError": ""}
              />
              {/* {editErrors.companyAuthorizedPersonel && <p className="error"> { editErrors.companyAuthorizedPersonel } </p>} */}

              {editTouched.companyAuthorizedPersonel && editErrors.companyAuthorizedPersonel ? (
                <div>
                  {editErrors.companyAuthorizedPersonel && (
                    <p className="error"> {editErrors.companyAuthorizedPersonel} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>

            <Form.Group
              className='mb-3'
            >
              <Form.Label className='mr-3 formLabel'> Aktif </Form.Label>
              <FormControlLabel
                control={
                  <Switch
                    name='isActive'
                    id='isActive'
                    type='checkbox'
                    value={company.data?.isActive ?? editValues.isActive}
                    checked={editValues.isActive ? true : false}
                    onChange={editHandleChange}
                    // onChange={(e) => {
                    //   if (company.data?.isActive){
                    //     setCompany(prevState => ({
                    //       ...prevState,
                    //       data: {
                    //         ...prevState.data,
                    //         isActive: e.target.value
                    //       }
                    //     }));
                    //   } else editHandleChange(e)
                    // }}
                  />
                }
              />
            </Form.Group>
            <div className='d-flex justify-content-between '>
              <Button variant='outline-secondary' onClick={handleClose}>
                Vazgeç
              </Button>
              <Button variant='outline-primary' type='submit'>
                {/* Değişiklikleri Kaydet */}
                Değişiklikleri Kaydet
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/* Edit Company  Modal */}







      <div className='content mt-[3.75rem]'>
        <Container>
          <div className='wrapper'>
            <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
              Firmalar
            </h3>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={handleExportExcel}
                color='success'
                variant='outline-success'
                className='text-[0.75rem] mb-4'
              >
                Excel Dosyası Oluştur <FileDownloadIcon />
              </Button>
              <Button
                // onClick={handleClickAlert}
                onClick={() => setCompany({...company, addModal : true})}
                color='primary'
                variant='outline-primary'
                className='text-[0.75rem] mb-4'
              >
                Yeni Firma Ekle <AddHomeWorkIcon className='ml-2' />
              </Button>
            </div>

            {companies && companies.length > 0 ? (
              <Table
                bordered
                hover
                variant=''
                style={{
                  alignItems: 'center',
                  // marginTop: '30px',
                  // marginBottom: '40px',
                }}
              >
                <thead>
                  <tr style={{ width: '100%' }}>
                    <th style={{ textAlign: '' }}>FİRMA ADI</th>
                    <th style={{ textAlign: '' }}> LOGO </th>
                    <th style={{ textAlign: '' }}>TELEFON</th>
                    {/* <th style={{ textAlign: '' }}>
                      Uluslararası Firma Numarası{' '}
                    </th> */}
                    <th style={{ textAlign: '' }}> E-MAİL</th>
                    <th style={{ textAlign: '' }}>YETKİLİ PERSONEL</th>
                    {/* <th style={{ textAlign: '' }}>Firma Cihazlar</th> */}
                    <th style={{ textAlign: 'center' }}>AKTİFLİK</th>
                    <th style={{ textAlign: 'center' }}>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {companies.length > 0 &&
                    companies.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: '14%' }}>
                            {item.companyName}
                          </td>
                          <td style={{ width: '14%' }}>
                            <img className="logoIga" width="30%" src={item.companyLogo} alt={item.companyName} />
                          </td>
                          <td style={{ width: '14%' }}>
                            {item.companyPhone}
                          </td>
                          {/* <td style={{ textAlign: 'justify' }}>
                            {item.companyInternalNo}
                          </td> */}
                          <td style={{ width: '14%' }}>
                            {item.companyEmail}
                          </td>
                          <td style={{ width: '14%' }}>
                            {item.companyAuthorizedPersonel}
                          </td>
                          {/* <td style={{ textAlign: 'justify' }}>
                            {item.companyDevices}
                          </td> */}
                          <td style={{ textAlign: 'center' }}>
                            {item.isActive ? 'Aktif' : 'Pasif'}
                          </td>

                          <td style={{ textAlign: 'center' }}>
                            <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip
                                  style={{ fontSize: '12px' }}
                                  id={`tooltip-${'top'}`}
                                >
                                  Düzenle
                                </Tooltip>
                              }
                            >
                              <Button
                                // onClick={() => handleClickAlert(item._id)}

                                onClick={() => handleEdit(index)}
                                className='p-0'
                                variant='outline-secondary'
                                size='sm'
                                style={{
                                  borderStyle: 'none',
                                  borderRadius: '5px',
                                }}
                              >
                                <EditIcon className='p-1' />
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip
                                  style={{ fontSize: '12px' }}
                                  id={`tooltip-${'top'}`}
                                >
                                  Sil
                                </Tooltip>
                              }
                            >
                              <Button
                                // onClick={() => handleClickAlert(item._id)}

                                className='p-0'
                                variant='outline-danger'
                                size='sm'
                                style={{
                                  borderStyle: 'none',
                                  borderRadius: '5px',
                                }}
                              onClick={() => handleRemove(item._id)}
                              >
                                <DeleteIcon className='p-1' />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            ) : (
              <p>Yükleniyor...</p>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}