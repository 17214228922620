import * as yup from "yup";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// Users Page
export const usersSchema = yup.object().shape({
  userEmail: yup
    .string()
    .email("Lütfen Geçerli bir email giriniz!")
    .matches(emailRegex, {
      message: "Lütfen Email formatında giriniz",
    })
    .required("E-mail girmek zorunludur!"),

  userPassword: yup
    .string()
    // .min(3, "Lütfen minimum 3 karakter giriniz")
    .required("Parola girmek zorunludur!"),

  //   userRole: yup
  //     .string()
  //     .oneOf(["user", "admin", "superadmin"], "Lütfen Kullanıcı Türü Seçiniz")
  //     .required("Kullanıcı Türü Seçmek zorunludur!"),
});

// Drivers Page
export const driversSchema = yup.object().shape({
  driverName: yup
    .string()
    .min(3, "Lütfen en az 3 karakter giriniz!")
    .max(50, "İsim Çok uzun!")
    .required("İsim girmek zorunludur!"),
});

// Companies Page
export const companiesSchema = yup.object().shape({
  companyName: yup
    .string()
    .min(3, "Lütfen en az 3 karakter giriniz!")
    .max(50, "Çok uzun!")
    .required("Firma Adı girmek zorunludur!"),

  companyPhone: yup
    .string()
    .matches(/^[0-9]+$/, "Lütfen sadece sayıları kullanınız!")
    .min(10, "Telefon numaranız eksik! Lütfen kontrol ediniz")
    .max(12, "Telefon numaranız fazla uzun! Lütfen kontrol ediniz")
    .required("Telefon girilmesi zorunludur!"),

  companyEmail: yup
    .string()
    .email("Lütfen Geçerli bir email giriniz!")
    .matches(emailRegex, {
      message: "Lütfen Email formatında giriniz",
    })
    .required("E-mail girmek zorunludur!"),

  companyAuthorizedPersonel: yup
    .string()
    .min(2, "Çok kısa!")
    .max(50, "Çok uzun!")
    .required("Yetkili Personel girmek zorunludur!"),
});

// Drivers Page
export const departmentSchema = yup.object().shape({
  groupName: yup
    .string()
    .min(3, "Lütfen en az 3 harfli isim giriniz!")
    .max(50, "İsim Çok uzun!")
    .required("Departman ismi girmek zorunludur!"),
});

// VehicleList Page
export const vehicleListSchema = yup.object().shape({
  vehicleImei: yup
    .string()
    .required("IMEI girilmesi zorunludur!")
    .length(15, "IMEI numarası 15 karakterli olmalıdır!")
    .max(15, "IMEI No 15 karakterden fazla olamaz!"),
  vehiclePlate: yup.string().required("Araç plakası girilmesi zorunludur!"),
  vehicleName: yup.string().required("Araç ismi girilmesi zorunludur!"),
  vehicleBrand: yup.string().required("Araç markası girilmesi zorunludur!"),
  vehicleModel: yup.string().required("Araç modeli girilmesi zorunludur!"),
  storageSize: yup
    .number()
    .typeError("Yakıt Depo boyutu bir sayı olmalıdır.")
    .max(600, "Yakıt limiti maximum 600'lt dir.")
    .required("Yakıt Depo boyutu girilmesi zorunludur!"),
});

// Devices Page
export const devicesSchema = yup.object().shape({
  deviceImei: yup
    .string()
    .required("IMEI girilmesi zorunludur!")
    .length(15, "IMEI numarası 15 karakterli olmalıdır!")
    .max(15, "IMEI No 15 karakterden fazla olamaz!"),
  deviceName: yup
    .string()
    .min(3, "Lütfen en az 3 harfli cihaz adı giriniz!")
    .max(50, "Cihaz Adı Çok uzun!")
    .required("Cihaz Adı girilmesi zorunludur!"),
  deviceSim: yup
    .string()
    .required("Sim Kart Numarası girilmesi zorunludur!")
    .length(8, "SIM numarası 8 karakterli olmalıdır!")
    .max(8, "SIM numarası 8 karakterden fazla olamaz!"),
  deviceType: yup.string().required("Cihaz tipi girilmesi zorunludur!"),
});
