import companySlice from '../slices/companySlice';
import getAllCompanies from '../../handlers/getAllCompanies';
import createNewCompany from '../../handlers/createNewCompany';

export const companyActions = companySlice.actions;

//Get all Companies
export const fetchCompanies = () => {
  return async (dispatch) => {
    const response = await getAllCompanies();
    dispatch(companyActions.setCompanies(response));
  };
};

//New Company
export const fetchNewCompany = (companyData) => {
  return async (dispatch) => {
    const response = await createNewCompany(companyData);
    dispatch(companyActions.setCompanies(response));
  };
};