import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { colors } from '@mui/material';

const ListArea = ({ data, limits, counter, setCounter }) => {
  const scrollIndex = useRef({});

  useEffect(() => {
    if (counter && scrollIndex.current[counter]) {
      scrollIndex.current[counter].scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [counter])

  const handleListSelect = (index) => {
    setCounter(index);
  }
  
  return (
    <>
      <div className="lists w-full h-[93%] text-center overflow-scroll ">
        <Table striped bordered hover size="sm">
          <thead className='text-[0.8rem] font-bold' style={{position:'sticky', top:'0', backgroundColor: '#fff', zIndex:'1'}}>
            <tr>
              <th>Saat</th>
              <th>Kontak</th>
              <th>Toplam KM</th>
              <th>Hız (km/s)</th>
            </tr>
          </thead>
          <tbody className='text-[0.75rem] cursor-pointer select-none'>
            {data.map((item, index) => {
              return (
                <tr key={index} className={`${index == counter ? "bg-sky-100" : ""}`} ref={e => scrollIndex.current[index] = e} onClick={() => handleListSelect(index)} >
                  <td>{moment(item.deviceDate).format("HH:mm:ss")}</td>
                  <td>{item.ignition == 1 ? "Açık" : "Kapalı"}</td>
                  <td>{item.totalkm}</td>
                  <td>{item.ignition == 1 ? parseFloat(item.vehiclespeed).toFixed(1) : 0} </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default ListArea;