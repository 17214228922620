import React, { useEffect, useState } from 'react';
import { Container } from '@mui/system';
import { Button } from '@mui/material';
import { Table } from 'react-bootstrap';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '../Modal';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import AddIcon from '@mui/icons-material/Add';

import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import moment from 'moment';
import * as XLSX from 'xlsx';
import Snackbar from '@mui/material/Snackbar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import './style.css';

const Shifts = () => {
  const [shiftModal, setShiftModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [importData, setImportData] = useState([]);

  const [drivers, setDrivers] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [shiftList, setShiftList] = useState([]);

  const [date, setDate] = useState(moment(new Date()));
  const [showInsert, setShowInsert] = useState(false);
  const [insertDataValues, setInsertDataValues] = useState({
    driver: '',
    vehicle: '',
    shift: '',
    startdate: '',
    enddate: '',
  });

  const [loader, setLoader] = useState(true);
  const [snack, setSnack] = useState({ open: false, message: null });
  const [collapse, setCollapse] = useState(false);
  const [file, setFile] = useState(null);
  const [sheetNameList, setSheetNameList] = useState([]);

  const [startDate, setStartDate] = useState(new Date());


  const isFutureDate = (date) => {
    return date.getTime() > new Date().getTime();
  };

  const handleSubmit = (e) => {
    const formData = {
      shiftName: e.target.elements.shiftName.value,
      shiftStartTime: moment(e.target.elements.startTime.value).format(`HH:mm`),
      shiftEndTime: moment(e.target.elements.endTime.value).format(`HH:mm`),
    };

    setLoader(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/vardiya/yenizaman`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(function (response) {
        setShiftModal(false);
        setLoader(false);
        Swal.fire({ title: 'Kayıt başarılı', icon: 'success' });
        setShiftList((prev) => [...prev, response.data]);
      });
  };

  const handleInsert = () => {
    let result = Object.values(insertDataValues).map((item) => {
      if (item == null) {
        Swal.fire({
          title: 'Lütfen tüm alanları doldurunuz.',
          icon: 'warning',
        });
        return false;
      } else return true;
    });
    if (result.includes(false)) return 0;
    setLoader(true);
    let selectedShift = moment(
      shiftList.find((item) => item._id == insertDataValues.shift)
        .shiftStartTime
    ).format(`HH:mm:ss`);
    //console.log(selectedShift)
    let data = {
      shiftType: insertDataValues.shift,
      shiftStartDate: moment(insertDataValues.startdate).format(
        `YYYY-MM-DD ${selectedShift}`
      ),
      shiftEndDate: moment(insertDataValues.enddate).format(
        `YYYY-MM-DD ${selectedShift}`
      ),
      driverId: insertDataValues.driver.id,
      vehicleId: insertDataValues.vehicle.id,
      userCompany: localStorage.getItem('userCompany')
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/vardiya/yeni`, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(function (response) {
        setLoader(false);
        Swal.fire({ title: 'Kayıt başarılı', icon: 'success' });
        setInsertDataValues({
          driver: null,
          vehicle: null,
          shift: null,
          startdate: null,
          enddate: null,
        });
        setShowInsert(false);
        data._id = response.data._id;
        setShifts((prev) => [...prev, data]);
      });
  };

  const actionHandle = async (id, type, val) => {
    async function updateData(data, url) {
      setLoader(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/${url}`,
          data,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        );
        setSnack({ open: true, message: 'Güncelleme Başarılı' });
        setLoader(false);
        return response.data;
      } catch (error) {
        setSnack({ open: true, message: 'Bir hata meydana geldi..' });
        setLoader(false);
        return 0;
      }
    }

    const updateItem = (id, newItem) => {
      setShifts(shifts.map((item) => (item.id === id ? newItem : item)));
    };

    let response;
    switch (type) {
      case 'driver':
        response = await updateData(
          { shiftId: id, driverId: val.id },
          'vardiyalar/duzenle'
        );
        if (response === 0) return 0;
        let updateDriver = shifts.find((item) => item._id === id);
        updateDriver.driverId = val.id;
        break;
      case 'vehicle':
        response = await updateData(
          { shiftId: id, vehicleId: val.id },
          'vardiyalar/duzenle'
        );
        if (response === 0) return 0;
        let updateVehicle = shifts.find((item) => item._id === id);
        updateVehicle.vehicleId = val.id;
        break;
      case 'date':
        response = await updateData(
          { shiftId: id, shiftDate: val },
          'vardiyalar/duzenle'
        );
        if (response === 0) return 0;
        let updateDate = shifts.find((item) => item._id === id);
        updateDate.shiftDate = val;
        break;
      case 'shift':
        response = await updateData(
          { shiftId: id, shiftType: val.target.value },
          'vardiyalar/duzenle'
        );
        if (response === 0) return 0;
        let updateShift = shifts.find((item) => item._id === id);
        updateShift.shiftType = val.target.value;
        //updateData({ shiftId: id, shiftType: val.type })
        break;
      case 'remove':
        Swal.fire({
          title: 'Emin misiniz?',
          text: 'Bu işlemi geri alamazsınız!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Vazgeç',
          confirmButtonText: 'Evet, sil!',
        }).then((result) => {
          if (result.isConfirmed) {
            updateData({ shiftId: id }, 'vardiyalar/sil');
            setShifts(shifts.filter((item) => item._id !== id));
            Swal.fire(
              'Silindi!',
              'Silme işleminiz gerçekleştirildi.',
              'success'
            );
          }
        });
        break;

      default:
        break;
    }
    let update = shifts.find((item) => item._id === response._id);
    updateItem(response._id, update);
  };

  useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/tumaraclar?companyName=${localStorage.getItem(
          'userCompany'
        )}&userRole=${localStorage.getItem('userRole')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        response.data.map((item) => {
          setVehicles((prev) => [
            ...prev,
            {
              id: item?._id,
              name: item?.vehiclePlate,
              label: `${item?.vehiclePlate} - ${item?.vehiclePlate}`,
              type: 'vehicle',
            },
          ]);
        });
      });

    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/tumsuruculer?companyName=${localStorage.getItem(
          'userCompany'
        )}&userRole=${localStorage.getItem('userRole')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        response.data.map((item) => {
          setDrivers((prev) => [
            ...prev,
            {
              id: item?._id,
              label: item.driverName,
              name: item?.driverName,
              type: 'driver',
            },
          ]);
        });
      });

    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/tumvardiyalar?companyName=${localStorage.getItem(
          'userCompany'
        )}&userRole=${localStorage.getItem('userRole')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((response) => {
        setShifts(response.data);
        setLoader(false);
        //setDate(moment(response.data[0]?.date).month());
        document.getElementById('select-months').options.selectedIndex = moment(
          response.data[0]?.date
        ).month();
      });

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/vardiya/saat`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
        setShiftList(response.data);
      });
  }, []);

  const handleDelete = (id) => {
    setLoader(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/vardiyalar/sil`,
        { id: id },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      )
      .then(function (response, err) {
        setLoader(false);
        if (err) {
          setSnack({ open: true, message: 'Bir hata meydana geldi..' });
          return 0;
        }
        setSnack({ open: true, message: 'Silme Başarılı' });
      });
  };

  function calcDateTime(date, time) {
    const datetime = moment(`${date} ${time}`);
    if (datetime.hours() < 7) {
      datetime.add(1, 'days');
    }
    //console.log(date,time, moment(`${date} ${time}`, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'))
    return datetime.format('YYYY-MM-DD HH:mm:ss');
  }

  function handleFileChange(e) {
    // Dosyayı al
    const fileObj = e.target.files[0];
    // Dosya tipini kontrol et (.xls veya .xlsx)
    if (
      fileObj.type !== 'application/vnd.ms-excel' &&
      fileObj.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      alert('Sadece Excel dosyalarını yükleyebilirsiniz!');
      return false;
    }
    setLoader(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      // XLSX kullanarak dosya içeriğini oku
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheet_name_list = workbook.SheetNames;
      setSheetNameList(sheet_name_list);
      const xlData = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheet_name_list[sheet_name_list.length - 1]],
        { header: 1, raw: false }
      ); // cellDates parametresi eklenmiştir
      const getDriver = (dname) =>
        drivers.find((driver) => driver.name.trim() == dname.trim())?.id;
      const getVehicle = (vname) =>
        vehicles.find((vehicle) => vehicle.name.trim() == vname.trim())?.id;
      const getShift = (date) => {
        if (
          moment(date, 'HH:mm:ss').format('HH:mm:ss') >
            moment('07:00:00', 'HH:mm,ss').format('HH:mm:ss') &&
          moment(date, 'HH:mm:ss').format('HH:mm:ss') <
            moment('19:00:00', 'HH:mm:ss').format('HH:mm:ss')
        ) {
          return shiftList.find((shift) => shift.shiftName == 'Sabah')?._id;
        } else if (
          moment(date, 'HH:mm:ss').format('HH:mm:ss') >
          moment('19:00:00', 'HH:mm:ss').format('HH:mm:ss')
        ) {
          return shiftList.find((shift) => shift.shiftName == 'Akşam')?._id;
        }
      };
      xlData.forEach((item, index) => {
        if (index < 1) return;
        if (item.length == 6) {
          let readData = {
            driverId: getDriver(item[0]) ?? item[0],
            vehicleId: getVehicle(item[1]) ?? item[1],
            date: moment(item[2]).format('YYYY-MM-DD'), // moment ile tarih biçimlendirme yapmanıza gerek yok, tarih zaten doğru formatta gelir
            // date: moment(item[2], "YYYY-MM-DD").format("DD-MM-YYYY"), // moment ile tarih biçimlendirme yapmanıza gerek yok, tarih zaten doğru formatta gelir
            shiftType: item[3] ? getShift(item[3].trim()) : '',
            isTrue: getDriver(item[0]) && getVehicle(item[1]) ? true : false,
          };

          if (item[3]) {
            readData.shiftStartDate = item[3]
              ? calcDateTime(item[2].trim(), item[3])
              : undefined;
            readData.shift =
              moment(item[3], 'HH:mm:ss').format('HH:mm:ss') >
                moment('07:00:00', 'HH:mm,ss').format('HH:mm:ss') &&
              moment(item[4], 'HH:mm:ss').format('HH:mm:ss') <
                moment('19:00:00', 'HH:mm:ss').format('HH:mm:ss')
                ? 'Gündüz'
                : 'Gece';
          }
          if (item[4])
            readData.shiftEndDate = item[4]
              ? calcDateTime(item[2].trim(), item[4])
              : undefined;
          setImportData((prev) => [...prev, readData]);
        }
      });
    };
    reader.readAsBinaryString(fileObj);
    setLoader(false);
    // Dosyayı state'e kaydet
    setFile(fileObj);
  }

  const handleClickAlert = () => {
    return (
      // <Stack sx={{ width: '100%' }} spacing={2}>

      //     <Alert variant="filled" severity="info">
      //         Bu bir demo sürümüdür — aktif değil!
      //     </Alert>

      // </Stack>
      alert('Demo Sürümünde Aktif Değil!')
    );
  };

  const handleImport = async () => {
    setLoader(true);

    let importDataList = importData.filter((item) => item.isTrue === true);

    if (importDataList.length === 0) {
      setSnack({ open: true, message: 'Eklenecek geçerli kayıt bulunamadı..' });
      return;
    }

    //setLoader(true);
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/vardiyalar/iceriaktar`,
          {
            data: JSON.stringify(importDataList),
            userCompany: localStorage.getItem('userCompany'),
            userRole: localStorage.getItem('userRole'),
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then((response) => {
          setLoader(false);
          console.log(response.data.conflictArray);

          let conflictArrays = response.data.conflictArray;

          let conflictSource = '';
          conflictArrays.map((item) => {
            if (item.conflict) {
              // console.log(item.conflictSource)

              if (item.conflictSource === 'EXCEL') {
                console.log(
                  'item.conflictSource EXCEL => ',
                  item.conflictSource
                );
              } else if (item.conflictSource === 'DB') {
                console.log('item.conflictSource DB => ', item.conflictSource);
              }
              // setConflictArray()
            }
          });

          // console.log("response.data =>", response.data.conflictArray[0].conflictSource)
        });

      return;
    } catch (err) {
      console.log(err.response.data.message);
      if (err.response.data.message === 'Eklenecek vardiya bulunamadı.') {
        setFile(null);
        setAddModal(false);
        setImportData([]);
        setLoader(false);
        setSnack({ open: true, message: err.response.data.message });
      }
    }
  };

  const handleSelectMonth = async (e) => {
    const selectedMonth = e.target.value;

    setLoader(true);
    const res = await axios.get(
      `${
        process.env.REACT_APP_BASE_URL
      }/tumvardiyalar/tarih?date=${selectedMonth}&companyName=${localStorage.getItem(
        'userCompany'
      )}&userRole=${localStorage.getItem('userRole')}`,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    );
    if (res.data.length > 0) {
      setShifts(res.data);
      //setDate(e.target.value);
      setLoader(false);
    } else {
      setSnack({ open: true, message: 'Vardiya bulunamadı..' });
      setShifts([]);
      //setDate(e.target.value);
      setLoader(false);
    }
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={snack.open}
        style={{ zIndex: '9999' }}
        autoHideDuration={1500}
        onClose={() => setSnack({ open: false, message: '' })}
        message={snack.message}
      />

      {/* Shift Modal */}
      <Modal open={shiftModal} setOpen={setShiftModal}>
        <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
          Yeni Vardiya Ekle
        </h3>

        <Form onSubmit={handleSubmit}>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Vardiya Adı</Form.Label>
            <Form.Control
              name='shiftName'
              type='text'
              placeholder='Vardiya Adı'
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formBasicPassword'>
            <Form.Label>Başlangıç Saati</Form.Label>
            <Form.Control name='startTime' type='time' placeholder='Password' />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formBasicPassword'>
            <Form.Label>Bitiş Saati</Form.Label>
            <Form.Control name='endTime' type='time' placeholder='Password' />
          </Form.Group>
          <Button variant='outlined' type='submit'>
            Ekle
          </Button>
        </Form>
      </Modal>
      {/* Shift Modal */}
      {/* vardiya ekleme Modal */}
      <Modal
        open={addModal}
        setOpen={setAddModal}
        width='max-content'
        padding='1.5rem 2rem'
      >
        <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
          Excelden Ekle
        </h3>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        {(!file && (
          <Container>
            <div className='wrapper mt-3'>
              <h5 className='select-none font-cinzel mb-5 text-[1rem] font-bold'>
                Excel Dosyası Yükleyiniz
              </h5>
              <input
                type='file'
                accept='.csv, .xlsx, .xls'
                onChange={handleFileChange}
              />
            </div>
          </Container>
        )) || (
          <div className='overflow-hidden h-[75vh] overflow-scroll'>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Sürücü Adı</th>
                  <th>Araç Kapı No</th>
                  <th>Vardiya Tarihi</th>
                  <th>Başlangıç Saati</th>
                  <th>Bitiş Saati</th>
                  <th>Vardiya</th>
                </tr>
              </thead>
              <tbody>
                {importData.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${item.isTrue ? 'bg-green-50' : 'bg-red-50'}`}
                    >
                      <td className='px-6'>
                        {drivers.find((el) => el.id == item.driverId)?.name ?? (
                          <span className='text-red-600 block'>
                            {item.driverId}
                          </span>
                        )}
                      </td>
                      <td className='px-4'>
                        {vehicles.find((el) => el.id == item.vehicleId)
                          ?.name ?? (
                          <span className='text-red-600 block'>
                            {item.vehicleId}
                          </span>
                        )}
                      </td>
                      <td className='px-4'>{item.date}</td>
                      <td
                        className={`${
                          item.shiftStartDate ? '' : 'bg-orange-200'
                        }`}
                      >
                        {item?.shiftStartDate
                          ? moment(
                              item.shiftStartDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('HH:mm:ss')
                          : '-'}
                      </td>
                      <td
                        className={`${
                          item?.shiftEndDate ? '' : 'bg-orange-200'
                        }`}
                      >
                        {item?.shiftEndDate
                          ? moment(
                              item.shiftEndDate,
                              'YYYY-MM-DD HH:mm:ss'
                            ).format('HH:mm:ss')
                          : '-'}
                      </td>
                      <td>{item.shift}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        )}
        <span
          className={`${file ? '' : 'hidden'} font-bold mt-4 block float-left`}
        >
          Not: Kırmızılar eklenmeyecektir..
        </span>
        <Button
          variant='contained'
          className={`${file ? '' : 'hidden'} mt-2 float-right`}
          onClick={handleImport}
        >
          Tamamla
        </Button>
        <Button
          onClick={() => {
            setFile(null);
            setAddModal(false);
          }}
          variant='contained'
          color='error'
          className={`mt-2 mr-2 float-right`}
        >
          Iptal
        </Button>
      </Modal>

      {/* vardiya ekelme modal Modal */}

      <div className='content mt-[3.75rem]'>
        <Container maxWidth='xl'>
          <div
            className={`wrapper mt-3 overflow-hidden transition-all ${
              collapse ? 'h-auto' : 'h-[50px]'
            }`}
          >
            <div className='title w-full flex justify-between items-start gap-4'>
              <h1
                className='select-none font-cinzel mb-6 -mt-2 text-[1.5rem] relative after:bottom-[-5px] cursor-pointer'
                onClick={() => setCollapse(!collapse)}
              >
                Vardiya Listesi
                <KeyboardArrowDownIcon
                  className={`transition-all ${
                    collapse ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </h1>
              <Button
                onClick={() => handleClickAlert()}
                className='-mt-2'
                variant='outlined'
                size='small'
                // onClick={() => setShiftModal(!shiftModal)}
              >
                Yeni Vardiya Ekle
              </Button>
            </div>
            <Table striped bordered hover className='mt-4 shiftTable'>
              <thead>
                <tr className='font-cinzel text-sky-800'>
                  <th className='w-[26%]'>Vardiya Adı</th>
                  <th className='text-center w-[21%]'>Başlangıç Saati</th>
                  <th className='text-center w-[21%]'>Bitiş Saati</th>
                  <th className='text-center w-[11%]'>İşlem</th>
                </tr>
              </thead>
              <tbody>
                {shiftList.map(
                  ({ _id, shiftName, shiftStartTime, shiftEndTime }) => (
                    <tr key={_id}>
                      <td className='text-center'>{shiftName}</td>
                      <td className='text-center'>
                        {moment(shiftStartTime)
                          .local('Europe/Istanbul', 'HH:mm')
                          .format('HH:mm')}
                      </td>
                      <td className='text-center'>
                        {moment(shiftEndTime)
                          .local('Europe/Istanbul', 'HH:mm')
                          .format('HH:mm')}
                      </td>
                      <td className='text-center'>
                        <Button
                          className='w-[1rem] p-1 min-w-[1.9rem] ml-1'
                          color='error'
                          variant='outlined'
                          size='small'
                        >
                          <DeleteIcon
                            className='text-[1rem] text-danger'
                            onClick={() => handleClickAlert()}
                            // onClick={() => handleDelete(_id, 'shift')}
                          />
                        </Button>
                        <Button
                          className='w-[1rem] p-1 min-w-[1.9rem] ml-1'
                          color='warning'
                          variant='outlined'
                          size='small'
                        >
                          <EditIcon
                            className='text-[1rem] text-warning'
                            onClick={() => handleClickAlert()}
                            // onClick={() => handleDelete(_id, 'shift')}
                          />
                        </Button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        </Container>

        <Container maxWidth='xl'>
          <div className='wrapper mt-3'>
            <h1 className='select-none font-cinzel mb-5 text-[1.5rem] relative after:bottom-[-5px] after:w-[100%] after:bg-pipe-color after:h-[3px] after:absolute after:left-0'>
              Sürücü-Vardiya Listesi
            </h1>
            <span className='text-[1.2rem] font-cinzel pr-4 mt-1 inline-block'>
              Ay :
            </span>

            <Form.Select
              aria-label='Default select'
              className='inline-block w-fit'
              id='select-months'
              onChange={handleSelectMonth}
            >
              <option value='1'>Ocak</option>
              <option value='2'>Şubat</option>
              <option value='3'>Mart</option>
              <option value='4'>Nisan</option>
              <option value='5'>Mayıs</option>
              <option value='6'>Haziran</option>
              <option value='7'>Temmuz</option>
              <option value='8'>Ağustos</option>
              <option value='9'>Eylül</option>
              <option value='10'>Ekim</option>
              <option value='11'>Kasım</option>
              <option value='12'>Aralık</option>
            </Form.Select>

            {}
            <Table striped bordered hover className='mt-4 shiftTable'>
              <thead>
                <tr className='font-cinzel text-sky-800'>
                  <th className='w-[16%]'>Tarih</th>
                  <th className='w-[18%]'>Sürücü Adı</th>
                  <th className='text-center w-[16%]'>Araç</th>
                  <th className='text-center w-[14%]'>Başlangıç</th>
                  <th className='text-center w-[14%]'>Bitiş</th>
                  <th className='text-center w-[10%]'>Vardiya</th>
                  <th className='text-center w-[10%]'>İşlem</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7} className='p-0'>
                    <div className='flex justify-between items-center'>
                      <div
                        onClick={() => {
                          setShowInsert(!showInsert);
                        }}
                        className='w-[50%] bg-emerald-50 hover:bg-emerald-100 text-center border-gray-400'
                      >
                        <h4 className='select-none font-cinzel font-bold hover:text-sky-600 cursor-pointer py-1'>
                          <AddIcon className='text-[2rem]' /> Yeni eklemek için
                          tıklayınız..
                        </h4>
                      </div>

                      <div
                        onClick={() => {
                          setAddModal(true);
                        }}
                        className='w-[50%] bg-sky-50 hover:bg-sky-100 text-center border-gray-400'
                      >
                        <h4 className='select-none font-cinzel font-bold hover:text-sky-600 cursor-pointer py-1'>
                          <AddIcon className='text-[2rem]' /> Excelden Eklemek
                          için tıklayınız..
                        </h4>
                      </div>
                    </div>
                  </td>
                </tr>

                {showInsert && (
                  <tr className='bg-teal-50'>
                    <td>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          onClick={() => handleClickAlert()}
                          value={date}
                          //onChange={(newVal) => handleGetFromDate(newVal)}
                          className='dpicker md:mx-auto'
                          style={{ width: '100%' }}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          filterDate={isFutureDate}
                        />
                      </LocalizationProvider>{' '}
                    </td>
                    <td className='pb-3 pt-4'>
                      <Autocomplete
                        disablePortal
                        id='driver'
                        options={drivers}
                        sx={{ width: '100%' }}
                        renderInput={(params) => (
                          <TextField {...params} label='Sürücü Seç' />
                        )}
                        onChange={(e, val) =>
                          setInsertDataValues({
                            ...insertDataValues,
                            driver: val,
                          })
                        }
                        value={insertDataValues.driver}
                      />
                    </td>
                    <td className='text-center pb-3 pt-4'>
                      <Autocomplete
                        disablePortal
                        id='combo-box-demo'
                        options={vehicles}
                        sx={{ width: '100%' }}
                        renderInput={(params) => (
                          <TextField {...params} label='Araç Seç' />
                        )}
                        onChange={(e, val) =>
                          setInsertDataValues({
                            ...insertDataValues,
                            vehicle: val,
                          })
                        }
                        value={insertDataValues.vehicle}
                      />
                    </td>

                    <td className='pb-3 pt-4'>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          className='dpicker w-full'
                          value={insertDataValues.date}
                          onChange={(newVal) =>
                            setInsertDataValues({
                              ...insertDataValues,
                              startdate: newVal,
                            })
                          }
                          format='HH:mm:ss'
                          ampm={false}
                          clearable
                        />
                      </LocalizationProvider>
                    </td>
                    <td className='pb-3 pt-4'>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <TimePicker
                          className='dpicker w-full'
                          value={insertDataValues.date}
                          onChange={(newVal) =>
                            setInsertDataValues({
                              ...insertDataValues,
                              enddate: newVal,
                            })
                          }
                          format='HH:mm:ss'
                          ampm={false}
                          clearable
                        />
                      </LocalizationProvider>
                    </td>
                    <td className='text-center pb-3 pt-4'>
                      <Select
                        placeholder='Mesai Seç'
                        onChange={(e) =>
                          setInsertDataValues({
                            ...insertDataValues,
                            shift: e.target.value,
                          })
                        }
                        value={insertDataValues.shift}
                        className='w-full text-start'
                      >
                        {shiftList.map((shift, index) => {
                          return (
                            <MenuItem key={index} value={shift._id}>
                              {shift.shiftName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </td>
                    <td className='text-center pb-3 pt-5'>
                      <Button
                        //onClick={handleInsert}
                        onClick={() => handleClickAlert()}
                        className='w-[1rem] p-1 min-w-[1.9rem]'
                        color='success'
                        variant='outlined'
                        size='small'
                      >
                        <AddIcon className='text-[1rem] text-success' />
                      </Button>
                      <Button
                        onClick={() => handleClickAlert()}
                        // onClick={() => {
                        //   setInsertDataValues({
                        //     driver: null,
                        //     startdate: null,
                        //     enddate: null,
                        //     shift: null,
                        //     vehicle: null,
                        //   });
                        //}}
                        className='w-[1rem] p-1 min-w-[1.9rem] ml-1'
                        color='error'
                        variant='outlined'
                        size='small'
                      >
                        <DeleteIcon className='text-[1rem] text-danger' />
                      </Button>
                    </td>
                  </tr>
                )}

                {(shifts.length === 0 && (
                  <tr>
                    <td
                      colSpan={6}
                      className='p-0 text-center text-[1.1rem] py-5 text-sky-800'
                    >
                      Kayıt Bulunamadı
                    </td>
                  </tr>
                )) ||
                  shifts.map((el, index) => {
                    let selectedDriver = drivers.find(
                      (driver) => driver.id === el.driverId
                    );
                    let selectedVehicle = vehicles.find(
                      (vehicle) => vehicle.id === el.vehicleId
                    );
                    let selectedShift = shiftList.find(
                      (shift) => shift._id === el.shiftType
                    )?._id;
                    return (
                      <tr key={index}>
                        <td>
                          <span className='block text-center text-[1.2em] mt-4'>
                            {moment(el.date).format('DD/MM/YYYY')}
                          </span>
                        </td>
                        <td className='pb-3 pt-4'>
                          <Autocomplete
                            disablePortal
                            options={drivers}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                              <TextField {...params} label='Sürücü Seç' />
                            )}
                            value={selectedDriver || ''}
                            onChange={(e, val) =>
                              actionHandle(el._id, 'driver', val)
                            }
                          />
                        </td>
                        <td className='text-center pb-3 pt-4'>
                          <Autocomplete
                            disablePortal
                            options={vehicles}
                            sx={{ width: '100%' }}
                            renderInput={(params) => (
                              <TextField {...params} label='Araç Seç' />
                            )}
                            value={selectedVehicle || ''}
                            onChange={(e, val) =>
                              actionHandle(el._id, 'vehicle', val)
                            }
                          />
                        </td>

                        <td className='pb-3 pt-4'>
                          {/* Başlangıç SAAT */}
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TimePicker
                              className='dpicker w-full'
                              value={
                                el?.shiftStartDate
                                  ? moment(el.shiftStartDate)
                                  : null
                              }
                              onBlur={(val) =>
                                actionHandle(el._id, 'startdate', val)
                              }
                              format='HH:mm:ss'
                              ampm={false}
                              clearable
                            />
                          </LocalizationProvider>
                          {/* Başlangıç SAAT */}
                        </td>
                        <td className='pb-3 pt-4'>
                          {/* Bitiş SAAT */}
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <TimePicker
                              className='dpicker w-full'
                              value={
                                el?.shiftEndDate
                                  ? moment(el.shiftEndDate)
                                  : null
                              }
                              onBlur={(val) =>
                                actionHandle(el._id, 'enddate', val)
                              }
                              format='HH:mm:ss'
                              ampm={false}
                              clearable
                            />
                          </LocalizationProvider>
                          {/* Bitiş SAAT */}
                        </td>
                        <td className='text-center pb-3 pt-4'>
                          <Select
                            placeholder='Mesai Seç'
                            onChange={(e, val) =>
                              actionHandle(el._id, 'shift', e)
                            }
                            value={selectedShift || ''}
                            className='w-full text-start'
                          >
                            {shiftList.map((shift, index) => {
                              return (
                                <MenuItem key={index} value={shift._id}>
                                  {shift.shiftName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </td>
                        <td className='text-center pb-3 pt-4'>
                          <Button
                            className='w-[1rem] p-1 min-w-[1.9rem] ml-1'
                            color='error'
                            variant='outlined'
                            size='small'
                          >
                            <DeleteIcon
                              className='text-[1rem] text-danger'
                              onClick={() =>
                                handleClickAlert(el._id, 'remove', null)
                              }
                              // onClick={() =>
                              //   //actionHandle(el._id, 'remove', null)
                              // }
                            />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Shifts;
