import { createSlice } from '@reduxjs/toolkit';

const initialDevicesTestDataState = {
    data: null,
};

const devicesTestDataSlice = createSlice({
  name: 'deviceData',
  initialState: initialDevicesTestDataState,
  reducers: {
    setDevicesTestData: (state, action) => {
      state.devicesTestData = action.payload;
    },
  },
});

export default devicesTestDataSlice;
