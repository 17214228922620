import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';
import { fetchDevices } from '../redux/actions/deviceActions';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
// import Button from "react-bootstrap/Button";
import $ from 'jquery';
import './Devices.css';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';
import AddIcon from '@mui/icons-material/Add';
import Modal from 'react-bootstrap/Modal';
import { devicesSchema } from '../schemas';
import axios from 'axios';
import { useFormik } from 'formik';
import { FormControlLabel, Switch } from '@mui/material';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/esm/Button';

// Excel işlemleri için XLSX kütüphanesini kullanıyoruz
const XLSX = require('xlsx');

$.DataTable = require('datatables.net');

export default function Devices() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch(); // Dispatch hook'unu tanımlıyoruz
  const devices = useAppSelector((state) => state.device.devices); /// State'ten tüm cihazları alıyoruz

  const [responseData, setResponseData] = useState(null);

  const [groups, setGroups] = useState([]);

  // device.showing, edit ve add için kullanılan modal için durumu ve verileri tutuyoruz
  const [device, setDevice] = useState({
    showing: false,
    data: null,
    addModal: false,
  });

  const [editInitialState, setEditInitialState] = useState({
    deviceId: '',
    deviceImei: '',
    deviceName: '',
    deviceSim: '',
    deviceType: '',
    isActive: false,
  });

  // Formik ile Cihaz Ekleme formunu başlatıyoruz
  const {
    values: addValues,
    errors: addErrors,
    handleChange: addHandleChange,
    handleSubmit: addHandleSubmit,
    handleBlur: addHandleBlur,
    touched: addTouched,
    handleChangeImage: addHandleChangeImage,
  } = useFormik({
    initialValues: {
      deviceImei: '',
      deviceName: '',
      deviceSim: '',
      deviceType: '',
      isActive: false,
    },
    onSubmit: (values) => {
      handleAddDevices(values);
    },
    validationSchema: devicesSchema,
  });

  // Formik ile Cihaz Düzenleme formunu başlatıyoruz
  const {
    values: editValues,
    errors: editErrors,
    handleChange: editHandleChange,
    handleSubmit: editHandleSubmit,
    handleBlur: editHandleBlur,
    touched: editTouched,
    handleChangeImage: editHandleChangeImage,
  } = useFormik({
    enableReinitialize: true,
    initialValues: editInitialState,
    onSubmit: (values) => {
      handleEditSubmit(values);
      // console.log("Burası => ", values);
    },
    validationSchema: devicesSchema,
  });

  useEffect(() => {
    handleGetDevices();
  }, [devices]);

  // Tüm cihazları almak için axios ile API çağrısı yapıyoruz
  const handleGetDevices = async () => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }/tumcihazlar?userRole=${localStorage.getItem(
          'userRole'
        )}&userCompany=${localStorage.getItem('userCompany')}`,
        {
          // Cihazları GET isteği ile alıyoruz
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
      setResponseData(response.data);
      setGroups(response.data);
      // console.log(response.data);

      const data = response.data.map((device) => {
        return [
          device.deviceImei,
          device.deviceName,
          device.deviceType,
          device.isActive ? 'Aktif' : 'Pasif',
          moment(device.createdAt).format('DD.MM.YYYY HH:mm'),
        ];
      });

      let tableData = [];
      response.data.forEach((device) => {
        tableData.push({
          deviceImei: device.deviceImei,
          deviceName: device.deviceName,
          deviceSim: device.deviceSim,
          deviceType: device.deviceType,
          isActive: device.isActive ? 'Aktif' : 'Pasif',
        });
      });

      if ($.fn.DataTable.isDataTable('#myTable')) {
        $('#myTable').DataTable().destroy();
      }

      $('#myTable').DataTable({
        responsive: true,
        language: {
          url: '//cdn.datatables.net/plug-ins/1.10.24/i18n/Turkish.json',
        },
        dom:
          "<'row'<'col-sm-12 col-md-6'l><'col-sm-12 col-md-6'f>>" +
          "<'row'<'col-sm-12'tr>>" +
          "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
        paginate: {
          previous: '<span class="prev-icon">Önceki</span>',
          next: '<span class="next-icon">Sonraki</span>',
        },
        oLanguage: {
          sLengthMenu: 'Göster _MENU_ kayıtlar',
          sInfo: '_TOTAL_ kayıttan_START_ ile _END_ arası gösteriliyor',
        },
        scrollX: false,
        tabIndex: 0,
        columns: [
          {
            data: 'deviceImei',
            title: 'Cihaz IMEI',
          },
          {
            data: 'deviceName',
            title: 'Cihaz Adı',
          },
          {
            data: 'deviceSim',
            title: 'Sim Kart Numarası',
          },
          {
            data: 'deviceType',
            title: 'Cihaz Tipi',
          },
          {
            data: 'isActive',
            title: 'Aktiflik',
          },
          {
            data: 'null',
            title: 'İşlemler',
          },

        ],

        columnDefs: [
          {
            targets: [5],
            orderable: false,
            searchable: false,
            render: function (data, type, row) {
              return '<button type="button" class="btn btn-outline-primary btn-sm editButton">Düzenle</button>&nbsp;<button type="button" class="btn btn-outline-danger btn-sm deleteButton">Sil</button>';
            },
          },

        ],
        order: [[0, 'desc']],
        data: tableData,
      });

      // Cihaz tablosundaki her düzenleme bağlantısına tıkladığında bu olay tetiklenir
      $('#myTable tbody').on('click', '.editButton', function (e) {
        const row = $(this).closest('tr');
        const data = $('#myTable').DataTable().row(row).data();
        const selectedDevice = response.data.find(
          (device) => device.deviceImei === data.deviceImei
        );
        handleEdit(selectedDevice);
      });

      // Cihaz tablosundaki her silme bağlantısına tıkladığında bu olay tetiklenir
      $('#myTable tbody').on('click', '.deleteButton', function () {
        const row = $(this).closest('tr');
        const data = $('#myTable').DataTable().row(row).data();
        const selectedDevice = response.data.find(
          (device) => device.deviceImei === data.deviceImei
        );
        handleDeleteDevices(selectedDevice);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => setDevice({ showing: false, data: null });

  // Yeni bir cihaz eklemek için axios ile API çağrısı yapıyoruz
  const handleAddDevices = async (e) => {
    // console.log(e);

    let formData = {
      deviceImei: e.deviceImei,
      deviceName: e.deviceName,
      deviceSim: e.deviceSim,
      deviceType: e.deviceType,
      isActive: e.isActive,
      companyName: localStorage.getItem('userCompany'),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/cihaz/yeni`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );

      if (response.status === 201) {
        Swal.fire({
          title: 'Başarılı!',
          text: 'Cihaz başarıyla eklendi.',
          icon: 'success',
          confirmButtonText: 'Tamam',
        }).then(() => {
          handleGetDevices();
          setDevice({ showing: false, data: null, addModal: false });
          window.location.reload();
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Hata!',
        text: 'Cihaz eklenirken bir hata oluştu.',
        icon: 'error',
        confirmButtonText: 'Tamam',
      });
    }
  };

  // Bir cihazı silmek için axios ile API çağrısı yapıyoruz
  const handleDeleteDevices = async (id) => {
    Swal.fire({
      title: 'Emin misiniz?',
      text: 'Bu cihazı silmek istediğinizden emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/cihaz/sil`,
            {
              deviceId: id,
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          );
          if (response.status === 200) {
            Swal.fire({
              title: 'Başarılı!',
              text: 'Cihaz başarıyla silindi.',
              icon: 'success',
              confirmButtonText: 'Tamam',
            }).then(() => {
              handleGetDevices();
              setDevice({ showing: false, data: null, addModal: false });
              window.location.reload();
            });
          }
        } catch (error) {
          console.log(error);
          Swal.fire({
            title: 'Hata!',
            text: 'Cihaz silinirken bir hata oluştu.',
            icon: 'error',
            confirmButtonText: 'Tamam',
          });
        }
      }
    });
  };

  // Cihaz düzenleme işlemi için, düzenlenecek cihazın bilgilerini state'e atar ve modalı açar
  const handleEdit = (selectedDevice) => {
    setDevice({ showing: true, selectedDevice: selectedDevice });
    setEditInitialState({
      deviceId: selectedDevice._id,
      deviceImei: selectedDevice.deviceImei,
      deviceName: selectedDevice.deviceName,
      deviceSim: selectedDevice.deviceSim,
      deviceType: selectedDevice.deviceType,
      isActive: selectedDevice.isActive,
    });
    // console.log("EDIT", editInitialState);
  };

  // Bir cihazı düzenlemek için axios ile API çağrısı yapıyoruz
  const handleEditSubmit = async (e) => {
    let formData = {
      deviceId: e.deviceId,
      deviceImei: e.deviceImei,
      deviceName: e.deviceName,
      deviceSim: e.deviceSim,
      deviceType: e.deviceType,
      isActive: e.isActive,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/cihaz/duzenle`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
      if (response.status === 200) {
        Swal.fire({
          title: 'Başarılı!',
          text: 'Cihaz başarıyla güncellendi.',
          icon: 'success',
          confirmButtonText: 'Tamam',
        }).then(() => {
          handleGetDevices();
          setDevice({ showing: false, data: null, addModal: false });
          window.location.reload();
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Hata!',
        text: 'Cihaz güncellenirken bir hata oluştu.',
        icon: 'error',
        confirmButtonText: 'Tamam',
      });
    }
  };

  //Get and Update users array on every render
  useEffect(() => {
    try {
      const userRole = localStorage.getItem('userRole');
      if (userRole !== 'superadmin') navigate('/');
      dispatch(fetchDevices());
    } catch (err) {
      console.log(err);
      navigate('/giris');
    }
  }, [dispatch, navigate]);

  // function handleEdit(row) {
  //   console.log("Düzenle butonuna tıklanan satır indeksi: ", row);
  // }

  // function handleRemove(row) {
  //   console.log("Sil butonuna tıklanan satır indeksi: ", row);
  // }

  //Create Excel File
  let myArr = [];
  const handleExportExcel = async () => {
    devices.map(item => {
      myArr.push({ 'Cihaz IMEI': item.deviceImei, 'Cihaz Adı': item.deviceName, 'Sim Numara': item.deviceSim, 'Cihaz Tipi': item.deviceType, 'Aktiflik': item.isActive === true ? ('Aktif') : ('Pasif') })
    });
    let filename = `${moment(new Date()).format('DD-MM-YYYY HH:mm')} Cihazlar.xlsx`;
    var ws = XLSX.utils.json_to_sheet(myArr);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, filename);
  };

  const handleClickAlert = () => {

    return (

      alert('Demo Sürümünde Aktif Değil!')
    );
  };

  return (
    <>
      {/* Add Device Modal */}
      {/* <Modal show={device.showing} onHide={handleClose} className='mt-0'> */}
      <Modal
        show={device.addModal}
        onHide={() => setDevice({ ...device, addModal: false })}
        className='mt-0'
      >
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
            Yeni Cihaz Ekle
          </h3>

          <Form onSubmit={addHandleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Cihaz IMEI</Form.Label>
              <Form.Control
                type='Text'
                name='deviceImei'
                id='deviceImei'
                placeholder=" Cihaz IMEI'sini Giriniz"
                value={device.data?.deviceImei ?? addValues.deviceImei}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (device.data?.deviceImei) {
                    setDevice((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        deviceImei: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                isInvalid={addTouched.deviceImei && !!addErrors.deviceImei}
                // className={addErrors.deviceImei ? "deviceImeiInputError" : ""}
              />

              {addTouched.deviceImei && addErrors.deviceImei ? (
                <div>
                  {addErrors.deviceImei && (
                    <p className='error'> {addErrors.deviceImei} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Cihaz Adı</Form.Label>
              <Form.Control
                type='Text'
                name='deviceName'
                id='deviceName'
                placeholder=" Cihaz Adı'nı Giriniz"
                value={device.data?.deviceName ?? addValues.deviceName}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (device.data?.deviceName) {
                    setDevice((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        deviceName: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={addErrors.deviceName ? 'deviceNameInputError' : ''}
                isInvalid={addTouched.deviceName && !!addErrors.deviceName}
              />

              {addTouched.deviceName && addErrors.deviceName ? (
                <div>
                  {addErrors.deviceName && (
                    <p className='error'> {addErrors.deviceName} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Sim Numara</Form.Label>
              <Form.Control
                type='Text'
                name='deviceSim'
                id='deviceSim'
                placeholder=" Sim Numara'sını Giriniz"
                value={device.data?.deviceSim ?? addValues.deviceSim}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (device.data?.deviceSim) {
                    setDevice((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        deviceSim: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={addErrors.deviceSim ? 'deviceSim' : ''}
                isInvalid={addTouched.deviceSim && !!addErrors.deviceSim}
              />

              {addTouched.deviceSim && addErrors.deviceSim ? (
                <div>
                  {addErrors.deviceSim && (
                    <p className='error'> {addErrors.deviceSim} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Cihaz Tipi</Form.Label>
              <Form.Control
                type='Text'
                name='deviceType'
                id='deviceType'
                placeholder=" Cihaz Tipi'ni Giriniz"
                value={device.data?.deviceType ?? addValues.deviceType}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (device.data?.deviceType) {
                    setDevice((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        deviceType: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={addErrors.deviceType ? 'deviceTypeInputError' : ''}
                isInvalid={addTouched.deviceType && !!addErrors.deviceType}
              />

              {addTouched.deviceType && addErrors.deviceType ? (
                <div>
                  {addErrors.deviceType && (
                    <p className='error'> {addErrors.deviceType} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            {/* <Form.Group className='mb-3'>
              <Form.Label className='mr-3 formLabel'> Aktiflik </Form.Label>
              <FormControlLabel
                type='checkbox'
                control={
                  <Switch
                    name='isActive'
                    id='isActive'
                    value={device.data?.isActive ?? addValues.isActive}
                    onChange={(e) => {
                      if (device.data?.isActive) {
                        setDevice((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            isActive: e.target.value,
                          },
                        }));
                      } else addHandleChange(e);
                    }}
                  />
                }
                label={addValues.isActive ? 'Aktif' : 'Pasif'}
              />
            </Form.Group> */}

            <div className='d-flex justify-content-between '>
              <Button
                className='mt-3'
                variant='outline-secondary'
                onClick={handleClose}
              >
                Vazgeç
              </Button>
              <Button className='mt-3' variant='outline-primary' type='submit'>
                {/* Değişiklikleri Kaydet */}
                Ekle
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/* Device Modal */}

      {/* Edit Device Modal */}
      <Modal show={device.showing} onHide={handleClose} centered>
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
            Cihaz Düzenle
          </h3>
          <Modal.Body>
            <Form onSubmit={editHandleSubmit}>
              <Form.Group className='mb-3'>
                <Form.Label className='mr-3 formLabel'>Cihaz IMEI</Form.Label>
                <Form.Control
                  readOnly={true}
                  disabled={true}
                  type='text'
                  name='deviceImei'
                  id='deviceImei'
                  placeholder='Cihaz IMEI Giriniz'
                  value={device.data?.deviceImei ?? editValues.deviceImei}
                  onBlur={editHandleBlur}
                  onChange={(e) => {
                    if (device.data?.deviceImei) {
                      setDevice((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          deviceImei: e.target.value,
                        },
                      }));
                    } else editHandleChange(e);
                  }}
                  isInvalid={editTouched.deviceImei && !!editErrors.deviceImei}
                />
                {editTouched.deviceImei && editErrors.deviceImei ? (
                  <div>
                    {editErrors.deviceImei && (
                      <p className='error'> {editErrors.deviceImei} </p>
                    )}
                  </div>
                ) : null}
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label className='mr-3 formLabel'>Cihaz Adı</Form.Label>
                <Form.Control
                  type='text'
                  name='deviceName'
                  id='deviceName'
                  value={device.data?.deviceName ?? editValues.deviceName}
                  onChange={(e) => {
                    if (device.data?.deviceName) {
                      setDevice((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          deviceName: e.target.value,
                        },
                      }));
                    } else editHandleChange(e);
                  }}
                  onBlur={editHandleBlur}
                  isInvalid={editTouched.deviceName && !!editErrors.deviceName}
                />
                <Form.Control.Feedback type='invalid'>
                  {editErrors.deviceName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label className='mr-3 formLabel'>Sim Numara</Form.Label>
                <Form.Control
                  type='text'
                  name='deviceSim'
                  id='deviceSim'
                  value={device.data?.deviceSim ?? editValues.deviceSim}
                  onChange={(e) => {
                    if (device.data?.deviceSim) {
                      setDevice((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          deviceSim: e.target.value,
                        },
                      }));
                    } else editHandleChange(e);
                  }}
                  onBlur={editHandleBlur}
                  isInvalid={editTouched.deviceSim && !!editErrors.deviceSim}
                />
                <Form.Control.Feedback type='invalid'>
                  {editErrors.deviceSim}
                </Form.Control.Feedback>
              </Form.Group>

              {/* <Form.Group className="mb-3">
                <Form.Label className='mr-3 formLabel'>Cihaz Grup ID</Form.Label>
                <Form.Control
                  type="text"
                  name="deviceGroupId"
                  id="deviceGroupId"
                  value={device.data?.deviceGroupId ?? editValues.deviceGroupId}
                  onChange={(e) => {
                    if (device.data?.deviceGroupId) {
                      setDevice(prevState => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          deviceGroupId: e.target.value
                        }
                      }));
                    } else editHandleChange(e)
                  }}
                  onBlur={editHandleBlur}
                  isInvalid={editTouched.deviceGroupId && !!editErrors.deviceGroupId}
                />
                <Form.Control.Feedback type="invalid">{editErrors.deviceGroupId}</Form.Control.Feedback>
              </Form.Group> */}

              <Form.Group className='mb-3'>
                <Form.Label className='mr-3 formLabel'>Cihaz Tipi</Form.Label>
                <Form.Control
                  name='deviceType'
                  id='deviceType'
                  value={device.data?.deviceType ?? editValues.deviceType}
                  onChange={(e) => {
                    if (device.data?.deviceType) {
                      setDevice((prevState) => ({
                        ...prevState,
                        data: {
                          ...prevState.data,
                          deviceType: e.target.value,
                        },
                      }));
                    } else editHandleChange(e);
                  }}
                  onBlur={editHandleBlur}
                  isInvalid={editTouched.deviceType && !!editErrors.deviceType}
                ></Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {editErrors.deviceType}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3'>
                <Form.Label className='mr-3 formLabel'>Aktiflik</Form.Label>
                <FormControlLabel
                  control={
                    <Switch
                      name='isActive'
                      id='isActive'
                      checked={editValues.isActive ? true : false}
                      onChange={editHandleChange}
                    />
                  }
                  type='checkbox'
                  label={editValues.isActive ? 'Aktif' : 'Pasif'}
                />
              </Form.Group>

              <div className='d-flex justify-content-between '>
                <Button
                  className='mt-3'
                  variant='outline-secondary'
                  onClick={handleClose}
                >
                  Vazgeç
                </Button>
                <Button
                  className='mt-3'
                  variant='outline-primary'
                  type='submit'
                >
                  Değişiklikleri Kaydet
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Container>
      </Modal>

      <div className='content mt-[4.5rem] '>
        <Container className=''></Container>

        <Container className=' mt-[2.5rem]'>
          <div className='wrapper'>
            <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
              Cihazlar
            </h3>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={handleExportExcel}
                color='success'
                variant='outline-success'
                className='text-[0.75rem] mb-4'
              >
                Excel Dosyası Oluştur <FileDownloadIcon />
              </Button>

              <Button
                onClick={() => setDevice({ ...devices, addModal: true })}
                // onClick={() => handleClickAlert()}
                color='primary'
                variant='outline-primary'
                className='text-[0.75rem] mb-4'
              >
                Yeni Cihaz Ekle <AddIcon />
              </Button>
            </div>

            {devices && devices.length > 0 ? (
              <Row className='mt-3'>
                <Col className='mb-5 mx-3' style={{}}>
                  <h1 className='card-title font-bold mb-3'>Cihaz Listesi</h1>

                  <table
                    id='myTable'
                    className='table bordered table-hover dataTables-example w-100'
                  ></table>
                </Col>
              </Row>
            ) : (
              <p>Yükleniyor...</p>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}
