import React, { useEffect } from 'react'
import * as echarts from 'echarts';

const Test = () => {

  useEffect(() => {
    var myChart = echarts.init(document.getElementById('main'));

    myChart.setOption({
      title: {
        text: 'ECharts Getting Started Example'
      },
      tooltip: {},
      xAxis: {
        data: ['shirt', 'cardigan', 'chiffon', 'pants', 'heels', 'socks']
      },
      yAxis: {},
      series: [
        {
          name: 'sales',
          type: 'bar',
          data: [5, 20, 36, 10, 10, 20]
        }
      ]
    });
    return () => {
      myChart.dispose();
    }
  }, []);

  return (
    <>
      <div id="main" className='w-full h-[400px]'></div>
    </>
  )
}

export default Test