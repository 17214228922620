import userSlice from '../slices/userSlice';
import getAllUsers from '../../handlers/getAllUsers';

export const userActions = userSlice.actions;

//Get all users
export const fetchUsers = () => {
  return async (dispatch) => {
    const response = await getAllUsers();
    dispatch(userActions.setUsers(response));
  };
};

// //Create new user
// export const fetchNewUser = (userData) => {
//   return async (dispatch) => {
//     await handleNewUser(userData);
//     const response = await getAllUsers();
//     dispatch(userActions.setUsers(response.data));
//   };
// };

// //Edit a user
// export const fetchEditUser = (userData) => {
//   return async (dispatch) => {
//     await handleEditUser(userData);
//     const response = await getAllUsers();
//     dispatch(userActions.setUsers(response.data));
//   };
// };

// //Delete a user
// export const fetchDeleteUser = (id) => {
//   return async (dispatch) => {
//     await handleDeleteUser(id);
//     const response = await getAllUsers();
//     dispatch(userActions.setUsers(response.data));
//   };
// };

// //Set state.name
// export const setUserName = (name) => {
//   return (dispatch) => {
//     dispatch(userActions.setName(name));
//   };
// };

// //Set state.age
// export const setUserAge = (age) => {
//   return (dispatch) => {
//     dispatch(userActions.setAge(age));
//   };
// };

// //Set selected ID to update or delete
// export const setSelectedId = (id) => {
//   return (dispatch) => {
//     dispatch(userActions.setId(id));
//   };
// };
