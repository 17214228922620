import { createSlice } from '@reduxjs/toolkit';

const initialCompanyState = {
    companyName: null,
    companyLogo: null,
    companyPhone: null,
    companyInternalNo: null,
    companyEmail: null,
    companyAuthorizedPersonel: null,
    companyDevices: null,
    isActive: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState: initialCompanyState,
  reducers: {
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
  },
});

export default companySlice;
