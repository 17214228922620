import React, { useState } from 'react'
import Speedometer, {
    Background,
    Arc,
    Needle,
    Progress,
    Marks,
    Indicator,
    DangerPath
} from 'react-speedometer';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import moment from 'moment';

const Speedometers = ({ data, counter }) => {

    const perColor = (value) => {
        if (value < 20) {
            return "red"
        } else if (value < 50) {
            return "orange"
        } else if (value < 80) {
            return "yellow"
        } else {
            return "lime"
        }
    }

    const [collapseSideMenu, setCollapseSideMenu] = useState(true)
    return (
        <div className={`meters absolute bottom-16 left-0 overflow-hidden z-[999] flex gap-4 transition-all ${collapseSideMenu ? "w-150 gaugeDiv" : "w-8 p-0"}`}>
            <div className="arrow absolute top-2/3 right-0 transform -translate-y-1/2">
                <div onClick={() => setCollapseSideMenu(!collapseSideMenu)} className="bg-white w-[30px] h-[30px] rounded-md shadow-lg flex justify-center items-center cursor-pointer text-red-600" >
                    {collapseSideMenu ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
                </div>
            </div>
            <div className={`cycle h-fit`} style={{ visibility: collapseSideMenu ? "visible" : "hidden" }}>
                <Speedometer
                    value={parseFloat(data[counter]?.enginecycle / 1000)}
                    max={8}
                    lineCap="round"
                    fontFamily='squada-one'
                    width={150}
                    height={150}

                >
                    <Background />
                    <Arc arcWidth={2} />
                    <Needle circleRadius={11} baseWidth={4} />
                    <DangerPath />
                    <Progress arcWidth={5} />
                    <Marks step={1} fontSize="15" />
                    <Indicator fontSize={20}>
                        {(value, textProps) => {
                            return (
                                <text
                                    {...textProps}
                                    fontSize={14}
                                    fill="white"
                                    x={43}
                                    y={130}
                                >
                                    {parseInt(data[counter]?.enginecycle)} rpm
                                </text>
                            )
                        }
                        }
                    </Indicator>
                </Speedometer>
            </div>

            <div className="speed h-fit">
                <Speedometer
                    value={parseInt(data[counter]?.vehiclespeed)}
                    max={100}
                    fontFamily='squada-one'
                    width={150}
                    height={150}
                >
                    <Background />
                    <Arc />
                    <Needle circleRadius={11} baseWidth="4" />
                    <Progress />
                    <Marks fontSize="15" step={10} />
                    <Indicator fontSize={26}>
                        {(value, textProps) => {
                            return (
                                <text
                                    {...textProps}
                                    fontSize={16}
                                    fill="white"
                                    x={43}
                                    y={130}
                                >
                                    {value} km/s
                                </text>
                            )
                        }
                        }
                    </Indicator>
                </Speedometer>
            </div>
            <div className="others h-fit">
                <Speedometer
                    value={data[counter]?.fuellevel}
                    max={100}
                    angle={135}
                    lineCap="round"
                    accentColor={perColor(data[counter]?.fuellevel)}
                    width={100}
                    height={75}
                >
                    <Arc arcWidth={10} />
                    <Progress arcWidth={10} />
                    <Indicator fontSize={20}>
                        {(value, textProps) => {
                            return (
                                <text
                                    {...textProps}
                                    fontSize={14}
                                    fill="white"
                                    textAnchor="middle"
                                    alignmentBaseline="middle"
                                    x={50}
                                    y={50}
                                >
                                    Yakıt: {value}%
                                </text>
                            )
                        }
                        }
                    </Indicator>
                </Speedometer>



                <Speedometer
                    value={parseInt(data[counter]?.gaspedalposition)}
                    max={100}
                    angle={135}
                    lineCap="round"
                    accentColor={perColor(100 - data[counter]?.gaspedalposition)}
                    width={100}
                    height={75}
                >
                    <Arc arcWidth={10} />
                    <Progress arcWidth={10} />
                    <Indicator fontSize={20}>
                        {(value, textProps) => {
                            return (
                                <text
                                    {...textProps}
                                    fontSize={14}
                                    fill="white"
                                    textAnchor="middle"
                                    alignmentBaseline="middle"
                                    x={50}
                                    y={50}
                                >
                                    Pedal : {value}%
                                </text>
                            )
                        }
                        }
                    </Indicator>
                </Speedometer>
            </div>
            <div className="infos flex flex-column gap-1 justify-center items-center">
                <span className='text-white'>Araç Saat</span>
                <span className='font-orbitron text-white font-bold tracking-[2px]' >{moment(data[counter - 1].deviceDate).format("HH:mm:ss")}</span>
                <span className={`${data[counter]?.ignition == 1 ? "text-lime-400" : "text-red-400"} block px-3 py-1 bg-slate-600 w-[7.4rem] text-center rounded-full`}>Kontak : {data[counter]?.ignition == 1 ? "Açık" : "Kapalı"}</span>
                <span className={`text-white block bg-red-400 px-3 py-1 rounded-full ${data[counter]?.breakstatus == 1 ? "block" : "hidden"}`}>Fren</span>
            </div>
        </div>
    )
}

export default Speedometers