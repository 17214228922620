import axios from 'axios';

//Get all users from the api
const getAllGroups = async () => {
  return await axios
    .get(
      `${
        process.env.REACT_APP_BASE_URL
      }/tumdepartmanlar?companyName=${localStorage.getItem(
        'userCompany'
      )}&userRole=${localStorage.getItem('userRole')}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      }
    )
    .then((response) => response.data);
};

export default getAllGroups;
