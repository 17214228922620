import { createSlice } from '@reduxjs/toolkit';

const initialGroupState = {
    groupName: null,
    groupCreatedBy: null,
    isActive: null,
    groupAttachedDevices: null,
    groupAuthorizedPersonel: null,
};

const groupSlice = createSlice({
  name: 'group',
  initialState: initialGroupState,
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
  },
});

export default groupSlice;
