import { useState, useEffect } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import { antPath } from 'leaflet-ant-path';

const MyMap = (props) => {

    
    const latlong = props.data.map((item) => [item.latitude, item.longitude]);
    let count = 3;
/*     setInterval(() => {
        count++;
        setRoute(latlong.filter((item,index) => index < count));
    }, 1000); */

    const [route, setRoute] = useState(latlong.filter((item,index) => index < 2));

    const [map, setMap] = useState(null);
    const options = {
        delay: 1000,
        dashArray: [10, 20],
        weight: 5,
        color: '#0000FF',
        pulseColor: '#FFFFFF',
        paused: false,
        reverse: false,
        hardwareAccelerated: true,
    };
    useEffect(() => {
        if (map) {
            const path = antPath(route, options);
            path.addTo(map);
            map.fitBounds(path.getBounds());
        }
    }, [map, options, route]);

    const onMapLoad = (event) => {
        setMap(event.target);
    };

    return (
        <MapContainer className="w-full h-full cursor-crosshair" center={[0, 0]} zoom={14} whenReady={onMapLoad}>
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
        </MapContainer>
    );
};

export default MyMap;