import React, { useEffect, useRef, useState } from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import GaugeChart from 'react-gauge-chart'
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';

const CarDetail = ({ markerRef, mapRef, cars, focusVehicle, setFocusVehicle, lastData }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const scrollonDevice = useRef({})
  const [collapse, setCollapse] = useState({ devices: true })

  const formatNumber = (number) => {
    const str = number.toString();
    const groups = str.match(/.{1,3}/g);
    const formattedNumber = groups.join('.');
    return formattedNumber;
  };
  const isOnline = (imei) => {
    const dateString = lastData.find((item) => item.deviceImei === imei)?.updatedAt ?? 0;
    const date = moment(new Date(dateString)).local().toDate();
    const now = new Date();
    const fiveMinutesAgo = moment(new Date(now.getTime() - 11 * 60 * 1000)).local().toDate();
    return date > fiveMinutesAgo;
  };

  useEffect(() => {
    if (focusVehicle && scrollonDevice.current[focusVehicle]) {
      setCollapse((prevCollapse) => ({ ...prevCollapse, devices: true }));
      scrollonDevice.current[focusVehicle].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [focusVehicle, scrollonDevice, setCollapse]);
  

  const handleCarClick = (imei) => {
    const marker = markerRef.current[imei];
    const map = mapRef.current;
    if (map && marker) {
      const markerPosition = marker.getLatLng();
      map.setView(markerPosition, 16, {
        pan: {
          animate: true,
          duration: 1.5
        },
        zoom: {
          animate: true
        }
      }
      );
      marker.openPopup();
      setFocusVehicle(imei);
    }
  }
  // vehiclePlate numaralarına göre arama yapmak için filter methodunu kullanıldı
  const filteredVehicles = cars?.filter((vehicle) =>
    vehicle.vehiclePlate.replace(/\s+/g, '').toLowerCase().includes(searchTerm.replace(/\s+/g, '').toLowerCase())
  );

  return (
    <>
      <div className={`boxColor absolute top-[53px] z-[400] right-3 w-[10%] min-w-[250px] text-white rounded-lg p-2 transition-all ${collapse.devices == true ? "h-[92%]" : "h-[35px] overflow-hidden"}`}>
        <h6 className="text-center text-white mb-3 font-cinzel font-bold cursor-pointer block" onClick={() => setCollapse({ devices: !collapse.devices })}>
          Araç Detay <KeyboardArrowDownIcon className={`${collapse.devices === true ? "rotate-180" : "rotate-0"} transition-all`} />
        </h6>
        <div className={`form-inline mb-4 ${collapse.devices === true ? "visible" : "invisible"}`}>
          <div className="input-group relative" data-widget="sidebar-search">
            <input
              className="form-control form-control-navbar border-l-0 bg-transparent py-1 text-[1rem] text-white mx-2 rounded-lg pl-10"
              type="search"
              aria-label="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchIcon className='absolute left-5 top-1' />
          </div>
        </div>

        <div className={`scroll-auto overflow-scroll pb-20 ${collapse.devices === true ? "h-[88%]" : "h-0"}`}>
          {filteredVehicles && filteredVehicles.map((el, index) => {
            return (
              <div
                ref={e => scrollonDevice.current[el.vehicleImei] = e}
                key={index}
                className={`hover:bg-blue-500 hover:bg-opacity-30 border-bottom border-top ${focusVehicle === el.vehicleImei ? 'bg-sky-500 bg-opacity-40 hover:bg-sky-500' : ''} rounded-[5px] mt-2 text-blue-900 cursor-pointer`}
                onClick={() => handleCarClick(el.vehicleImei)}
              >
                <div className='plate text-[0.75rem] mt-2 ml-1'>
                  <span className='w-[15px]  bg-blue-600 text-white px-[5px] py-[2px] font-bold'>TR</span>
                  <span className='bg-white text-black font-bold py-[2px] px-1'>{el.vehiclePlate}</span>
                  {/* <span className='float-right mr-3'>{el.vehicleName}</span> */}
                </div>

                <div className='deviceInfo text-[12px] h-[120px] flex flex-column p-2'>
                  <table className='w-[100%]'>
                    <tbody>
                      <tr>
                        <td className='w-min'><span className='font-bold'>Cihaz Donanım :</span></td>
                        <td className={`w-fit ${isOnline(el.vehicleImei) ? "text-lime-400" : "text-gray-600"}`}><FiberManualRecordIcon className={`w-fit mt-[-2px] mx-auto h-[1.2rem]`} />{isOnline(el.vehicleImei) ? "Aktif" : "Pasif"}</td>
                      </tr>
                      <tr>
                        <td><span className='font-bold w-min'>Araç :</span></td>
                        <td className={`${lastData && cars && (lastData.find((item) => item.deviceImei === el.vehicleImei)?.ignition ?? 0) === 1 ? "text-lime-400" : "text-gray-600"}`}><FiberManualRecordIcon className="w-fit mt-[-2px] mx-auto h-[1.2rem]" />
                          {lastData && cars && (lastData.find((item) => item.deviceImei === el.vehicleImei)?.ignition ?? 0) === 1 ? "Aktif" : "Pasif"}
                        </td>
                      </tr>
                      <tr>
                        <td><span className='font-bold w-min'>Total KM :</span></td>
                        <td className='font-bold text-amber-200'>
                          {lastData && cars && formatNumber(parseInt(lastData.find((item) => item.deviceImei === el.vehicleImei)?.totalkm ?? 0))} KM
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <div className="fuelGauge flex mt-2 justify-center items-center w-full h-[45px] overflow-visible">
                    <div className="fuelIconText flex items-center">
                      <LocalGasStationIcon />
                      {lastData && cars && (lastData.find((item) => item.deviceImei === el.vehicleImei)?.fuellevel ?? 0).toFixed(1)}%
                    </div>

                    <GaugeChart id="gauge-chart3"
                      nrOfLevels={420}
                      arcsLength={[0.3, 0.5, 0.2]}
                      colors={['#EA4228', '#F5CD19', '#5BE12C']}
                      percent={lastData && cars && (lastData.find((item) => item.deviceImei === el.vehicleImei)?.fuellevel / 100 ?? 0)}
                      arcPadding={0.02}
                      className='w-[75px]'
                      arcWidth={0.2}
                      hideText={true}
                      animate={false}
                    />


                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CarDetail;
