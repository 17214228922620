import { configureStore } from '@reduxjs/toolkit';
import companySlice from './slices/companySlice';
import deviceSlice from './slices/deviceSlice';
import groupSlice from './slices/groupSlice';
import userSlice from './slices/userSlice';
import devicesTestDataSlice from './slices/devicesTestDataSlice';
import vehicleSlice from './slices/vehicleSlice';

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    company: companySlice.reducer,
    group: groupSlice.reducer,
    device: deviceSlice.reducer,
    deviceData: devicesTestDataSlice.reducer,
    vehicle: vehicleSlice.reducer,
  },
});

export default store;
