import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { useEffect, useRef } from 'react';

const ListArea = ({ data, limits, markerRef, mapRef, focusIndex, setFocusIndex }) => {
  const scrollIndex = useRef({});
  function formatNumber(num) {
    return num.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
  let alarmTypes = {
    1: 'Ani Hızlanma',
    2: 'Ani Fren',
    3: 'Sert Dönüş',
    4: 'Hız Sınırı Aşımı',
  }

  useEffect(() => {
    if (focusIndex && scrollIndex.current[focusIndex]) {
      scrollIndex.current[focusIndex].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [focusIndex, scrollIndex]);

  const handleCarClick = (index) => {
    const marker = markerRef.current[index];
    const map = mapRef.current;
    if (map && marker) {
      const markerPosition = marker.getLatLng();
      map.setView(markerPosition, 16, {
        pan: {
          animate: true,
          duration: 1.5
        },
        zoom: {
          animate: true
        }
      }
      );
      marker.openPopup();
      setFocusIndex(index);
    }

  }

  return (
    <Table className='text-[0.85rem]'>
      <thead className='bg-slate-200 sticky top-0'>
        <tr className='font-semibold'>
          <th>#</th>
          <th>Tarih</th>
          <th>Kontak</th>
          <th>Kilometre (Km)</th>
          <th>Devir (Rpm)</th>
          <th>Gaz</th>
          <th>Hız (Km/h)</th>
          <th>Fren</th>
          <th>Motor Yük</th>
          <th>Tork</th>
          <th>Yakıt</th>
          <th>Tüketim (L/h)</th>
          <th>Alarm</th>
        </tr>
      </thead>
      <tbody>
        {data && data.map((item, index) => (
          <tr key={index} className={`odd:bg-neutral-50 ${focusIndex===index ? "bg-slate-300 animate-pulse" : ""} hover:bg-sky-100 cursor-pointer`} ref={e => scrollIndex.current[index] = e} onClick={() => handleCarClick(index)} >
            <td>
              <div 
              className={`w-7 h-7 text-[9px] font-cinzel border-[2px] border-white font-bold inline-flex shadow-md justify-center items-center rounded-[50%] text-white 
              ${item.alarms ? "bg-red-400" : parseInt(item.vehiclespeed)>limits.speed ? "bg-orange-300" : parseFloat(item.fuelconsumption) >limits.fuelConsumption ? "bg-yellow-300" : "bg-lime-400"}`}>
                {index}
              </div>
            </td>
            <td>{moment(item.deviceDate).local().format("YYYY-MM-DD HH:mm:ss")}</td>
            <td>{item.ignition === "1" ? 'Açık' : 'Kapalı'}</td>
            <td>{formatNumber(parseFloat(item.totalkm))}</td>
            <td>{parseInt(item.enginecycle)}</td>
            <td>{item.gaspedalposition}%</td>
            <td className={`${parseInt(item.vehiclespeed)>limits.speed ? "font-bold bg-red-100" : ""}`}>{parseInt(item.vehiclespeed)}</td>
            <td>{item.handbreakstatus}</td>
            <td>{item.engineload}%</td>
            <td>{item.enginetorque}</td>
            <td className={parseFloat(item.fuellevel)<limits.fuelLevel ? "font-bold bg-red-100" : ""}>
              {item.fuellevel}%
            </td>
            <td className={`${parseFloat(item.fuelconsumption) >limits.fuelConsumption ? "font-bold bg-yellow-100" : ""}`}>
              {item.fuelconsumption} 
            </td>
            <td className={`${item.alarms ? "font-semibold bg-red-100" : item.vehiclespeed > limits.speed ? "bg-orange-100 font-semibold": ""}`}>
              {item.alarms ? alarmTypes[item.alarms] : item.vehiclespeed > limits.speed ? alarmTypes[4] : "-"}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default ListArea;