import React, { useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
// import { useSearchParams } from 'react-router-dom';
import moment from 'moment'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import SelectDate from './SelectDate';
import axios from 'axios';
import { subDays } from 'date-fns';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DataTable from 'react-data-table-component';
import { Button } from "react-bootstrap"

const ShiftLogs = () => {
  // const [searchParams, setSearchParams] = useSearchParams();
  // let deviceImei = searchParams.get('deviceImei');
  const [responseData, setResponseData] = useState();
  const pastMonth = new Date();
  const defaultSelected = {
    from: subDays(pastMonth, 4),
    to: pastMonth,
  };

  const [selectDate, setSelectDate] = useState(defaultSelected)
  const [loader, setLoader] = useState(false)
  const [dateCollapse, setDateCollapse] = useState(false);
  const tableRef = useRef();

  const printTable = () => {
    alert("Not active demo version")
    if (tableRef.current) {
      const tableDiv = tableRef.current;
      const printContents = tableDiv.innerHTML;
      const originalContents = document.body.innerHTML;

      document.body.innerHTML = printContents;
      window.print();

      document.body.innerHTML = originalContents;
    }
  };

  // const calcTime = (time) => {
  //   let hours = Math.floor(time / 60);
  //   let minutes = time % 60;
  //   return hours + "Sa " + minutes + "Dk"
  // }

  const handleDateChange = (date) => {
    setLoader(true)
    let startDate = moment(date.from).format('YYYY-MM-DD')
    let endDate = moment(date.to).format('YYYY-MM-DD')
    axios.get(`${process.env.REACT_APP_BASE_URL}/arac/rapor?startDate=${startDate}&endDate=${endDate}`)
      .then(function (response) {
        setResponseData(response.data)
        setDateCollapse(false)
        setLoader(false)
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false)
      });
  }

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/arac/rapor?startDate=${moment(selectDate.from).format("YYYY-MM-DD")}&endDate=${moment(selectDate.to).format("YYYY-MM-DD")}`)
      .then(function (response) {
        setResponseData(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  //open url in new tab
  const handleOpenHistory = (imei, start, end) => {
    const win = window.open(`${(process.env.REACT_APP_BASE_URL).replace(":7000","")}/arac/hareket/gecmis?deviceImei=${imei.trim()}&startDate=${moment(start).format("YYYY/MM/DDTHH:mm:ss")}&endDate=${moment(end).format("YYYY/MM/DDTHH:mm:ss")}`, '_blank');
    win.focus();
  }

  if (!responseData)
    return (
      <div className="content h-[100vh]">
        <div className="wrapper relative">
          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} >
            <CircularProgress color='inherit' />
          </Backdrop>
        </div>
      </div>
    );

  const columns = [
    {
      name: 'Tarih',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'Araç',
      selector: row => row.vehiclePlate,
      sortable: true,
    },
    {
      name: 'Toplam Çalışma',
      selector: row => row.totalWorkTime,
      sortable: true,
    },
    {
      name: 'Toplam Yol',
      selector: row => row.totalDistance,
      sortable: true,
    },
    {
      name: 'Toplam Yakıt',
      selector: row => row.totalFuel,
      sortable: true,
    },
    {
      name: 'Toplam Yakıt (litre)',
      selector: row => row.totalFuelLitre,
      sortable: true,
    },
  ];
  const data = [];
  Object.keys(responseData).forEach((item, index) => {
    Object.keys(responseData[item].data).forEach((allData, index) => {
      let pushedData = {
        //date : moment.utc(responseData[item].data[allData]?.morning.startTime).format("DD.MM.YYYY") + " - " + moment.utc(responseData[item].data[allData]?.evening.endTime).format("DD.MM.YYYY"),
        date:
          moment(allData).format("YYYY-MM-DD") +
          " - " +
          moment(allData).add(1, "day").format("YYYY-MM-DD"),
        vehiclePlate: responseData[item].vehiclePlate,
        totalWorkTime:
          (
            parseFloat(responseData[item].data[allData].morning.totalTime ?? 0) +
            parseFloat(responseData[item].data[allData].evening.totalTime ?? 0)
          ).toFixed(2) + " Sa",
        totalDistance:
          (
            parseFloat(responseData[item].data[allData]?.morning.totalKM ?? 0) +
            parseFloat(responseData[item].data[allData]?.evening.totalKM ?? 0)
          ).toFixed(2) + " Km",
        totalFuel:
          (
            parseFloat(responseData[item].data[allData]?.morning.fueldiff ?? 0) +
            parseFloat(responseData[item].data[allData]?.evening.fueldiff ?? 0)
          ).toFixed(2) + " %",
        totalFuelLitre:
          (
            parseFloat(responseData[item].data[allData]?.morning.fueldiffLt ?? 0) +
            parseFloat(responseData[item].data[allData]?.evening.fueldiffLt ?? 0)
          ).toFixed(2) + " Lt",
        allData: responseData[item].data[allData],
        imei: responseData[item].vehicleImei,
      };
      data.push(pushedData);
    });
  });
  

  const ExpandedComponent = ({ data }) => {
    return <>
      <div className="expandedComponent">
        <div className="flex gap-4 justify-start flex-column lg:flex-row">
          <div className="expbox shadow-xl p-4 my-2">
            <div className="expboxTitle font-cinzel text-[1.2em] font-bold border-b-2 border-b-orange-400 mb-2">Sabah</div>
            <div className="expboxContent flex flex-col justify-between min-h-[240px]">
              <table>
                <tbody>
                  <tr>
                    <td className='text-right font-semibold'>Başlangıc :</td>
                    <td className='pl-2'>{moment(data.allData?.morning?.startTime).format("DD/MM/YY HH:mm:ss")}</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Bitiş :</td>
                    <td className='pl-2'>{moment(data.allData?.morning?.endTime).format("DD/MM/YY HH:mm:ss")}</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Saat :</td>
                    <td className='pl-2'>{data.allData?.morning?.totalTime ?? "-"} sa.</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Başlangıç KM :</td>
                    <td className='pl-2'>{data.allData?.morning?.startKM ?? "-"} Km</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Bitiş KM :</td>
                    <td className='pl-2'>{data.allData?.morning?.endKM ?? "-"} Km</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam KM :</td>
                    <td className='pl-2'>{data.allData?.morning?.totalKM ?? "-"} Km</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Başlangıç Yakıt :</td>
                    <td className='pl-2'>{data.allData?.morning?.startFuel ?? "-"} %</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Bitiş Yakıt :</td>
                    <td className='pl-2'>{data.allData?.morning?.endFuel ?? "-"} %</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Yakıt :</td>
                    <td className='pl-2'>{data.allData?.morning?.fueldiff ?? "-"} %</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Yakıt Lt :</td>
                    <td className='pl-2'>{data.allData?.morning?.fueldiffLt ?? "-"} Lt.</td>
                  </tr>
                </tbody>
              </table>
              <Button
                className='text-black hover:text-white inline py-[0.1em] mt-2'
                size='sm'
                variant='secondary'
                onClick={() => handleOpenHistory(data.imei, data.allData?.morning?.startTime, data.allData?.morning?.endTime)} >
                Hareketler
              </Button>
            </div>
          </div>
          <div className="expbox shadow-xl p-4 my-2">
            <div className="expboxTitle font-cinzel text-[1.2em] font-bold border-b-2 border-b-orange-400 mb-2">Akşam</div>
            <div className="expboxContent flex flex-column justify-between min-h-[240px]">
              <table>
                <tbody>
                  <tr>
                    <td className='text-right font-semibold'>Başlangıc :</td>
                    <td className='pl-2'>{moment(data.allData?.evening?.startTime).format("DD/MM/YY HH:mm:ss")}</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Bitiş :</td>
                    <td className='pl-2'>{moment(data.allData?.evening?.endTime).format("DD/MM/YY HH:mm:ss")}</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Saat :</td>
                    <td className='pl-2'>{data.allData?.evening?.totalTime ?? "-"} sa.</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Başlangıç KM :</td>
                    <td className='pl-2'>{(data.allData?.evening?.startKM)?.toFixed(2) ?? "-"} Km</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Bitiş KM :</td>
                    <td className='pl-2'>{(data.allData?.evening?.endKM)?.toFixed(2) ?? "-"} Km</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam KM :</td>
                    <td className='pl-2'>{data.allData?.evening?.totalKM ?? "-"} Km</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Başlangıç Yakıt :</td>
                    <td className='pl-2'>{data.allData?.evening?.startFuel ?? "-"}%</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Bitiş Yakıt :</td>
                    <td className='pl-2'>{data.allData?.evening?.endFuel ?? "-"} %</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Yakıt :</td>
                    <td className='pl-2'>{data.allData?.evening?.fueldiff ?? "-"} %</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Yakıt Lt :</td>
                    <td className='pl-2'>{data.allData?.evening?.fueldiffLt ?? "-"} Lt.</td>
                  </tr>
                </tbody>
              </table>
              <Button
                className='text-black hover:text-white inline py-[0.1em] mt-2'
                size='sm'
                variant='secondary'
                onClick={() => handleOpenHistory(data.imei, data.allData?.evening?.startTime, data.allData?.evening?.endTime)} >
                Hareketler
              </Button>
            </div>
          </div>
          <div className="expbox shadow-xl px-8 py-4 my-2">
            <div className="expboxTitle font-cinzel text-[1.2em] font-bold border-b-2 border-b-orange-400 mb-2">Total</div>
            <div className="expboxContent flex flex-column justify-between min-h-[240px]">
              <table>
                <tbody>
                  <tr>
                    <td className='text-right font-semibold'>Tarih :</td>
                    <td className='pl-2'>{data.date}</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Saat :</td>
                    <td className='pl-2'>{data?.totalWorkTime}</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam KM :</td>
                    <td className='pl-2'>{data?.totalDistance}</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Yakıt :</td>
                    <td className='pl-2'>{data?.totalFuel}</td>
                  </tr>
                  <tr>
                    <td className='text-right font-semibold'>Toplam Yakıt (lt) :</td>
                    <td className='pl-2'>{data?.totalFuelLitre}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                className='text-black hover:text-white inline py-[0.1em] mt-2'
                size='sm'
                variant='secondary'
                onClick={() => handleOpenHistory(data.imei, data.allData?.morning?.startTime, data.allData?.evening?.endTime)} >
                Hareketler
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  };
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      <div className='content mt-[3.75rem] relative'>
        <Container>
          <div className='wrapper'>
            <div className="topMenu flex justify-between">
              <SelectDate selectDate={selectDate} setSelectDate={setSelectDate} dateCollapse={dateCollapse} setDateCollapse={setDateCollapse} handleDateChange={handleDateChange} />
              <div className="exports">
                <PrintIcon className='mr-4 mt-2 cursor-pointer hover:text-red-600' onClick={() => printTable()} />
                <FileDownloadIcon className='mt-2 cursor-pointer hover:text-red-600' onClick={() => printTable()} />
              </div>
            </div>
            <hr className='my-3' />
            <DataTable
              //ref={tableRef}
              columns={columns}
              data={data}
              //dense
              direction="auto"
              paginationPerPage={25}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              fixedHeader
              highlightOnHover
              pointerOnHover
              responsive
              subHeaderAlign="right"
              className='max-h-[calc(100vh-180px)]'
              subHeaderWrap
            />
          </div>
        </Container>
      </div>
    </>
  )
}

export default ShiftLogs