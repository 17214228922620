import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/esm/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import './VehicleList.css';
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from 'moment';
import { vehicleListSchema } from '../../schemas';
import { useFormik } from 'formik';

const XLSX = require('xlsx');

const VehicleList = () => {
  const [responseData, setResponseData] = useState([]);
  const [prevImei, setPrevImei] = useState(null);

  const [loader, setLoader] = useState(false);
  // const [vehicles, setVehicles] = useState(null)

  // ******************************
  const [vehicles, setVehicles] = useState([]);
  //vehicle showing add/edit için kullanılan modal
  const [vehicle, setVehicle] = useState({
    showing: false,
    data: null,
    addModal: false,
  });

  const [allDevices, setAllDevices] = useState([])
  const [devices, setDevices] = useState([]);

  const [editInitialState, setEditInitialState] = useState({
    vehicleImei: '',
    vehiclePlate: '',
    vehicleName: '',
    vehicleBrand: '',
    vehicleModel: '',
    storageSize: '',
  });

  // Formik Add vehicleList initialized
  const {
    values: addValues,
    errors: addErrors,
    handleChange: addHandleChange,
    handleSubmit: addHandleSubmit,
    handleBlur: addHandleBlur,
    touched: addTouched,
    // handleChangeImage: addHandleChangeImage,
  } = useFormik({
    initialValues: {
      vehicleImei: '',
      vehiclePlate: '',
      vehicleName: '',
      vehicleBrand: '',
      vehicleModel: '',
      storageSize: '',
    },
    onSubmit: (values) => {
      handleAddVehicle(values);
    },
    validationSchema: vehicleListSchema,
  });

  // Formik Edit vehicleList initialized
  const {
    values: editValues,
    errors: editErrors,
    handleChange: editHandleChange,
    handleSubmit: editHandleSubmit,
    handleBlur: editHandleBlur,
    touched: editTouched,
    // handleChangeImage: editHandleChangeImage,
  } = useFormik({
    enableReinitialize: true,
    initialValues: editInitialState,
    onSubmit: (values) => {
      handleEditSubmit(values);
    },
    validationSchema: vehicleListSchema,
  });

  // ******************************

  const handleGetVehicles = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumaraclar?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(async (response) => {
        setVehicles(response.data);
        setResponseData(response.data);
      });
  };

  const handleGetDevices = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumcihazlar?userRole=${localStorage.getItem('userRole')}&companyName=${localStorage.getItem('userCompany')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(async (response) => {
        setAllDevices(response.data)
        console.log("vehiclelist=>",response);
        let data = response.data.filter((item) => !item.isActive);
        setDevices(data);
      });
  };

  useEffect(() => {
    handleGetVehicles();
    handleGetDevices();
  }, []);

  if (!responseData)
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    );

  const handleClose = () => setVehicle({ showing: false, data: null });

  const handleAddVehicle = async (e) => {
    // e.preventDefault();
    // setLoader(true);

    let formData = {
      // _id: e._id,
      vehicleImei: e.vehicleImei,
      vehiclePlate: e.vehiclePlate,
      vehicleName: e.vehicleName,
      vehicleBrand: e.vehicleBrand,
      vehicleModel: e.vehicleModel,
      storageSize: e.storageSize,
      companyName: localStorage.getItem('userCompany'),
    };

    let deviceId = allDevices.find(device => device.deviceImei === formData.vehicleImei)._id

    //insert işlemi
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/arac/yeni`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(async function (response) {
        // setLoader(false);
        await axios
          .post(`${process.env.REACT_APP_BASE_URL}/cihaz/duzenle`, {deviceId: deviceId, isActive: true}, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => console.log(response));
        setVehicles(setVehicles({ ...vehicles, addModal: false }));
        setResponseData((prevData) => {
          return [...prevData, formData];
        });
        window.location.reload();
      });
  };

  const handleRemove = async (id) => {
    let vehicleImei = await vehicles.find(vehicle => vehicle._id === id).vehicleImei
    let deviceId = allDevices.find(device => device.deviceImei === vehicleImei)._id
    console.log("deviceId=>",deviceId)
    Swal.fire({
      title: 'Emin misiniz?',
      text: 'Bu işlemi geri alamazsınız!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Vazgeç',
      confirmButtonText: 'Evet, sil!',
    }).then((result) => {
      if (result.isConfirmed) {
        // setLoader(true);
        console.log("handleremove=>",id);
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/arac/sil`,
            { vehicleId: id },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(async () => {
            await axios
          .post(`${process.env.REACT_APP_BASE_URL}/cihaz/duzenle`, {deviceId: deviceId, isActive: false}, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => console.log(response));
            Swal.fire(
              'Silindi!',
              'Silme işleminiz gerçekleştirildi.',
              'success'
            ).then(() => window.location.reload());
          });
      }
    });
  };
  // console.log(responseData);

  const handleEdit = (index) => {
    setPrevImei(responseData[index].vehicleImei);
    setVehicle({ showing: true, index: index });
    // setShow({ visible: true, index: index });
    setEditInitialState({
      vehicleImei: responseData[index].vehicleImei,
      vehiclePlate: responseData[index].vehiclePlate,
      vehicleName: responseData[index].vehicleName,
      vehicleBrand: responseData[index].vehicleBrand,
      vehicleModel: responseData[index].vehicleModel,
      storageSize: responseData[index].storageSize,
    });
  };

  const handleEditSubmit = async (element) => {
    // element.preventDefault();

    let formData = {
      prevImei: prevImei,
      vehicleImei: element.vehicleImei,
      vehiclePlate: element.vehiclePlate,
      vehicleName: element.vehicleName,
      vehicleBrand: element.vehicleBrand,
      vehicleModel: element.vehicleModel,
      storageSize: element.storageSize,
    };

    let deviceId = allDevices.find(device => device.deviceImei === formData.vehicleImei)._id
    let deviceIdPrev = allDevices.find(device => device.deviceImei === formData.prevImei)._id
    console.log(deviceId, deviceIdPrev);

    //update işlemi
    await axios
      .post(`${process.env.REACT_APP_BASE_URL}/arac/duzenle`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then(async function (response) {
        if(formData.prevImei !== formData.vehicleImei) {
          await axios
          .post(`${process.env.REACT_APP_BASE_URL}/cihaz/duzenle/2`, {deviceId: deviceId, deviceIdPrev: deviceIdPrev}, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          })
          .then((response) => console.log(response));
        }
        console.log("vehiclelistedit=>",response);
        window.location.reload();
      });
  };

  //Create Excel File
  let myArr = [];
  const handleExportExcel = async () => {
    vehicles.map((item) => {
      myArr.push({
        'IMEI Numarası': item.vehicleImei,
        'Plaka Numarası': item.vehiclePlate,
        'Araç Adı': item.vehicleName,
        'Araç Markası': item.vehicleBrand,
        'Araç Modeli': item.vehicleModel,
        'Yakıt Depo Boyutu': item.storageSize,
      });
    });
    let filename = `${moment(new Date()).format(
      'DD-MM-YYYY HH:mm'
    )} AraçListesi.xlsx`;
    var ws = XLSX.utils.json_to_sheet(myArr);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, filename);
  };

  const handleClickAlert = () => {
    //   alert('adadasd')
    // console.log('adasda');
    return (
      // <Stack sx={{ width: '100%' }} spacing={2}>

      //     <Alert variant="filled" severity="info">
      //         Bu bir demo sürümüdür — aktif değil!
      //     </Alert>

      // </Stack>
      alert('Demo Sürümünde Aktif Değil!')
    );
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      {/* <Snackbar data={snack} setData={setSnack} /> */}

      {/* Add Vehicle List Modal */}
      <Modal
        show={vehicle.addModal}
        onHide={() => setVehicle({ ...vehicle, addModal: false })}
        style={{ marginTop: '-20px' }}
      >
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
            Yeni Araç Ekle
          </h3>

          <Form onSubmit={addHandleSubmit}>
            <Form.Group className='mb-3 mt-5'>
              <Form.Label className='formLabel'>IMEI Numarası</Form.Label>
              <Form.Control
                as='select'
                type='Text'
                name='vehicleImei'
                id='vehicleImei'
                defaultValue='IMEI SEÇİNİZ'
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehicleImei) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehicleImei: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={addErrors.vehicleImei ? 'imeiNoInputError' : ''}
                autoFocus
              >
                <option disabled>IMEI SEÇİNİZ</option>
                {devices.length > 0 &&
                  devices.map((item, index) => {
                    return <option key={index}>{item.deviceImei}</option>;
                  })}
              </Form.Control>

              {/* {addErrors.vehicleImei && <p className="error"> { addErrors.vehicleImei } </p>} */}

              {addTouched.vehicleImei && addErrors.vehicleImei ? (
                <div>
                  {addErrors.vehicleImei && (
                    <p className='error'> {addErrors.vehicleImei} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Plaka Numarası</Form.Label>
              <Form.Control
                type='Text'
                name='vehiclePlate'
                id='vehiclePlate'
                placeholder='Plaka Numarasını Giriniz'
                value={vehicle.data?.vehiclePlate ?? addValues.vehiclePlate}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehiclePlate) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehiclePlate: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={
                  addErrors.vehiclePlate ? 'vehiclePlateInputError' : ''
                }
              />

              {/* {addErrors.vehiclePlate && <p className="error"> { addErrors.vehiclePlate } </p>} */}

              {addTouched.vehiclePlate && addErrors.vehiclePlate ? (
                <div>
                  {addErrors.vehiclePlate && (
                    <p className='error'> {addErrors.vehiclePlate} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Araç Adı</Form.Label>
              <Form.Control
                type='Text'
                name='vehicleName'
                id='vehicleName'
                placeholder='Araç Adını Giriniz'
                value={vehicle.data?.vehicleName ?? addValues.vehicleName}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehicleName) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehicleName: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={addErrors.vehicleName ? 'vehicleNameInputError' : ''}
              />

              {/* {addErrors.vehicleName && <p className="error"> { addErrors.vehicleName } </p>} */}

              {addTouched.vehicleName && addErrors.vehicleName ? (
                <div>
                  {addErrors.vehicleName && (
                    <p className='error'> {addErrors.vehicleName} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Araç Markası</Form.Label>
              <Form.Control
                type='Text'
                name='vehicleBrand'
                id='vehicleBrand'
                placeholder='Araç Markasını Giriniz'
                value={vehicle.data?.vehicleBrand ?? addValues.vehicleBrand}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehicleBrand) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehicleBrand: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={
                  addErrors.vehicleBrand ? 'vehicleBrandInputError' : ''
                }
              />

              {/* {addErrors.vehicleBrand && <p className="error"> { addErrors.vehicleBrand } </p>} */}

              {addTouched.vehicleBrand && addErrors.vehicleBrand ? (
                <div>
                  {addErrors.vehicleBrand && (
                    <p className='error'> {addErrors.vehicleBrand} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Araç Modeli</Form.Label>
              <Form.Control
                type='Text'
                name='vehicleModel'
                id='vehicleModel'
                placeholder='Araç Modelini Giriniz'
                value={vehicle.data?.vehicleModel ?? addValues.vehicleModel}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehicleModel) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehicleModel: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={
                  addErrors.vehicleModel ? 'vehicleModelInputError' : ''
                }
              />

              {/* {addErrors.vehicleModel && <p className="error"> { addErrors.vehicleModel } </p>} */}

              {addTouched.vehicleModel && addErrors.vehicleModel ? (
                <div>
                  {addErrors.vehicleModel && (
                    <p className='error'> {addErrors.vehicleModel} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Yakıt Depo Boyutu</Form.Label>
              <Form.Control
                type='Text'
                name='storageSize'
                id='storageSize'
                placeholder='Yakıt Depo Boyutunu Giriniz'
                value={vehicle.data?.storageSize ?? addValues.storageSize}
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.storageSize) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        storageSize: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={addErrors.storageSize ? 'storageSizeInputError' : ''}
              />

              {/* {addErrors.storageSize && <p className="error"> { addErrors.storageSize } </p>} */}

              {addTouched.storageSize && addErrors.storageSize ? (
                <div>
                  {addErrors.storageSize && (
                    <p className='error'> {addErrors.storageSize} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <div className='d-flex justify-content-between mt-5'>
              <Button
                variant='outline-primary'
                type='submit'
                className=' d-block m-auto'
              >
                Ekle
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/*  Vehicle List Modal */}

      {/* Edit Vehicle List Modal */}
      <Modal
        show={vehicle.showing}
        onHide={handleClose}
        style={{ marginTop: '60px' }}
      >
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
            Araç Düzenle
          </h3>

          <Form onSubmit={editHandleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>IMEI Numarası</Form.Label>
              <Form.Control
                as='select'
                type='Text'
                name='vehicleImei'
                id='vehicleImei'
                // defaultValue='IMEI SEÇİNİZ'
                // placeholder='IMEI Numarası'
                // defaultValue={responseData[show.index]?.vehicleImei}
                value={vehicle.data?.vehicleImei ?? editValues.vehicleImei}
                onBlur={editHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehicleImei) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehicleImei: e.target.value,
                      },
                    }));
                  } else editHandleChange(e);
                }}
                className={
                  editErrors.vehicleImei ? 'vehicleImeiInputError' : ''
                }
                autoFocus
              >
                <option disabled>
                  {vehicle.data?.vehicleImei ?? editValues.vehicleImei}
                </option>
                {devices.length > 0 &&
                  devices.map((item, index) => {
                    return <option key={index}>{item.deviceImei}</option>;
                  })}
              </Form.Control>
              {/* {addErrors.vehicleImei && <p className="error"> { addErrors.vehicleImei } </p>} */}

              {editTouched.vehicleImei && addErrors.vehicleImei ? (
                <div>
                  {addErrors.vehicleImei && (
                    <p className='error'> {addErrors.vehicleImei} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Plaka No</Form.Label>
              <Form.Control
                type='Text'
                placeholder='Plaka Numarası'
                name='vehiclePlate'
                id='vehiclePlate'
                // defaultValue={responseData[show.index]?.vehiclePlate}
                value={vehicle.data?.vehiclePlate ?? editValues.vehiclePlate}
                onBlur={editHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehiclePlate) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehiclePlate: e.target.value,
                      },
                    }));
                  } else editHandleChange(e);
                }}
                className={
                  editErrors.vehiclePlate ? 'vehiclePlateInputError' : ''
                }
              />
              {/* {addErrors.vehiclePlate && <p className="error"> { addErrors.vehiclePlate } </p>} */}

              {editTouched.vehiclePlate && addErrors.vehiclePlate ? (
                <div>
                  {addErrors.vehiclePlate && (
                    <p className='error'> {addErrors.vehiclePlate} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Araç Adı</Form.Label>
              <Form.Control
                type='Text'
                placeholder='Araç Adı'
                name='vehicleName'
                id='vehicleName'
                // defaultValue={responseData[show.index]?.vehicleName}
                value={vehicle.data?.vehicleName ?? editValues.vehicleName}
                onBlur={editHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehicleName) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehicleName: e.target.value,
                      },
                    }));
                  } else editHandleChange(e);
                }}
                className={
                  editErrors.vehicleName ? 'vehicleNameInputError' : ''
                }
              />
              {/* {addErrors.vehicleName && <p className="error"> { addErrors.vehicleName } </p>} */}

              {editTouched.vehicleName && addErrors.vehicleName ? (
                <div>
                  {addErrors.vehicleName && (
                    <p className='error'> {addErrors.vehicleName} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Araç Markası</Form.Label>
              <Form.Control
                type='Text'
                placeholder='Araç Markası'
                name='vehicleBrand'
                id='vehicleBrand'
                // defaultValue={responseData[show.index]?.vehicleName}
                value={vehicle.data?.vehicleBrand ?? editValues.vehicleBrand}
                onBlur={editHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehicleBrand) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehicleBrand: e.target.value,
                      },
                    }));
                  } else editHandleChange(e);
                }}
                className={
                  editErrors.vehicleBrand ? 'vehicleBrandInputError' : ''
                }
              />
              {/* {addErrors.vehicleBrand && <p className="error"> { addErrors.vehicleBrand } </p>} */}

              {editTouched.vehicleBrand && addErrors.vehicleBrand ? (
                <div>
                  {addErrors.vehicleBrand && (
                    <p className='error'> {addErrors.vehicleBrand} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Araç Modeli</Form.Label>
              <Form.Control
                type='Text'
                placeholder='Araç Modeli'
                name='vehicleModel'
                id='vehicleModel'
                // defaultValue={responseData[show.index]?.vehicleModel}
                value={vehicle.data?.vehicleModel ?? editValues.vehicleModel}
                onBlur={editHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.vehicleModel) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        vehicleModel: e.target.value,
                      },
                    }));
                  } else editHandleChange(e);
                }}
                className={
                  editErrors.vehicleModel ? 'vehicleModelInputError' : ''
                }
              />
              {/* {addErrors.vehicleModel && <p className="error"> { addErrors.vehicleModel } </p>} */}

              {editTouched.vehicleModel && addErrors.vehicleModel ? (
                <div>
                  {addErrors.vehicleModel && (
                    <p className='error'> {addErrors.vehicleModel} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Yakıt Depo Boyutu</Form.Label>
              <Form.Control
                type='Text'
                placeholder='Yakıt Depo Boyutu'
                name='storageSize'
                id='storageSize'
                // defaultValue={responseData[show.index]?.storageSize}
                value={vehicle.data?.storageSize ?? editValues.storageSize}
                onBlur={editHandleBlur}
                onChange={(e) => {
                  if (vehicle.data?.storageSize) {
                    setVehicle((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        storageSize: e.target.value,
                      },
                    }));
                  } else editHandleChange(e);
                }}
                className={
                  editErrors.storageSize ? 'storageSizeInputError' : ''
                }
              />
              {/* {addErrors.storageSize && <p className="error"> { addErrors.storageSize } </p>} */}

              {editTouched.storageSize && addErrors.storageSize ? (
                <div>
                  {addErrors.storageSize && (
                    <p className='error'> {addErrors.storageSize} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>
            <div className='d-flex justify-content-between'>
              <Button variant='outline-secondary' onClick={handleClose}>
                Vazgeç
              </Button>
              <Button variant='outline-primary' type='submit'>
                Değişiklikleri Kaydet
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/* Edit Vehicle List Modal */}

      <div className='content mt-[3.75rem]'>
        <Container>
          <div className='wrapper'>
            <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
              Araç Listesi
            </h3>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={handleExportExcel}
                color='success'
                variant='outline-success'
                className='text-[0.75rem] mb-4'
              >
                Excel Dosyası Oluştur <FileDownloadIcon />
              </Button>
              <Button
                // onClick={handleClickAlert}

                onClick={() => setVehicle({ ...vehicle, addModal: true })}
                color='primary'
                variant='outline-primary'
                className='text-[0.75rem] mb-4'
              >
                Yeni Araç Ekle <AddIcon />
              </Button>
            </div>

            {responseData && responseData.length > 0 ? (
              <Table bordered hover className=''>
                <thead>
                  <tr>
                    <th style={{ textAlign: '' }}>#</th>
                    <th style={{ textAlign: '' }}>IMEI NUMARA</th>
                    <th style={{ textAlign: '' }}>PLAKA NUMARA </th>
                    <th style={{ textAlign: '' }}> ADI</th>
                    <th style={{ textAlign: '' }}>MARKASI</th>
                    <th style={{ textAlign: '' }}>MODELİ</th>
                    <th style={{ textAlign: 'center' }}>YAKIT DEPO BOYUTU</th>
                    <th style={{ textAlign: 'center' }}>İşlemler</th>
                  </tr>
                </thead>

                <tbody>
                  {(responseData.length > 0 &&
                    responseData.map((el, index) => {
                      return (
                        <tr key={index}>
                          <td>{index}</td>
                          <td>{el.vehicleImei}</td>
                          <td>{el.vehiclePlate}</td>
                          <td>{el.vehicleName}</td>
                          <td>{el.vehicleBrand}</td>
                          <td>{el.vehicleModel}</td>
                          <td style={{ textAlign: 'center' }}>
                            {el.storageSize} -lt
                          </td>
                          <td className='text-center'>
                            <OverlayTrigger
                              key={'top'}
                              placement={'top'}
                              overlay={
                                <Tooltip
                                  style={{ fontSize: '12px' }}
                                  id={`tooltip-${'top'}`}
                                >
                                  {'Düzenle'}
                                </Tooltip>
                              }
                            >
                              <Button
                                className='w-[1rem] p-1 min-w-[1.9rem]'
                                color='success'
                                variant='outlined'
                                size='small'
                              >
                                <EditIcon
                                  className='text-[1rem] text-success'
                                  onClick={() => handleEdit(index)}
                                  // onClick={() => handleClickAlert(el._id)}

                                  //onClick={() => setShow(el)}
                                />
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger
                              key={'top2'}
                              placement={'top'}
                              overlay={
                                <Tooltip
                                  style={{ fontSize: '12px' }}
                                  id={`tooltip-${'top'}`}
                                >
                                  {'Sil'}
                                </Tooltip>
                              }
                            >
                              <Button
                                className='w-[1rem] p-1 min-w-[1.9rem] ml-1'
                                color='error'
                                variant='outlined'
                                size='small'
                              >
                                <DeleteIcon
                                  className='text-[1rem] text-danger'
                                  // onClick={() => handleClickAlert(el._id)}
                                  onClick={() => handleRemove(el._id)}
                                />
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })) || (
                    <tr>
                      <td colSpan={8}>
                        <Alert severity='info'>Kayıtlı araç bulunamadı..</Alert>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default VehicleList;
