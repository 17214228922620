import deviceSlice from '../slices/deviceSlice';
import getAllDevices from '../../handlers/getAllDevices';

export const deviceActions = deviceSlice.actions;

//Get all devices
export const fetchDevices = () => {
  return async (dispatch) => {
    const response = await getAllDevices();
    dispatch(deviceActions.setDevices(response));
  };
};