import React, { useState } from 'react'
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';


const Snack = ({data, setData}) => {

    setTimeout(() => {
        setData({message : null, show : false})
    }, 5000);

    return (
        <Snackbar
            className='z-[9999999999]'
            open={data.show}
            TransitionComponent={Slide}
            message={data?.message}
        />
    )
}

export default Snack