import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import { useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { useAppSelector, useAppDispatch } from "../redux/hooks/hooks";
import { fetchUsers } from "../redux/actions/userActions";
import Button from "react-bootstrap/esm/Button";
import OverlayTrigger from "react-bootstrap/esm/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import axios from "axios";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import moment from "moment"
import { useFormik } from 'formik';
import './Users.css';
import { usersSchema } from "../schemas";

const XLSX = require('xlsx');

export default function Users({initialValues, onSubmit}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch(); //Define dispatch hook

  const users = useAppSelector((state) => state.user.users); //Get all users from the state
  const [responseData, setResponseData] = useState(null);
  const [groups, setGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  
  //user.showing edit ve add için kullanılan modal
  const [user, setUser] = useState({ showing: false, data: null, addModal: false });

  const [editInitialState, setEditInitialState] = useState({
    userId: '',
    userEmail: '',
    userPassword: '',
    // userRole: '',
  })

      // FORMIK Add User initialized
      const {values: addValues, errors: addErrors, handleChange: addHandleChange, handleSubmit: addHandleSubmit, handleBlur: addHandleBlur, touched: addTouched} = useFormik({
        initialValues: {
          // userId: '',
          userEmail: '',
          userPassword: '',
          // userRole: '',
        },
        onSubmit: values => {
          handleAddUser(values);
         },
        validationSchema: usersSchema,
    
      });

        // Formik Edit User initialized
      const { values: editValues, errors: editErrors, handleChange: editHandleChange, handleSubmit: editHandleSubmit, handleBlur: editHandleBlur, touched: editTouched } = useFormik({
        enableReinitialize: true,
        initialValues: editInitialState,
        onSubmit: (values) => {
          console.log("Burası => ", values);
          handleEditSubmit(values);
        },
        validationSchema: usersSchema,
      });

    // FORMIK Ad USers initialized
  // const {values, errors, handleChange ,handleSubmit,handleBlur, touched} = useFormik({
  //   initialValues: {
  //     userEmail: '',
  //     userPassword: '',
  //     // userRole: '',
  //   },
  //   onSubmit: values => {
  //     handleAddUser(values);
  //    },
  //   validationSchema: usersSchema,

  // });
  


  useEffect(() => {
    handleGetGroups();
  }, []);


  useEffect(() => {
    // console.log(userGroups);
  }, [userGroups]);

  const handleGetGroups = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/kullanicilar`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((response) => setGroups(response.data));
  };

  // console.log(users);
  const handleShow = (id) => {
    let editUser = users.find((item) => item._id === id);
    setUser({ showing: true, data: editUser });
  };
  const handleClose = () => setUser({ showing: false, data: null });

  const handleAddUser = async (e) => {
    // e.preventDefault();
    console.log("handleAddUser=>",e);

    let formData = {
      userEmail: e.userEmail,
      userPassword: e.userPassword,
      userCompany: localStorage.getItem('userCompany')
      // userAuthorizedPersonel: userAuthorizedPersonel,
      // userGroups: userGroups,
    };

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.userEmail)) return;
    
      //insert işlemi
      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/kullanici/yeni`, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(function (response) {
          setUser(setUser({...user, addModal: false}))
          setResponseData((prevData) => {
            return [...prevData, formData];
          });
          window.location.reload();
        });
    
  };

  //Get and Update users array on every render
  useEffect(() => {
    try {
      const userRole = localStorage.getItem('userRole')
      if(userRole !== "superadmin") navigate('/')
      dispatch(fetchUsers());
    } catch (err) {
      console.log(err);
      navigate("/login");
    }
  }, [dispatch, navigate]);

  const handleRemove = (userId) => {
    // Are u sure delete it function
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlemi geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Vazgeç",
      confirmButtonText: "Evet, sil!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(
            `${process.env.REACT_APP_BASE_URL}/kullanici/sil`,{userId},
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            Swal.fire(
              "Silindi!",
              "Silme işleminiz gerçekleştirildi.",
              "success"
            );
          }).then(function (response) {
          window.location.reload();
            
          });
      }
    });
  };

  //Create Excel File
  let myArr = []
  const handleExportExcel = async () => {
    users.map(item => {
      myArr.push({ 'Kullanıcı Email': item.userEmail, 'Kullanıcı Rolü': item.userRole })
    });
    let filename = `${moment(new Date()).format('DD-MM-YYYY HH:mm')} Kullanıcılar.xlsx`;
    var ws = XLSX.utils.json_to_sheet(myArr);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, filename);
  };


  // User role function
  function handleUserRoleChange(event) {
    console.log("Seçilen Kullanıcı rolü:", event.target.value);
  }

  const handleClickAlert = () => {
    //   alert('adadasd')
    // console.log('adasda');
    return (
      // <Stack sx={{ width: '100%' }} spacing={2}>

      //     <Alert variant="filled" severity="info">
      //         Bu bir demo sürümüdür — aktif değil!
      //     </Alert>

      // </Stack>
      alert('Demo Sürümünde Aktif Değil!')
    );
  };



  const handleEdit = (index) => {
    console.log("handleEdit=>",users[index])
    setUser({ showing: true, index: index });
    // setShow({ visible: true, index: index });
    setEditInitialState({
      userId : users[index]._id,
      userEmail: users[index].userEmail,
      userPassword: users[index].userPassword,
    })
    console.log("EDIT", editInitialState);
  };



  const handleEditSubmit = async (e) => {
    // e.preventDefault();
    let formData = {
      userId : e.userId,
      userEmail : e.userEmail,
      userPassword : e.userPassword,
    };


    // console.log(formData);
// return

          //update işlemi
          await axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/kullanici/duzenle`,formData,
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            // .then(function (response) {
            //   window.location.reload();
            // });
        .then(function (response) {
          // setUser(setUser({...user, handleClose}))
          // users((prevData) => {
          //   return [...prevData, formData];
          // });
          window.location.reload();
        });

  }


  // const formik = useFormik({
  //   initialValues: initialValues,
  //   validationSchema: usersSchema,
  // });

  // console.log(formik);


  return (
    <>

      {/* Add User Modal */}
      <Modal show={user.addModal} onHide={() => setUser({...user, addModal : false})}>
        <Container className="p-12">
          <h3 className="font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700">
              Yeni Kullanıcı Ekle
          </h3>

          <Form onSubmit={addHandleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="formLabel"> E-mail</Form.Label>
              <Form.Control
                name="userEmail"
                type="Email"
                id="userEmail"
                value={user.data?.userEmail ?? addValues.userEmail}
                placeholder="E-mail adresinizi giriniz"
                // onChange={checkEmail}
                onBlur={addHandleBlur}
                // onChange={handleChange}
                onChange={(e) => {
                  if (user.data?.userEmail){
                    setUser(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        userEmail: e.target.value,
                      },
                      
                    }));
                  } else addHandleChange(e)
                }}
                // {...formik.getFieldProps('userEmail')}
                // className={formik.touched.userEmail && formik.errors.userEmail ? 'userEmailInputError' : ''}
                className={addErrors.userEmail ? "emailInputError": ""}
                autoFocus
              />
              {/* {errors.userEmail && <p className="error"> { errors.userEmail } </p>} */}

              {addTouched.userEmail && addErrors.userEmail ? (
                <div>
                  {addErrors.userEmail && (
                    <p className="error"> {addErrors.userEmail} </p>
                  )}
                </div>
              ) : null}  

            </Form.Group>
            <Form.Group
              className="mb-3"
            >
              <Form.Label className="formLabel"> Parola</Form.Label>
              <Form.Control
                name="userPassword"
                type="Password"
                id="userPassword"
                value={user.data?.userPassword ?? addValues.userPassword}
                placeholder="Parolanızı Giriniz"
                onBlur={addHandleBlur}
                onChange={(e) => {
                  if (user.data!=null){
                    setUser(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        userPassword: e.target.value
                      }
                    }));
                  } else addHandleChange(e)
                }}
                className={addErrors.userPassword ? "passwordInputError": ""}

              />
              {/* {errors.userPassword && <p className="error"> { errors.userPassword } </p>} */}

              {addTouched.userPassword && addErrors.userPassword ? (
                <div>
                  {addErrors.userPassword && (
                    <p className="error"> {addErrors.userPassword} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>

            {/* <Form.Group className="mb-5">
              <Form.Label className="formLabel"> Rolü</Form.Label>
              <Form.Select aria-label="Default select example"
                // onChange={handleUserRoleChange}
              name="userRole"
              id="userRole"
              value={user.data?.userRole ?? values.userRole}
              onBlur={handleBlur}
              onChange={(e) => {
                  if (user.data!=null){
                    setUser(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        userRole: e.target.value
                      }
                    }));
                  } else handleChange(e)
                }}
              // type="Text"
              // placeholder="Rolü Giriniz"
              // defaultValue={user.data?.userRole ?? ""}
              className={errors.userRole ? "userRoleInputError": ""}
              >
                <option value="">Lütfen Kullanıcı Türü Seçiniz</option>
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="superadmin">SüperAdmin</option>
              </Form.Select>

              {/* {errors.userRole && <p className="error"> { errors.userRole } </p>} */}

              {/* {touched.userRole && errors.userRole ? (
                <div>
                  {errors.userRole && (
                    <p className="error"> {errors.userRole} </p>
                  )}
                </div>
              ) : null} 

            </Form.Group> */} 

            <div className="d-flex justify-content-between">
              <Button className="mt-5" variant="outline-secondary" onClick={handleClose}>
                Vazgeç
              </Button>
              <Button className="mt-5" variant="outline-primary" type="submit">
                {/* Değişiklikleri Kaydet */}
                Ekle
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/* Add User Modal */}

      
      {/* Edit User Modal */}
      <Modal show={user.showing} onHide={handleClose}>
        <Container className="p-12">
          <h3 className="font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700">
              Kullanıcı Düzenle
          </h3>

          <Form onSubmit={editHandleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="formLabel"> E-mail</Form.Label>
              <Form.Control
                name="userEmail"
                type="Email"
                id="userEmail"
                // value={user.data?.userEmail ?? addValues.userEmail}
                value={ editValues.userEmail}
                placeholder="E-mail adresinizi giriniz"
                // onChange={checkEmail}
                onBlur={editHandleBlur}
                // onChange={handleChange}
                onChange={editHandleChange}
                // {...formik.getFieldProps('userEmail')}
                // className={formik.touched.userEmail && formik.errors.userEmail ? 'userEmailInputError' : ''}
                className={editErrors.userEmail ? "emailInputError": ""}
                autoFocus
              />
              {/* {errors.userEmail && <p className="error"> { errors.userEmail } </p>} */}

              {editTouched.userEmail && editErrors.userEmail ? (
                <div>
                  {editErrors.userEmail && (
                    <p className="error"> {editErrors.userEmail} </p>
                  )}
                </div>
              ) : null}  

            </Form.Group>
            <Form.Group
              className="mb-3"
            >
              <Form.Label className="formLabel"> Parola</Form.Label>
              <Form.Control
                name="userPassword"
                type="Password"
                id="userPassword"
                // value={user.data?.userPassword ?? addValues.userPassword}
                value={editValues.userPassword}
                placeholder="Parolanızı Giriniz"
                onBlur={editHandleBlur}
                onChange={editHandleChange}
                className={editErrors.userPassword ? "passwordInputError": ""}

              />
              {/* {errors.userPassword && <p className="error"> { errors.userPassword } </p>} */}

              {editTouched.userPassword && editErrors.userPassword ? (
                <div>
                  {editErrors.userPassword && (
                    <p className="error"> {editErrors.userPassword} </p>
                  )}
                </div>
              ) : null}

            </Form.Group>

            {/* <Form.Group className="mb-5">
              <Form.Label className="formLabel"> Rolü</Form.Label>
              <Form.Select aria-label="Default select example"
                // onChange={handleUserRoleChange}
              name="userRole"
              id="userRole"
              value={user.data?.userRole ?? values.userRole}
              onBlur={handleBlur}
              onChange={(e) => {
                  if (user.data!=null){
                    setUser(prevState => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        userRole: e.target.value
                      }
                    }));
                  } else handleChange(e)
                }}
              // type="Text"
              // placeholder="Rolü Giriniz"
              // defaultValue={user.data?.userRole ?? ""}
              className={errors.userRole ? "userRoleInputError": ""}
              >
                <option value="">Lütfen Kullanıcı Türü Seçiniz</option>
                <option value="user">User</option>
                <option value="admin">Admin</option>
                <option value="superadmin">SüperAdmin</option>
              </Form.Select>

              {/* {errors.userRole && <p className="error"> { errors.userRole } </p>} */}

              {/* {touched.userRole && errors.userRole ? (
                <div>
                  {errors.userRole && (
                    <p className="error"> {errors.userRole} </p>
                  )}
                </div>
              ) : null} 

            </Form.Group> */} 

            <div className="d-flex justify-content-between">
              <Button className="mt-5" variant="outline-secondary" onClick={handleClose}>
                Vazgeç
              </Button>
              <Button className="mt-5" variant="outline-primary" type="submit">
                Değişiklikleri Kaydet
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/* Edit User Modal */}

      <div className="content mt-[3.75rem]">
        <Container>
          <div className="wrapper">
            <h3 className="font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700">
              Kullanıcılar
            </h3>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
              <Button
                onClick={handleExportExcel}
                color="success"
                variant="outline-success"
                className="text-[0.75rem] mb-4"
              >
                Excel Dosyası Oluştur < FileDownloadIcon />
              </Button>
              <Button
                // onClick={handleClickAlert}
                onClick={() => setUser({...user, addModal : true})}
                color="primary"
                variant="outline-primary"
                className="text-[0.75rem] mb-4"
              >
                Yeni Kullanıcı Ekle < PersonAddIcon className="ml-2" />
              </Button>
            </div>


            {users && users.length > 0 ? (
              <Table bordered hover className="">
                <thead>
                  <tr>
                    <th style={{ textAlign: "" }}>KULLANICI E-MAİL</th>
                    {/* <th style={{ textAlign: "" }}>KULLANICI ROLÜ</th> */}
                    <th style={{ textAlign: "center" }}>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td align="justify " style={{ textAlign: "" }}>
                          {item.userEmail}
                        </td>
                        {/* <td align="justify " style={{ textAlign: "" }}>{item.userRole}</td> */}
                        <td align="center " style={{ textAlign: "" }}>

                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip
                                style={{ fontSize: "12px" }}
                                id={`tooltip-${"top"}`}
                              >
                                {"Düzenle"}
                              </Tooltip>
                            }
                          >
                            <Button
                              // onClick={() => handleClickAlert(item.index)}
                              // onClick={() => handleShow(item.index)}
                              onClick={() => handleEdit(index)}
                              className="p-0"
                              variant="outline-secondary"
                              size="sm"
                              style={{
                                borderStyle: "none",
                                borderRadius: "5px",
                              }}
                            >
                              <EditIcon className="p-1" />
                            </Button>
                          </OverlayTrigger>


                          <OverlayTrigger
                            placement={"top"}
                            overlay={
                              <Tooltip
                                style={{ fontSize: "12px" }}
                                id={`tooltip-${"top"}`}
                              >
                               {" Sil"}
                              </Tooltip>
                            }
                          >
                            <Button
                              // onClick={() => handleClickAlert(item.index)}
                              className="p-0"
                              variant="outline-danger"
                              size="sm"
                              style={{
                                borderStyle: "none",
                                borderRadius: "5px",
                              }}
                            onClick={() => handleRemove(item._id)}
                            >
                              <DeleteIcon className="p-1" />
                            </Button>
                          </OverlayTrigger>

                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <p>Yükleniyor...</p>
            )}
          </div>
        </Container>

      </div>
    </>
  );
}