import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import 'react-datepicker/dist/react-datepicker.css';
import $ from 'jquery';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { DayPicker } from 'react-day-picker';
import { subDays } from 'date-fns';

import { Form } from 'react-bootstrap';
import { Backdrop, CircularProgress } from '@mui/material';
import moment from 'moment';
import { tr } from 'date-fns/locale';
import styles from 'react-day-picker/dist/style.module.css';

export default function GpsLogs() {
  const [gpsLogs, setGpsLogs] = useState([]);
  const [loader, setLoader] = useState(false);

  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);

  const getDevices = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumcihazlar`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
        setDevices(response.data);
      });
  };

  useEffect(() => {
    getDevices();
  }, []);

  const pastMonth = new Date();
  const defaultSelected = {
    from: subDays(pastMonth, 3),
    to: pastMonth,
  };
  const [range, setRange] = useState(defaultSelected);

  const handleGetGpsLogs = async () => {
    setLoader(true)
    $('#gpsTable').DataTable().destroy();
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/kayitlar/tarih?startDate=${range.from}&endDate=${range.to}&deviceImei=${selectedDevice}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        setTimeout(() => {
          $('#gpsTable').DataTable();
        }, 50);
        setGpsLogs([]);
        setGpsLogs(res.data);
        setLoader(false)
      });
  };

  let footer = (
    <>
      <button className='btn btn-primary mt-5 mb-1 w-100' onClick={() => setRange({})}>
        Seçimi Sıfırla
      </button>
      <button
        className='btn w-100 btn-primary mt-5 mb-1'
        onClick={handleGetGpsLogs}
        disabled={!selectedDevice || !range.from || !range.to}
      >
        Tamamla
      </button>
    </>
  );

  const handleSelectDevice = (e) => {
    setSelectedDevice(e.target.value);
  };

  const modifiersStyles = {
    selected: {
      color: 'white',
      backgroundColor: '#0d6dfd',
    }
  };

  const classNames = {
    ...styles,
    head: 'custom-head',
    disabled: 'custom-disabled',
  };

  return (
    <div className='content'>
      <Container className='' style={{ alignItems: 'center', marginTop: '6%' }}>
        <div className='wrapper'>
          
            <h3 className="font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700">
            GPS - Log Kayıtları Sayfası
                        </h3>
          <Row>
            <Col sm='3' md='3' lg='3'>
              <Form>
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                <Form.Label>Cihaz Seçiniz</Form.Label>
                  <Form.Select onChange={handleSelectDevice}>
                    <option disabled={selectedDevice}>Cihaz Seç</option>
                    {devices.length > 0 &&
                      devices.map((device) => {
                        return (
                          <option value={device.deviceImei} key={device._id}>
                            {device.deviceName}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col lg='3'>
              <Backdrop
                sx={{
                  color: '#fff',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loader}
              >
                <CircularProgress color='inherit' />
              </Backdrop>

              <div className='wrapper d-flex justify-content-center'>
                <DayPicker
                locale={tr}
                classNames={classNames}
                  mode='range'
                  selected={range}
                  onSelect={setRange}
                  footer={footer}
                  modifiersStyles={modifiersStyles}
                  modifiers={range}
                  max={7}
                  toDate={new Date()}
                />
              </div>
            </Col>
            {gpsLogs.length > 0 &&
              <Col sm={6} md={6} lg={8} xl={8}>
                <h1
                  className='card-title font-bold mb-3'
                  style={{ marginTop: '70px', alignItems: 'center' }}
                >
                  Device GPS Logs
                </h1>
                <table
                  id='gpsTable'
                  className='table table-striped table-bordered table-hover dataTables-example w-100'
                >
                  <thead>
                    <tr>
                      <td>Araç Plakası</td>
                      <td>Tarih</td>
                      <td>Enlem</td>
                      <td>Boylam</td>
                      <td>Rakım</td>
                      <td>Hız</td>
                      <td>Açı</td>
                      <td>Uydular</td>
                    </tr>
                  </thead>
                  <tbody>
                    {gpsLogs.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{devices.find(element => {
                            return element.deviceImei === item.deviceImei
                          }).deviceName}</td>
                          <td>{moment(item.deviceDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>{item.latitude}</td>
                          <td>{item.longitude}</td>
                          <td>{item.altitude}</td>
                          <td>{item.gpsspeed}</td>
                          <td>{item.gpsangle}</td>
                          <td>{item.satellites}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Col>}
          </Row>
        </div>
      </Container>
    </div>
  );
}
