import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';
import { fetchUsers } from '../redux/actions/userActions';
import Button from 'react-bootstrap/esm/Button';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import moment from 'moment';
const XLSX = require('xlsx');

export default function Drivers() {
  const navigate = useNavigate();
  //   const dispatch = useAppDispatch(); //Define dispatch hook
  //   const drivers = useAppSelector((state) => state.driver.data); //Get all drivers from the state

  const [driver, setDriver] = useState({ showing: false, data: null });
  const [groups, setGroups] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [error, setError] = useState(false);

  const [driverName, setDriverName] = useState(null);
  const [file, setFile] = useState(null);

  let myArrExportExcell = [];

  const handleGetGroups = async () => {
    const companyName = localStorage.getItem('userCompany')
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumsuruculer?companyName=${companyName}&userRole=${localStorage.getItem('userRole')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
        setGroups(response.data);
        response.data.map((item) => {
          myArrExportExcell.push({
            'Sürücü İsmi': item.driverName,
            'Sürücü Grup': item.driverGroup[0],
          });
        });
      });
  };

  const handleShow = (driverId) => {
    let editDriver = groups.find((item) => item._id === driverId);
    setDriver({ showing: true, data: editDriver });
  };
  const handleClose = () => {
    setDriver({ showing: false, data: null });
    setError(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      e.target.elements.driverName.value.length < 3 ||
      e.target.elements.driverName.value.length > 50
    ) {
      setError(true);
      return;
    }

    let formData = {
      driverName: e.target.elements.driverName.value,
      driverCompany: localStorage.getItem('userCompany')
    };

    if (driver.data == null) {
      //insert işlemi

      await axios
        .post(`${process.env.REACT_APP_BASE_URL}/surucu/yeni`, formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then(function (response) {
          window.location.reload();
        })
        .catch((err) => {
          if (err.response.data.message === 'Sürücü zaten kayıtlı.') {
            window.location.reload();
          }
        });
    } else {
      //update işlemi
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/surucu/duzenle`,
          {
            ...formData,
            driverId: driver.data._id,
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        )
        .then(function (response) {
          window.location.reload();
        });
    }
  };

  //Create Excel File
  const handleExportExcel = async () => {
    await handleGetGroups();
    let filename = `${moment(new Date()).format(
      'DD-MM-YYYY HH:mm'
    )} Sürücüler.xlsx`;
    var ws = XLSX.utils.json_to_sheet(myArrExportExcell);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, filename);
  };

  //Import Excel File
  // const handleImportExcel = async () => {
  //     await handleGetGroups();
  //     let filename = 'DeviceData.xlsx';
  //     var ws = XLSX.utils.json_to_sheet(myArrImportExcell);
  //     var wb = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'Data');
  //     XLSX.writeFile(wb, filename);
  // };

  //Get and Update drivers array on every render
  useEffect(() => {
    try {
      handleGetGroups();
    } catch (err) {
      console.log(err);
      navigate('/giris');
    }
  }, []);

  const handleRemove = (driverId) => {
    // Are u sure delete it function
    Swal.fire({
      title: 'Emin misiniz?',
      text: 'Bu işlemi geri alamazsınız!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Vazgeç',
      confirmButtonText: 'Evet, sil!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/surucu/sil`,
            {
              driverId: driverId,
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            Swal.fire(
              'Silindi!',
              'Silme işleminiz gerçekleştirildi.',
              'success'
            ).then(() => {
              window.location.reload();
            });
          });
      }
    });
  };

  const handleSelectedGroups = (e) => {
    if (e.target.checked) {
      setUserGroups((prev) => [...prev, e.target.name]);
    } else {
      userGroups.splice(userGroups.indexOf(e.target.name), 1);
    }
  };

  const handleClick = async () => {
    try {
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/tumsuruculer?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`,
          {
            // _id: _id,
            driverName: driverName,
            // userPassword: userPassword,
            // userRole: userRole,
            // userAuthorizedPersonel: userAuthorizedPersonel,
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then(() => navigate('/giris'));
    } catch (err) {
      console.log(err);
      navigate('/kayitol');
    }
  };

  const handleClickAlert = () => {
    //   alert('adadasd')
    // console.log('adasda');
    return (
      // <Stack sx={{ width: '100%' }} spacing={2}>

      //     <Alert variant="filled" severity="info">
      //         Bu bir demo sürümüdür — aktif değil!
      //     </Alert>

      // </Stack>
      alert('Demo Sürümünde Aktif Değil!')
    );
  };

  //console.log(drivers)
  function handleFileChange(e) {
    // Dosyayı al
    const fileObj = e.target.files[0];
    // Dosya tipini kontrol et (.xls veya .xlsx)
    if (
      fileObj.type !== 'application/vnd.ms-excel' &&
      fileObj.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      alert('Sadece Excel dosyalarını yükleyebilirsiniz!');
      return false;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      // XLSX kullanarak dosya içeriğini oku
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheet_name_list = workbook.SheetNames;
      const xlData = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheet_name_list[sheet_name_list.length - 1]],
        { header: 1 }
      );
      //   const getDriver = (dname) => drivers.find((driver) => driver.name.trim() == dname.trim())?.id;
      //   const getVehicle = (vname) => vehicles.find((vehicle) => vehicle.name.trim() == vname.trim())?.id;
      const getShift = (date) => {
        // if (moment(date, 'HH:mm:ss').format('HH:mm:ss') > moment("07:00:00", 'HH:mm,ss').format('HH:mm:ss') && moment(date, 'HH:mm:ss').format('HH:mm:ss') < moment("19:00:00", 'HH:mm:ss').format('HH:mm:ss')) {
        //   return shiftList.find((shift) => shift.shiftName == "Sabah")?._id;
        // } else if (moment(date, 'HH:mm:ss').format('HH:mm:ss') > moment("19:00:00", 'HH:mm:ss').format('HH:mm:ss')) {
        //   return shiftList.find((shift) => shift.shiftName == "Akşam")?._id;
        // }
      };
      xlData.forEach((item, index) => {
        if (index < 3) return;
        if (item.length == 19) {
          //   let readData = {
          //     driverId: getDriver(item[0]) ?? item[0],
          //     vehicleId: getVehicle(item[1]) ?? item[1],
          //     vehicleType: item[2].trim(),
          //     date: moment(sheet_name_list[sheet_name_list.length - 1].trim(), 'DD.MM.YYYY').format('YYYY-MM-DD'),
          //     shiftStartDate: moment(item[3], 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
          //     shiftEndDate: moment(item[4], 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
          //     shift: "Sabah",
          //     gateId: item[1],
          //     shiftType: item[3] ? getShift(item[3].trim()) : "",
          //     isTrue: (getDriver(item[0]) && getVehicle(item[1])) ? true : false,
          //   }
          //   setImportData((prev) => [...prev, readData])
          //    let readData2 = {
          //     driverId: getDriver(item[10]) ?? item[10],
          //     vehicleId: getVehicle(item[11]) ?? item[11],
          //     vehicleType: item[12].trim(),
          //     date: moment(sheet_name_list[sheet_name_list.length - 1].trim(), 'DD.MM.YYYY').format('YYYY-MM-DD'),
          //     shiftStartDate: moment(item[13], 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
          //     shiftEndDate: moment(item[14], 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
          //     shift: item[3] ? getShift(item[3].trim()) : "",
          //     shift: "Akşam",
          //     gateId: item[11],
          //     shiftType: item[3] ? getShift(item[3].trim()) : "",
          //     isTrue: (getDriver(item[10]) && getVehicle(item[11])) ? true : false,
          //   }
          //   setImportData((prev) => [...prev, readData2])
          // } else if (item.length == 9) {
          //   let readData = {
          //     driverId: getDriver(item[0]) ?? item[0],
          //     vehicleId: getVehicle(item[1]) ?? item[1],
          //     vehicleType: item[2].trim(),
          //     date: moment(sheet_name_list[sheet_name_list.length - 1].trim(), 'DD.MM.YYYY').format('YYYY-MM-DD'),
          //     shiftStartDate: moment(item[3], 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
          //     shiftEndDate: moment(item[4], 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
          //     shift: "Sabah",
          //     gateId: item[1],
          //     shiftType: item[3] ? getShift(item[3].trim()) : "",
          //     isTrue: (getDriver(item[0]) && getVehicle(item[1])) ? true : false,
          //   }
          //   setImportData((prev) => [...prev, readData])
        }
      });
    };
    reader.readAsBinaryString(fileObj);
    // Dosyayı state'e kaydet
    setFile(fileObj);
  }

  return (
    <>
      {/*  User Modal */}
      <Modal show={driver.showing} onHide={handleClose}>
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
            {/* Kullanıcı Düzenle */}
            {driver.data == null
              ? 'Yeni Sürücü Ekle'
              : `${driver.data.driverName} - Düzenle`}
          </h3>

          <Form onSubmit={handleFormSubmit}>
            <Form.Group
              className='mb-3'
              controlId='exampleForm.ControlTextarea1'
            >
              <Form.Label className='formLabel'>Sürücü Adı</Form.Label>
              <Form.Control
                name='driverName'
                type='Text'
                placeholder='Sürücü Adı Giriniz'
                defaultValue={driver.data?.driverName ?? ''}
              />
              <label
                htmlFor='warning'
                className={`text-danger font-bold text-[0.85em] ml-1 ${
                  error ? '' : 'hidden'
                }`}
              >
                Sürücü Adı minimum 3 karakter, Maximum 50 karakter olmalıdır!
              </label>
            </Form.Group>

            <div className='d-flex justify-content-between'>
              <Button
                className='mt-5'
                variant='outline-secondary'
                onClick={handleClose}
              >
                Vazgeç
              </Button>
              <Button className='mt-5' variant='outline-primary' type='submit'>
                {/* Değişiklikleri Kaydet */}
                {driver.data == null ? 'Ekle' : 'Kaydet'}
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/*  User Modal */}

      <div className='content mt-[3.75rem]'>
        <Container fluid='md' className='px-[8rem]'>
          <div className='wrapper'>
            <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
              Sürücüler
            </h3>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Button
                // onClick={handleExportExcel}
                onClick={handleExportExcel}
                variant='outline-success'
                className='text-[0.75rem] mb-4'
              >
                {/* Export Excel */}
                Excel Dosyası Oluştur <FileDownloadIcon />
              </Button>

              <Button
                onClick={() => setDriver({ showing: true, data: null })}
                // onClick={handleClickAlert}
                color='primary'
                variant='outline-primary'
                className='text-[0.75rem] mb-4'
              >
                Yeni Sürücü Ekle <PersonAddIcon className='ml-2' />
              </Button>
            </div>

            {groups && groups.length > 0 ? (
              <Table bordered hover className='' size='sm'>
                <thead>
                  <tr>
                    {/* <th style={{ textAlign: "center" }}>Id</th> */}
                    <th style={{ textAlign: '' }}>SÜRÜCÜ ADI</th>
                    {/* <th style={{ textAlign: "center" }}>Kapı Kodu</th>
                    <th style={{ textAlign: "center" }}>Kullanım Amacı</th> */}
                    {/* <th style={{ textAlign: "center" }}>İşlemler</th>
                    <th style={{ textAlign: "center" }}>İşlemler</th> */}
                    <th style={{ textAlign: 'center' }}>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {groups.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td style={{ textAlign: '' }}>{item.driverName}</td>
                        {/* <td style={{ textAlign: "center" }}>{item.userRole}</td>
                        <td style={{ textAlign: "center" }}>
                          
                        </td> */}
                        {/* <td></td> */}
                        <td style={{ textAlign: 'center' }}>
                          <OverlayTrigger
                            key={'top2'}
                            placement={'top'}
                            overlay={
                              <Tooltip
                                style={{ fontSize: '12px' }}
                                id={`tooltip-${'top'}`}
                              >
                                Düzenle
                              </Tooltip>
                            }
                          >
                            <Button
                              // onClick={() => handleClickAlert(item._id)}
                              onClick={() => handleShow(item._id)}
                              className='p-0'
                              variant='outline-secondary'
                              size='sm'
                              style={{
                                borderStyle: 'none',
                                borderRadius: '5px',
                              }}
                            >
                              <EditIcon className='p-1' />
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip
                                style={{ fontSize: '12px' }}
                                id={`tooltip-${'top'}`}
                              >
                                Sil
                              </Tooltip>
                            }
                          >
                            <Button
                              // onClick={() => handleClickAlert(item._id)}
                              className='p-0'
                              variant='outline-danger'
                              size='sm'
                              style={{
                                borderStyle: 'none',
                                borderRadius: '5px',
                              }}
                              onClick={() => handleRemove(item._id)}
                            >
                              <DeleteIcon className='p-1' />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <p>Yükleniyor...</p>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}
