import { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  Polyline,
  Marker,
  Popup,
} from 'react-leaflet';
import L, { marker } from 'leaflet';
import React from 'react';
import moment from 'moment';

const MyMap = (props) => {
  const position = [props.data[props.data.length-1]?.latitude ?? 0, props.data[props.data.length-1]?.longitude ?? 0];
  const latlong = props.data.map((item) => [item.latitude, item.longitude]);
  const [map, setMap] = useState(null);
  const [pos, setPos] = useState([[0, 0]]);
  const polylineRef = React.useRef();

  useEffect(() => {
    setPos(latlong.filter((item, index) => index < props.counter));
  }, [props.counter]);

  const setIcon = (car, classArr) => {
    const classes = Object.values(classArr).join(' ');
    const svgCode = `
        <div className="carIcon">
          <span style="position:absolute;font-weight:bold;font-size:0.73rem;display:block;width:max-content;color:white;top:-0.85rem">${parseInt(
            car?.vehiclespeed ?? 0
          )} km/s</span>
            <div className="car" style="${
              (classArr.rotate > 90 || classArr.rotate) < -90
                ? 'transform:scaleX(-1)'
                : ''
            }">
              <svg viewBox="-11.75 -10.014 126 108.985" xmlns="http://www.w3.org/2000/svg" xmlns:bx="https://boxy-svg.com" class="${classes}">
                <path d="M5,36.12h14.635c-1.315,1.744-2.096,3.88-2.096,6.236H7.865L5,40.365V36.12z"/>
                <path d="M27.962,34.727c-4.226,0-7.656,3.425-7.656,7.63c0,4.232,3.431,7.644,7.656,7.644c4.212,0,7.63-3.411,7.63-7.644  C35.592,38.151,32.174,34.727,27.962,34.727z M27.962,46.523c-2.312,0-4.187-1.861-4.187-4.167c0-2.292,1.875-4.153,4.187-4.153  c2.292,0,4.167,1.861,4.167,4.153C32.129,44.662,30.254,46.523,27.962,46.523z"/>
                <path d="M81.26,34.726c-4.229,0-7.66,3.43-7.66,7.63c0,4.23,3.431,7.64,7.66,7.64c4.21,0,7.641-3.41,7.641-7.64  C88.9,38.156,85.47,34.726,81.26,34.726z M81.26,46.526c-2.3,0-4.18-1.86-4.18-4.17c0-2.29,1.88-4.15,4.18-4.15  c2.29,0,4.16,1.86,4.16,4.15C85.42,44.666,83.55,46.526,81.26,46.526z"/>
                <path d="M99,20.841L93.461,4.18L84.73,0H67.31v36.12H36.27c1.31,1.75,2.1,3.88,2.1,6.25h32.46c0-5.75,4.67-10.4,10.43-10.4  c4.04,0,7.53,2.31,9.26,5.69c0.62,1.19,1.021,2.54,1.101,3.98c0.01,0.23,0.05,0.47,0.05,0.73H100V25L99,20.841z M72.22,20.841v-12.5  h18.461l4.16,12.5H72.22z"/>
                <polygon fill="none" points="72.22,20.841 72.22,8.341 90.681,8.341 94.841,20.841 "/>
                <rect x="1.5" y="0" width="63" height="33.652"/>
                <path d="M 52.311 -67.415 L 72.777 -50.307 L 31.844 -50.307 L 52.311 -67.415 Z" style="fill: rgb(216, 216, 216); stroke: rgb(0, 0, 0);" transform="matrix(1, 0, 0, -1, 0, 0)" bx:shape="triangle 31.844 -67.415 40.933 17.108 0.5 0 1@754d418d"/>
              </svg>
            </div>
          </div>
        `;

    const customIcon = L.divIcon({
      html: svgCode,
      className: 'transition duration-500 ease-all',
      popupAnchor: [-5, -25],
      iconAnchor: [28, 32],
      iconSize: [55, 55],
    });
    return customIcon;
  };

  const setBuble = (alarm) => {
    const svgCode = `
        <div style="relative before:absolute before:w-2 before:h-10">
            <span style="color:red; font-size:11px;font-weight:bold;display:block;width:35px;line-height:1">${
              alarm == 1
                ? 'Ani Hızlanma'
                : alarm == 2
                ? 'Ani Fren'
                : alarm == 3
                ? 'Sert Dönüş'
                : '?'
            }</span>
        </div>
        `;

    // Özelleştirilmiş SVG öğesini oluşturun
    const customIcon = L.divIcon({
      html: svgCode,
      className: 'transition duration-500 ease-all',
      popupAnchor: [0, -15],
      iconSize: [32, 32],
    });
    return customIcon;
  };

  function setCarColor(car) {
    let classes = {
      animate: '',
      color: 'fill-white',
      rotate: '0',
    };

    if (car?.vehiclespeed > 2) classes.animate = 'bounce';
    else classes.animate = '';

    if (car?.ignition == 1)
      if (car?.vehiclespeed > 2 && car?.vehiclespeed < 25)
        classes.color = 'fill-lime-400';
      else if (car?.vehiclespeed > 25) classes.color = 'fill-red-400';
      else classes.color = 'fill-yellow-400';
    else classes.color = 'fill-gray-200';

    return classes;
  }

  const handleMarkerClick = (e, car) => {
    // console.log(car);
  };

  const onMapLoad = (event) => {
    setMap(event.target);
  };

  const vehicleInfo = (info) => {
    const ignition = props.data[props.counter]?.ignition;
    switch (info) {
        case 'vehiclePlate':
            return props.vehiclePlate
      case 'speed':
        return ignition == 1
          ? parseInt(props.data[props.counter]?.vehiclespeed) + ' Km/s'
          : '0 Km/s';
      case 'hour':
        return moment(props.data[props.counter]?.deviceDate).format('HH:mm:ss');
        break;
      case 'date':
        return moment(props.data[props.counter]?.deviceDate).format(
          'YYYY-MM-DD'
        );
        break;
      case 'enginecycle':
        return ignition == 1
          ? props.data[props.counter]?.enginecycle + ' Rpm'
          : '0 Rpm';
        break;
      case 'ignition':
        return props.data[props.counter]?.ignition == 1 ? 'Açık' : 'Kapalı';
        break;
      case 'gpsstatus':
        return props.data[props.counter]?.gpsstatus == 1 ? 'Açık' : 'Kapalı';
        break;
      case 'fuellevel':
        return props.data[props.counter]?.fuellevel + ' %';
        break;
      case 'totalkm':
        return (
          parseFloat(props.data[props.counter]?.totalkm).toFixed(2) + ' Km'
        );
        break;
      case 'gaspedalposition':
        return props.data[props.counter]?.gaspedalposition;
        break;

      default:
        break;
    }
  };

  const limeOptions = { color: '#7ed11f', weight: 4 };

  return (
    <MapContainer
      center={position}
      className='w-full h-full cursor-crosshair'
      zoom={14}
      whenReady={onMapLoad}
    >
      <TileLayer url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}' />
      <Polyline pathOptions={limeOptions} positions={pos} ref={polylineRef} />
      <Marker
        icon={setIcon(
          props.data[props.counter],
          setCarColor(props.data[props.counter])
        )}
        position={pos[pos.length - 1]}
        animate={true}
        eventHandlers={{
          click: (e) => handleMarkerClick(e, props.data[props.counter]),
        }}
      >
        <Popup>
          <div className='flex flex-col'>
          <div className='flex flex-row'>
              <div className='font-bold'>Araç Plaka:</div>
              <div className='ml-2'>{vehicleInfo('vehiclePlate')}</div>
            </div>
            <div className='flex flex-row'>
              <div className='font-bold'>Tarih:</div>
              <div className='ml-2'>{vehicleInfo('date')}</div>
            </div>
            <div className='flex flex-row'>
              <div className='font-bold'>Saat:</div>
              <div className='ml-2'>{vehicleInfo('hour')}</div>
            </div>
            <hr className='my-2' />
            <div className='flex flex-row'>
              <div className='font-bold'>Kontak:</div>
              <div className='ml-2'>{vehicleInfo('ignition')}</div>
            </div>
            <div className='flex flex-row'>
              <div className='font-bold'>Total KM:</div>
              <div className='ml-2'>{vehicleInfo('totalkm')}</div>
            </div>
            <hr className='my-2' />

            <div className='flex flex-row'>
              <div className='font-bold'>Hız:</div>
              <div className='ml-2'>{vehicleInfo('speed')}</div>
            </div>

            <div className='flex flex-row'>
              <div className='font-bold'>Gaz pedal:</div>
              <div className='ml-2'>{vehicleInfo('gaspedalposition')}</div>
            </div>
            <div className='flex flex-row'>
              <div className='font-bold'>Devir:</div>
              <div className='ml-2'>{vehicleInfo('enginecycle')}</div>
            </div>
            <div className='flex flex-row'>
              <div className='font-bold'>Yakıt:</div>
              <div className='ml-2'>{vehicleInfo('fuellevel')}</div>
            </div>
          </div>
        </Popup>
      </Marker>
      {/* {pos.map((item, index) => {
        if (props.data[index].alarms)
          return (
            <Marker
              icon={setBuble(props.data[index].alarms)}
              position={item}
              key={index}
            />
          );
      })} */}
    </MapContainer>
  );
};

export default MyMap;
