import { createSlice } from '@reduxjs/toolkit';

const initialDeviceState = {
    deviceName: null,
    deviceImei: null,
    deviceType: null,
    isActive: null,
    deviceGroupId: null,
};

const deviceSlice = createSlice({
  name: 'device',
  initialState: initialDeviceState,
  reducers: {
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
  },
});

export default deviceSlice;
