import React, { useEffect, useRef, useState } from 'react';
import { Container } from 'react-bootstrap';
import 'react-day-picker/dist/style.css';
import { subDays } from 'date-fns';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Col, Row } from 'react-bootstrap';
import BarChart from '../Charts/BarChart';
import './index.css';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { DayPicker } from 'react-day-picker';
import ClusteredChart from '../Charts/ClusteredChart';
import { tr } from 'date-fns/locale';
import styles from 'react-day-picker/dist/style.module.css';
const XLSX = require('xlsx');

const AllTimeReports = () => {
  const chartReports = [];
  const clusteredChartReports = [];
  const [reports, setReports] = useState([]);
  const [vehicles, setVehicles] = useState();
  const [chartReportsState, setChartReports] = useState();
  const [clusteredChartReportsState, setClusteredChartReportsState] =
    useState();
  const [loader, setLoader] = useState(false);
  const [buttonClickCount, setButtonClickCount] = useState(0);

  const pastMonth = new Date();
  const defaultSelected = {
    from: subDays(pastMonth, 4),
    to: pastMonth,
  };
  const [range, setRange] = useState(defaultSelected);

  const date1 = new Date(range?.from);
  const date2 = new Date(range?.to);

  let diffMinutes;
  const timeDiff = Math.abs(date2.getTime() - date1.getTime());
  if (
    typeof range?.to !== 'undefined' &&
    typeof range?.from !== 'undefined' &&
    range.to !== range.from
  ) {
    diffMinutes = Math.floor(timeDiff / (1000 * 3600 * 24));
    if (isNaN(diffMinutes)) {
      diffMinutes = 1;
    }    
    else diffMinutes = diffMinutes + 1;
  } else if (range?.to === range?.from || typeof range?.to === 'undefined') {
    diffMinutes = 1;
  }

  let excelArray = [];
  async function handleGetLogs() {
    setButtonClickCount(1);
    if (typeof range.from === 'undefined') return;
    setLoader(true);
    setChartReports(null);
    setClusteredChartReportsState(null);
    await axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/arac/raporlar/all`,
        {
          startDate:
            moment(range.from).format('YYYY-MM-DD 00:00:00') ??
            moment(new Date()).format('YYYY-MM-DD 00:00:00'),
          endDate:
            (range.to && moment(range.to).format('YYYY-MM-DD 23:59:59')) ??
            moment(range.from).format('YYYY-MM-DD 23:59:59'),
        },
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then((res) => {
        setLoader(false);
        let reportsArray = [];
        vehicles.forEach((vehicle) =>
          reportsArray.push({
            vehicleImei: vehicle.vehicleImei,
            vehicleplate: vehicle.vehiclePlate,
          })
        );
        console.log("vehicles=>",vehicles);
        console.log('reportsArray=>', reportsArray);
        res.data.forEach((item, index) => {
          reportsArray.forEach((el, idx) => {
            if (item.vehicleImei === el.vehicleImei) {
              if (!el.date) {
                el.date = item.date;
              }
              if (el.enginehourdiff) {
                el.enginehourdiff += item.enginehourdiff;
              } else {
                el.enginehourdiff = 0;
                el.enginehourdiff += item.enginehourdiff;
              }
              if (el.hardbreakcount) {
                el.hardbreakcount += item.hardbreakcount;
              } else {
                el.hardbreakcount = 0;
                el.hardbreakcount += item.hardbreakcount;
              }
              if (el.hardturncount) {
                el.hardturncount += item.hardturncount;
              } else {
                el.hardturncount = 0;
                el.hardturncount += item.hardturncount;
              }
              if (el.speedlimitviolationcount) {
                el.speedlimitviolationcount += item.speedlimitviolationcount;
              } else {
                el.speedlimitviolationcount = 0;
                el.speedlimitviolationcount += item.speedlimitviolationcount;
              }
              if (el.suddenaccelerationcount) {
                el.suddenaccelerationcount += item.suddenaccelerationcount;
              } else {
                el.suddenaccelerationcount = 0;
                el.suddenaccelerationcount += item.suddenaccelerationcount;
              }
              if (el.totalactiveworkingtime) {
                el.totalactiveworkingtime += item.totalactiveworkingtime;
              } else {
                el.totalactiveworkingtime = 0;
                el.totalactiveworkingtime += item.totalactiveworkingtime;
              }
              if (el.totalkm) {
                el.totalkm += item.totalkm;
              } else {
                el.totalkm = 0;
                el.totalkm += item.totalkm;
              }
              if (el.totalworkingtime) {
                el.totalworkingtime += item.totalworkingtime;
              } else {
                el.totalworkingtime = 0;
                el.totalworkingtime += item.totalworkingtime;
              }
            }
          });
        });
        

        reportsArray.forEach((item) => {
          chartReports.push({
            vehicleplate: item.vehicleplate,
            date: moment(item.date).format('YYYY-MM-DD HH:mm:ss'),
            hardbreakcount: parseInt(item.hardbreakcount),
            hardturncount: parseInt(item.hardturncount),
            speedlimitviolationcount: parseInt(item.speedlimitviolationcount),
            suddenaccelerationcount: parseInt(item.suddenaccelerationcount),
          });
          clusteredChartReports.push({
            vehicleplate: item.vehicleplate,
            date: moment(item.date).format('YYYY-MM-DD HH:mm:ss'),
            'Aktif Çalışma Süresi': parseInt(item.totalactiveworkingtime),
            'Çalışma Süresi': parseInt(item.totalworkingtime),
            'Rölantide Çalışma Süresi':
              item.totalworkingtime - item.totalactiveworkingtime,
          });
          excelArray.push({
            'Araç Plakası': item.vehicleplate,
            'Toplam Yol': parseFloat(item.totalkm).toFixed(2) + 'KM',
            'Toplam Çalışma Süresi':
              Math.floor(item.totalworkingtime / 60) +
              ' Saat ' +
              (parseInt(item.totalworkingtime) % 60) +
              ' Dakika',
            'Toplam Aktif Çalışma Süresi':
              Math.floor(item.totalactiveworkingtime / 60) +
              ' Saat ' +
              (parseInt(item.totalactiveworkingtime) % 60) +
              ' Dakika',
            'Toplam Rölantide Çalışma Süresi':
              Math.floor(
                (item.totalworkingtime - item.totalactiveworkingtime) / 60
              ) +
              ' Saat ' +
              (parseInt(item.totalworkingtime - item.totalactiveworkingtime) %
                60) +
              ' Dakika',
            'Motor Saati Farkı': `${parseInt(item.enginehourdiff)} saat ${
              String(item.enginehourdiff % 1).split('.')[1] !== undefined
                ? Math.floor(
                    (parseFloat(item.enginehourdiff % 1)
                      .toFixed(2)
                      .split('.')[1] *
                      6) /
                      10
                  )
                : 0
            } dakika`,
            'Ani Fren Sayısı': item.hardbreakcount,
            'Sert Dönüş Sayısı': item.hardturncount,
            'Hız Limiti İhlali': item.speedlimitviolationcount,
            'Ani Hızlanma Sayısı': item.suddenaccelerationcount,
          });
        });

        setReports(reportsArray);

        //////////////////////////
        let totalCount = 0;
        chartReports.map((item) => {
          totalCount +=
            item.hardbreakcount +
            item.hardturncount +
            item.speedlimitviolationcount +
            item.suddenaccelerationcount;
        });
        totalCount === 0 ? setChartReports(null) : setChartReports(chartReports);
        clusteredChartReports.length === 0
          ? setClusteredChartReportsState(null)
          : setClusteredChartReportsState(clusteredChartReports);

        // let totalCountClustred = 0;
        // clusteredChartReports.map((item) => {
        //   totalCountClustred += item.totalkm;
        // });
        // totalCountClustred == 0
        //   ? setClusteredChartReportsState(null)
        //   : setClusteredChartReportsState(clusteredChartReports);
        // setChartReports(chartReports);
        // setClusteredChartReportsState(clusteredChartReports);
      });
  }

  //Create Excel File
  const handleExportExcel = async () => {
    let filename = `${moment(new Date()).format(
      'DD-MM-YYYY HH:mm'
    )} TümAraçRaporları.xlsx`;
    await handleGetLogs();
    var ws = XLSX.utils.json_to_sheet(excelArray);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reports Data');
    XLSX.writeFile(wb, filename);
  };

  const getAllVehicles = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumaraclar?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((res) => {
        setVehicles(res.data);
      });
  };
  useEffect(() => {
    getAllVehicles();
  }, []);

  const columns = [
    // ############ BUGGY ############
    // {
    //   name: 'Yakıt Tüketimi Değişimi',
    //   selector: (row) => row.fuelConsumption,
    // },
    // {
    //   name: 'Tur Sayısı',
    //   cell: (row) =>
    //     row.posStack.poly1.dateValues[row.reportDate.split(' ')[0]] ? (
    //       row.shiftType == 'Sabah' ? (
    //         <p>
    //           {row.posStack.poly1.dateValues[row.reportDate.split(' ')[0]]?.v1 > row.posStack.poly2.dateValues[row.reportDate.split(' ')[0]]?.v1 ? row.posStack.poly2.dateValues[row.reportDate.split(' ')[0]]?.v1 : row.posStack.poly1.dateValues[row.reportDate.split(' ')[0]]?.v1}
    //         </p>
    //       ) : (
    //         <p>
    //           {row.posStack.poly1.dateValues[row.reportDate.split(' ')[0]]?.v2 > row.posStack.poly2.dateValues[row.reportDate.split(' ')[0]]?.v2 ? row.posStack.poly2.dateValues[row.reportDate.split(' ')[0]]?.v2 : row.posStack.poly1.dateValues[row.reportDate.split(' ')[0]]?.v2}
    //         </p>
    //       )
    //     ) : (
    //       0
    //     ),
    //   sortable: true,
    // },
    // ############ BUGGY ############
    {
      name: 'Araç Plakası',
      selector: (row) => row.vehicleplate,
    },
    {
      name: 'Toplam Yol (KM)',
      selector: (row) => row.totalkm,
      sortable: true,
    },
    {
      name: 'Toplam Çalışma',
      selector: (row) => addSifir(row.totalworkingtime),
      sortable: true,
    },
    {
      name: 'Toplam Aktif Çalışma',
      selector: (row) => addSifir(row.totalactiveworkingtime),
      sortable: true,
    },
    {
      name: 'Toplam Rölantide Çalışma',
      selector: (row) => addSifir(row.totalengineworkingtime),
      sortable: true,
    },
    {
      name: 'Ani Fren',
      selector: (row) => row.hardbreakcount,
      sortable: true,
    },
    {
      name: 'Sert Dönüş',
      selector: (row) => row.hardturncount,
      sortable: true,
    },
    {
      name: 'Hız Sınırı Aşımı',
      selector: (row) => row.speedlimitviolationcount,
      sortable: true,
    },
    {
      name: 'Ani Hızlanma',
      selector: (row) => row.suddenaccelerationcount,
      sortable: true,
    },
    {
      name: 'Motor Saat farkı (H)',
      selector: (row) => addSifir(row.enginespeeddifference),
      sortable: true,
    },
  ];
  const addSifir = (b) => {
    let a = b.split(':');
    if (a[0] < 10) a[0] = '0' + a[0];
    if (a[1] < 10) a[1] = '0' + a[1];
    return a[0] + ':' + a[1];
  };
  let data;
  if (reports.length > 0) {
    data = reports.map((item, index) => {
      return {
        vehicleplate: item.vehicleplate,
        reportDate: moment(item.date).format('YYYY-MM-DD'),
        totalkm: parseFloat(item.totalkm).toFixed(2),
        totalworkingtime:
          Math.floor(item.totalworkingtime / 60) +
          ':' +
          (parseInt(item.totalworkingtime) % 60),
        totalactiveworkingtime:
          Math.floor(item.totalactiveworkingtime / 60) +
          ':' +
          (parseInt(item.totalactiveworkingtime) % 60),
        totalengineworkingtime:
          Math.floor(
            (item.totalworkingtime - item.totalactiveworkingtime) / 60
          ) +
          ':' +
          (parseInt(item.totalworkingtime - item.totalactiveworkingtime) % 60),
        hardbreakcount: item.hardbreakcount,
        hardturncount: item.hardturncount,
        speedlimitviolationcount: item.speedlimitviolationcount,
        suddenaccelerationcount: item.suddenaccelerationcount,
        enginespeeddifference: `${parseInt(item.enginehourdiff)}:${
          String(item.enginehourdiff % 1).split('.')[1] !== undefined
            ? Math.floor(
                (parseFloat(item.enginehourdiff % 1)
                  .toFixed(2)
                  .split('.')[1] *
                  6) /
                  10
              )
            : 0
        }`,
        posStack: item.posStack,
      };
    });
  }

  let customStyles = {
    rows: {
      style: {
        minHeight: '42px', // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '8px',
      },
    },
    cells: {
      style: {
        fontSize: '13px',
        color: '#000',
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
  };
  const buttonRef = useRef();

  let footer = (
    <>
      <button
        className='btn btn-primary mt-5 mb-1 w-100'
        onClick={() => setRange({})}
      >
        Seçimi Sıfırla
      </button>
      <button
        ref={buttonRef}
        className='btn w-100 btn-primary mt-5 mb-1'
        onClick={handleGetLogs}
        // disabled={!isDeviceSelected}
      >
        Getir
      </button>
      <button
        ref={buttonRef}
        className='btn w-100 btn-success mt-5 mb-1'
        onClick={handleExportExcel}
        // disabled={!isDeviceSelected}
      >
        Excel Dosyası Oluştur
      </button>
    </>
  );

  const modifiersStyles = {
    selected: {
      color: 'white',
      backgroundColor: '#0d6dfd',
    },
  };

  const handleSetChange = (e) => {
    // if(e.to == undefined) {
    //   // e.to = e.from
    // }
    setRange(e);
  };

  const classNames = {
    ...styles,
    head: 'custom-head',
    disabled: 'custom-disabled',
  };

  return (
    <>
      <div className='content mt-[3.75rem]'>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loader}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        <Container>
          <div className='wrapper'>
            {reports.length > 0 && (
              <Col className='d-flex justify-content-center fw-bold fs-2'>
                <h3>{reports[0].vehicleplate}</h3>
              </Col>
            )}
            <Row>
              <Col xs={6} sm={12} md={6} lg={4} xl={3}>
                <Backdrop
                  sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={loader}
                >
                  <CircularProgress color='inherit' />
                </Backdrop>
                <div className='d-flex justify-content-center'>
                  <DayPicker
                    locale={tr}
                    classNames={classNames}
                    className='md:mx-auto'
                    mode='range'
                    selected={range}
                    onSelect={handleSetChange}
                    modifiersStyles={modifiersStyles}
                    modifiers={range}
                    footer={footer}
                    // max={7}
                    toDate={new Date()}
                  />
                </div>
              </Col>
              <Col>
                {/* {chartReportsState && (
                  <BarChart
                    fieldValue='vehicleplate'
                    data={chartReportsState}
                  />
                )} */}
                {clusteredChartReportsState ? (
                  <ClusteredChart
                    fieldValue='vehicleplate'
                    data={clusteredChartReportsState}
                  />
                ) : (
                  <div className='h-full flex justify-center items-center bg-gradient-to-b from-slate-100 to-blue-100'>
                    <div className='text-center'>
                      <h1 className='text-2xl font-bold'>
                        {buttonClickCount === 0 ? (
                          <p>
                            Raporları üretmek için "Tarih" seçimi yaptıktan
                            sonra "Getir" butonuna tıklayınız.
                          </p>
                        ) : (
                          <p>Seçili tarihler arasında veri bulunamadı.</p>
                        )}
                      </h1>
                      {/* <p className="text-slate-500"> <span className='animate-pulse cursor-pointer select-none text-sky-700'>Buraya</span> tıklayarak ya da sol üst köşeden farklı bir tarih seçebilirsiniz..</p> */}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              {chartReportsState && excelArray && (
                <BarChart fieldValue='date' data={chartReportsState} />
              )}
            </Row>
            <Row>
              {reports.length > 0 && (
                <Row lg='9'>
                  <h1 className='font-cinzel text-center my-[2rem] text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700 card-title'>
                    Raporlar
                  </h1>
                  <DataTable
                    columns={columns}
                    data={data}
                    pagination
                    responsive
                    customStyles={customStyles}
                  />
                </Row>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AllTimeReports;
