import React, { useEffect, useRef, useState } from 'react';
import DynamicMap from './Dashboard/Map';
import CarDetail from './Dashboard/CarDetail';
import axios from 'axios';
import mqtt from 'mqtt/dist/mqtt';
import './Dashboard/style.css';
import SettingsIcon from '@mui/icons-material/Settings';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';

const options = {
  host: 'ws://213.238.190.87',
  port: 8083,
  username: 'test2',
  password: '1234',
  clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
};

const NextDashboard = () => {
  const [cars, setCars] = useState();
  const [focusVehicle, setFocusVehicle] = useState(null);

  const mapRef = useRef(null);
  const markerRef = useRef({});
  const [realTimeData, setRealTimeData] = useState([]);
  const imeiList = [];

  const [panel, setPanel] = useState(false);
  const [mapUrl, setMapUrl] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const getVehicleData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/tumaraclar?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        );
        const vehicleData = response.data;
        setCars(vehicleData);
        if (vehicleData.length > 0) {
          vehicleData.forEach((el) => imeiList.push(el.vehicleImei));
          axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/sonveri/imei`,
              { imei: JSON.stringify(imeiList) },
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
              }
            )
            .then((response) => {
              setRealTimeData(response.data);
            });
        }
      } catch (error) {
        console.error(error);
      }
    };
    getVehicleData();

    localStorage.getItem('mapUrl')
      ? setMapUrl(localStorage.getItem('mapUrl'))
      : setMapUrl(
          'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
        );
  }, []);

  function handleMapChange(event) {
    setMapUrl(event.target.value);
    localStorage.setItem('mapUrl', event.target.value);
    setPanel(false);
  }

  useEffect(() => {
    const client = mqtt.connect(options.host, options);
    if (!client.connected) {
      client.on('connect', () => {
        imeiList.map((imei) => {
          client.subscribe(`igaMqtt/${imei}`, (err) => {
            if (err) {
              console.log(err);
            } else console.log('subscribed : ' + imei);
          });
        });
      });
    }

    client.on('message', (topic, payload) => {
      let data = JSON.parse(payload.toString());
      if (data?.deviceImei) {
        setRealTimeData((prevData) => {
          let index = prevData.findIndex(
            (device) => device.deviceImei.trim() == data.deviceImei.trim()
          );
          if (index != -1) {
            const updatedData = [...prevData];
            updatedData[index] = { ...prevData[index], ...data };
            return updatedData;
          } else {
            return [...prevData, data];
          }
        });
      }
    });
    return () => {
      client.end();
    };
  }, [options.host]);

  /*   https://leaflet-extras.github.io/leaflet-providers/preview/ */
  return (
    <>
      <div className='content relative'>
        <div className='absolute top-14 left-2 text-white z-[500] boxColor rounded-lg shadow-lg cursor-pointer p-0'>
          <div
            className={`iconhover hover:animate-spin p-3 m-0 w-[50px] flex justify-center items-center ${
              panel ? 'animate-spin' : ''
            }`}
            onClick={() => setPanel(!panel)}
          >
            <SettingsIcon className='text-[1.75rem]' />
          </div>
          <div className={`panel px-4 py-4 ${panel ? '' : 'd-none'}`}>
            <div className='mapSelect w-[200px]'>
              {mapUrl && (
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>
                    Harita Türü
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Harita Türü'
                    value={mapUrl}
                    onChange={handleMapChange}
                  >
                    <MenuItem value='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'>
                      Normal
                    </MenuItem>
                    <MenuItem value='https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png'>
                      Topografik
                    </MenuItem>
                    {/* <MenuItem value="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png">Karanlık</MenuItem> */}
                    <MenuItem value='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'>
                      Gerçek
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
        </div>
        {mapUrl && (
          <DynamicMap
            carsData={realTimeData}
            cars={cars}
            mapRef={mapRef}
            markerRef={markerRef}
            focusVehicle={focusVehicle}
            setFocusVehicle={setFocusVehicle}
            mapUrl={mapUrl}
          />
        )}
        <CarDetail
          lastData={realTimeData}
          cars={cars}
          mapRef={mapRef}
          markerRef={markerRef}
          focusVehicle={focusVehicle}
          setFocusVehicle={setFocusVehicle}
        />
      </div>
    </>
  );
};

export default NextDashboard;