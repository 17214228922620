import React, { useEffect, useState } from 'react';
import MapArea from './MapArea';
import ListArea from './ListArea';
import SelectDate from './SelectDate';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ControlArea from './ControlArea';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Speedometer from './Speedometers';

const Default = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let deviceImei = searchParams.get('deviceImei');
  let startDate = searchParams.get('startDate');
  let endDate = searchParams.get('endDate');
  const [responseData, setResponseData] = useState();
  const [selectDate, setSelectDate] = useState({
    start: startDate
      ? moment(startDate, 'YYYY/MM/DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      : moment(new Date()).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    end: endDate
      ? moment(endDate, 'YYYY/MM/DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
      : moment(new Date()).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  });
  const [loader, setLoader] = useState(false);
  const markerRef = React.useRef({});
  const mapRef = React.useRef({});
  const [dateCollapse, setDateCollapse] = useState(false);
  const [counter, setCounter] = useState(1);
  const [collapseSideMenu, setCollapseSideMenu] = useState(false);
  const limits = {
    speed: 50,
    fuelConsumption: 12,
    fuelLevel: 10,
  };

  const handleDateChange = (value) => {
    setSelectDate({
      start: moment(value).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      end: moment(value).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    });
  };


  const [vehiclePlate, setVehiclePlate] = useState(null)

  useEffect(() => {
    getAllDevices();
  }, []);

  const getAllDevices = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/tumaraclar?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
          setVehiclePlate(response.data.find(item => item.vehicleImei == deviceImei).vehiclePlate)
          console.log("vehiclePlate=>",vehiclePlate)
      });
  };

  useEffect(() => {
    setLoader(true);
    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/gpsverisi/tarih?deviceImei=${deviceImei}&startDate=${moment(
          selectDate.start
        ).toISOString()}&endDate=${moment(selectDate.end).toISOString()}`,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then(function (response) {
        setLoader(false);
        setResponseData(response.data);
      });
  }, [selectDate.start, selectDate.end]);

  if (!responseData)
    return (
      <div className='content h-[100vh] relative'>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </div>
    );

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color='inherit' />
      </Backdrop>

      <div className='content h-[100vh] bg-white relative'>
        <div className='flex flex-col h-full'>
          {(responseData.length === 0 && (
            <>
              <div className='h-full flex justify-center items-center bg-gradient-to-b from-slate-100 to-blue-100'>
                <div className='text-center'>
                  <h1 className='text-2xl font-bold'>
                    {moment(selectDate.start).format('YYYY/MM/DD')} Tarihine
                    ilişkin herhangi bir hareket yok
                  </h1>
                  <p className='text-slate-500'>
                    <span
                      className='animate-pulse cursor-pointer select-none text-sky-700'
                      onClick={() => setDateCollapse(!dateCollapse)}
                    >
                      Buraya
                    </span>{' '}
                    tıklayarak ya da sol üst köşeden farklı bir tarih
                    seçebilirsiniz..
                  </p>
                </div>
              </div>
            </>
          )) || (
            <>
              <div className='h-full'>
                <MapArea
                  counter={counter}
                  vehiclePlate={vehiclePlate}
                  setCounter={setCounter}
                  data={responseData}
                  markerRef={markerRef}
                  mapRef={mapRef}
                />
              </div>
            </>
          )}
        </div>
        <SelectDate
          setSelectDate={handleDateChange}
          selectDate={selectDate.start}
          dateCollapse={dateCollapse}
          setDateCollapse={setDateCollapse}
        />
        <ControlArea
          data={responseData}
          counter={counter}
          setCounter={setCounter}
          date={selectDate}
        />
        {responseData.length > 0 && (
          <Speedometer data={responseData} counter={counter} />
        )}
        <div
          className={`boxColor absolute right-2 top-14 w-[300px] z-[999] rounded-sm ${
            collapseSideMenu ? 'h-[85vh]' : 'h-10'
          }`}
        >
          <span
            className='text-center mt-2 block font-cinzel font-bold cursor-pointer select-none'
            onClick={() => setCollapseSideMenu(!collapseSideMenu)}
          >
            Hareket Listesi
            <KeyboardArrowDownIcon
              className={`${
                collapseSideMenu == true ? 'rotate-180' : 'rotate-0'
              } transition-all`}
            />
          </span>
          {collapseSideMenu && (
            <ListArea
              data={responseData}
              limits={limits}
              counter={counter}
              setCounter={setCounter}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Default;
