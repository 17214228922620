import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../redux/hooks/hooks';
import { fetchGroups } from '../redux/actions/groupActions';
import Button from 'react-bootstrap/esm/Button';
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Swal from 'sweetalert2';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'axios';

import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AddIcon from '@mui/icons-material/Add';
import moment from 'moment';
import { Formik, useFormik } from 'formik';
import { departmentSchema } from '../schemas';
import './Groups.css';

const XLSX = require('xlsx');

export default function Groups() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch(); //Define dispatch hook
  const groups = useAppSelector((state) => state.group.groups); //Get all users from the state

  // const [group, setGroup] = useState({ showing: false, data: null });
  const [groupAttachedDevices, setGroupAttachedDevices] = useState([]);
  const [groupAuthorizedPersonel, setGroupAuthorizedPersonel] = useState(
    localStorage.getItem('user')
  );

  const [isActiveChecked, setIsActiveChecked] = useState(false);

  const [responseData, setResponseData] = useState(null);

  //group showing edit ve add için kullanılan modal
  const [group, setGroup] = useState({
    showing: false,
    data: null,
    addModal: false,
  });

  const [editInitialState, setEditInitialState] = useState({
    _id: '',
    groupName: '',
    isActive: false,
  });

  // FORMIK Add Group initialized
  const {
    values: addValues,
    errors: addErrors,
    handleChange: addHandleChange,
    handleSubmit: addHandleSubmit,
    handleBlur: addHandleBlur,
    touched: addTouched,
  } = useFormik({
    initialValues: {
      groupName: '',
      isActive: false,
    },
    onSubmit: (values) => {
      handleAddGroup(values);
    },
    validationSchema: departmentSchema,
  });

  // Formik Edit Vehicles initialized
  const {
    values: editValues,
    errors: editErrors,
    handleChange: editHandleChange,
    handleSubmit: editHandleSubmit,
    handleBlur: editHandleBlur,
    touched: editTouched,
  } = useFormik({
    enableReinitialize: true,
    initialValues: editInitialState,
    onSubmit: (values) => {
      // console.log("Burası => ", values);
      handleEditSubmit(values);
    },
    validationSchema: departmentSchema,
    // enableReinitialize: true, // enableReinitialize özelliği burada belirtilir
  });

  // // FORMIK Add Group initialized
  // const { values, errors, handleChange, handleSubmit } = useFormik({
  //     initialValues: {
  //       groupName: '',
  //       isActive: false,
  //     },
  //     onSubmit: values => {
  //         handleAddGroup(values);
  //     },
  //     validationSchema: departmentSchema,

  // });

  // useEffect(() => {
  //   handleGetGroups();
  // }, []);

  useEffect(() => {
    // console.log(groupAttachedDevices);
  }, [groupAttachedDevices]);

  // const handleGetGroups = async () => {
  //   await axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/tumcihazlar?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: 'Bearer ' + localStorage.getItem('token'),
  //       },
  //     })
  //     .then((response) => setDevices(response.data));
  // };

  const handleClose = () => setGroup({ showing: false, data: null });

  // const getDevices = async () => {
  //   await axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/tumcihazlar?companyName=${localStorage.getItem('userCompany')}&userRole=${localStorage.getItem('userRole')}`, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: 'Bearer ' + localStorage.getItem('token'),
  //       },
  //     })
  //     .then((response) => {
  //       setDevices(response.data);
  //     });
  // };

  const handleAddGroup = async (e) => {
    // e.preventDefault();
    let formData = {
      groupName: e.groupName,
      // isActive: e.target.checkIsActive,
      isActive: e.isActive,
      groupCompany: localStorage.getItem('userCompany'),
      // groupAttachedDevices: groupAttachedDevices,
      // groupAuthorizedPersonel: groupAuthorizedPersonel,
    };

    // console.log(formData);

    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(formData.userEmail)) return;

    //insert işlemi
    await axios
      .post(
        `${
          process.env.REACT_APP_BASE_URL
        }/departman/yeni`,
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      )
      .then(function (response) {
        setResponseData((prevData) => {
          return [...prevData, formData];
        });
        window.location.reload();
      });
  };

  const handleSelectedDevices = (e) => {
    if (e.target.checked) {
      setGroupAttachedDevices((prev) => [...prev, e.target.name]);
    } else {
      groupAttachedDevices.splice(
        groupAttachedDevices.indexOf(e.target.name),
        1
      );
    }
  };

  //Get and Update users array on every render
  useEffect(() => {
    try {
      // getDevices();
      dispatch(fetchGroups());
    } catch (err) {
      console.log(err);
      navigate('/giris');
    }
  }, []);

  // Are u sure delete it function

  const handleRemove = (id) => {
    // Are u sure delete it function
    Swal.fire({
      title: 'Emin misiniz?',
      text: 'Bu işlemi geri alamazsınız!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Vazgeç',
      confirmButtonText: 'Evet, sil!',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_BASE_URL}/departman/sil`,
            {
              groupId: id,
            },
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
              },
            }
          )
          .then(() => {
            Swal.fire(
              'Silindi!',
              'Silme işleminiz gerçekleştirildi.',
              'success'
            ).then(() => window.location.reload());
          });
      }
    });
  };

  //Create Excel File
  let myArr = [];
  const handleExportExcel = async () => {
    groups.map((item) => {
      myArr.push({
        'Departman Adı': item.groupName,
        Aktiflik: item.isActive == true ? 'Aktif' : 'Pasif',
        'Departman Yetkili Personel': item.groupAuthorizedPersonel[0],
        'Firma Adı': item.groupCompany,
      });
    });
    let filename = `${moment(new Date()).format(
      'DD-MM-YYYY HH:mm'
    )} Departmanlar.xlsx`;
    var ws = XLSX.utils.json_to_sheet(myArr);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, filename);
  };

  const handleClickAlert = () => {
    //   alert('adadasd')
    // console.log('adasda');
    return (
      // <Stack sx={{ width: '100%' }} spacing={2}>

      //     <Alert variant="filled" severity="info">
      //         Bu bir demo sürümüdür — aktif değil!
      //     </Alert>

      // </Stack>
      alert('Demo Sürümünde Aktif Değil!')
    );
  };

  const handleEdit = (index) => {
    // console.log("Burası handleEdit", groups[index]);
    // return
    setGroup({ showing: true, index: index });
    // setShow({ visible: true, index: index });
    setEditInitialState({
      _id: groups[index]._id,
      groupName: groups[index].groupName,
      isActive: groups[index].isActive,
    });
    // console.log("EDIT", editInitialState);
  };

  const handleEditSubmit = (e) => {
    // e.preventDefault();
    let formData = {
      groupId: e._id,
      groupName: e.groupName,
      isActive: e.isActive,
    };
    // console.log(formData);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/departman/duzenle`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      .then((response) => {
        if (response) window.location.reload();
      });
  };

  return (
    <>
      {/* Add Group Modal */}
      <Modal
        show={group.addModal}
        onHide={() => setGroup({ ...group, addModal: false })}
        style={{ marginTop: '60px' }}
      >
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
            Yeni Departman Ekle
          </h3>
          <Form onSubmit={addHandleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Departman Adı</Form.Label>
              <Form.Control
                name='groupName'
                type='Text'
                id='groupName'
                placeholder=' Departman Adını Giriniz'
                value={group.data?.groupName ?? addValues.groupName}
                onBlur={addHandleBlur}
                // defaultChecked={group.data?.isActive ? true : false}
                onChange={(e) => {
                  if (group.data?.groupName) {
                    setGroup((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        groupName: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                className={addErrors.groupName ? 'groupNameInputError' : ''}
                // defaultValue={group.data?.groupName ?? ''}
                autoFocus
              />
              {/* {addErrors.groupName && <p className="error"> { addErrors.groupName } </p>} */}

              {addTouched.groupName && addErrors.groupName ? (
                <div>
                  {addErrors.groupName && (
                    <p className='error'> {addErrors.groupName} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Check
                name='isActive'
                type='checkbox'
                id='isActive'
                label='Aktif/Pasif'
                value={group.data?.isActive ?? addValues.isActive}
                // defaultChecked={group.data?.isActive ? true : false}
                onChange={(e) => {
                  if (group.data?.isActive) {
                    setGroup((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        isActive: e.target.value,
                      },
                    }));
                  } else addHandleChange(e);
                }}
                // className={addErrors.isActive ? "isActiveInputError": ""}
              />
            </Form.Group>
            <Form.Group
              className='mb-3 d-flex flex-column'
              controlId='exampleForm.ControlTextarea1'
            ></Form.Group>
            <div className='d-flex justify-content-between '>
              <Button variant='outline-secondary' onClick={handleClose}>
                Vazgeç
              </Button>
              <Button variant='outline-primary' type='submit'>
                Ekle
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/* Add Group Modal */}

      {/* Edit Group Modal */}
      <Modal
        show={group.showing}
        onHide={handleClose}
        style={{ marginTop: '60px' }}
      >
        <Container className='p-12'>
          <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
            Departman Düzenle
          </h3>
          <Form onSubmit={editHandleSubmit}>
            <Form.Group className='mb-3'>
              <Form.Label className='formLabel'>Departman Adı</Form.Label>
              <Form.Control
                name='groupName'
                type='Text'
                id='groupName'
                placeholder=' Departman Adını Giriniz'
                value={group.data?.groupName ?? editValues.groupName}
                onBlur={editHandleBlur}
                // defaultChecked={group.data?.isActive ? true : false}
                onChange={editHandleChange}
                className={editErrors.groupName ? 'groupNameInputError' : ''}
                // defaultValue={group.data?.groupName ?? ''}
                autoFocus
              />
              {/* {editErrors.groupName && <p className="error"> { editErrors.groupName } </p>} */}

              {editTouched.groupName && editErrors.groupName ? (
                <div>
                  {editErrors.groupName && (
                    <p className='error'> {editErrors.groupName} </p>
                  )}
                </div>
              ) : null}
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Check
                name='isActive'
                type='checkbox'
                id='isActive'
                label='Aktif/Pasif'
                value={editValues.isActive}
                checked={editValues.isActive ? true : false}
                onChange={editHandleChange}
                // className={editErrors.isActive ? "isActiveInputError": ""}
              />
            </Form.Group>
            <Form.Group
              className='mb-3 d-flex flex-column'
              controlId='exampleForm.ControlTextarea1'
            ></Form.Group>
            <div className='d-flex justify-content-between '>
              <Button variant='outline-secondary' onClick={handleClose}>
                Vazgeç
              </Button>
              <Button variant='outline-primary' type='submit'>
                Değişiklikleri Kaydet
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
      {/* Edit Group Modal */}

      <div className='content mt-[3.75rem]'>
        <Container>
          <div className='wrapper'>
            <h3 className='font-cinzel text-center mb-4 text-[1.3rem] font-bold relative after:absolute after:bottom-[-3px] after:left-0 after:w-[100%] after:h-[1px] after:bg-red-700'>
              Departmanlar
            </h3>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Button
                // onClick={() => setGroup({ showing: true, data: null })}
                onClick={() => handleExportExcel()}
                color='primary'
                variant='outline-success'
                className='text-[0.75rem] mb-4'
              >
                Excel Dosyası Oluştur <FileDownloadIcon />
              </Button>

              <Button
                onClick={() => setGroup({ ...groups, addModal: true })}
                // onClick={() => handleClickAlert()}
                color='primary'
                variant='outline-primary'
                className='text-[0.75rem] mb-4'
              >
                Yeni Departman Ekle <AddIcon />
              </Button>
            </div>

            {groups ? (
              <Table bordered hover variant=''>
                <thead>
                  <tr>
                    <th style={{ textAlign: '' }}>DEPARTMAN ADI</th>
                    {/* <th style={{ textAlign: '' }}>FİRMA ADI</th> */}
                    <th style={{ textAlign: '' }}>AKTİFLİK</th>
                    {/* <th style={{ textAlign: 'center' }}>Departman Bağlı Cihazlar</th> */}
                    {/* <th style={{ textAlign: '' }}>
                      YETKİLİ PERSONEL
                    </th> */}
                    <th style={{ textAlign: 'center' }}>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {groups.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: 'justify' }}>
                          {item.groupName}
                        </td>

                        {/* <td style={{ textAlign: 'justify' }}>
                          {item.groupCompany}
                        </td> */}

                        <td style={{ textAlign: '' }}>
                          {item.isActive ? 'Aktif' : 'Pasif'}
                        </td>
                        {/* <td style={{ textAlign: 'center' }}>
                          {item.groupAttachedDevices.map((item) => item + '\n')}
                        </td> */}
                        {/* <td style={{ textAlign: '' }}>
                          {item.groupAuthorizedPersonel}
                        </td> */}
                        <td style={{ textAlign: 'center' }}>
                          <OverlayTrigger
                            key={'top1'}
                            placement={'top'}
                            overlay={
                              <Tooltip
                                style={{ fontSize: '12px' }}
                                id={`tooltip-${'top'}`}
                              >
                                Düzenle
                              </Tooltip>
                            }
                          >
                            <Button
                              // onClick={() => handleClickAlert(item._id)}

                              onClick={() => handleEdit(index)}
                              className='p-0'
                              variant='outline-secondary'
                              size='sm'
                              style={{
                                borderStyle: 'none',
                                borderRadius: '5px',
                              }}
                            >
                              <EditIcon className='p-1' />
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            key={'top'}
                            placement={'top'}
                            overlay={
                              <Tooltip
                                style={{ fontSize: '12px' }}
                                id={`tooltip-${'top'}`}
                              >
                                Sil
                              </Tooltip>
                            }
                          >
                            <Button
                              // onClick={() => handleClickAlert(item._id)}

                              className='p-0'
                              variant='outline-danger'
                              size='sm'
                              style={{
                                borderStyle: 'none',
                                borderRadius: '5px',
                              }}
                              onClick={() => handleRemove(item._id)}
                            >
                              <DeleteIcon className='p-1' />
                            </Button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <p>Yükleniyor...</p>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}