import React, { useRef, useState } from 'react'
import {
    MapContainer,
    TileLayer,
    ZoomControl,
    Popup,
    Polygon,
    useMapEvents,
    useMap
} from "react-leaflet";
import L from "leaflet"
import { Button } from '@mui/material';

const MapPolygone = ({ position, setMapOpen, positions, setPositions, updateCoords, centerFunc }) => {

    /* Çizim için gerekli olan değişkenler */
    const [lastPosition, setLastPosition] = useState(null);
    const [draw, setDraw] = useState(false);
    /* Çizim için gerekli olan değişkenler */

    const polygonOptions = {
        color: "red",
        fillColor: "#f03",
        fillOpacity: 0.5
    };
    const polylineOptions = {
        color: "green"
    };


    const complateSelected = (data) => {
        setMapOpen(false);
        setDraw(false);
        updateCoords(positions)
    }

    const lineRef = useRef();

    function MapEvents() {
        const map = useMap();
        const handleClick = (e) => {
            if (draw == true) {
                const newPos = [e.latlng.lat, e.latlng.lng];
                setLastPosition(newPos);
                setPositions([...positions, newPos]);
            }
        };
        const handleDoubleClick = (e) => {
            //Henüz bir pozisyon girilmemiş ise çift tıklayınca başlat
            if (draw == false && positions.length == 0) {
                const newPos = [e.latlng.lat, e.latlng.lng];
                setPositions([...positions, newPos]);
                setLastPosition(newPos);
                setDraw(true);
            } else if (draw == true) { // girilmişse çift tıklama ile bitir
                setDraw(false);
                setLastPosition(null);
                setPositions([...positions, positions[0]]);
                if (lineRef.current) {
                    lineRef.current.removeFrom(map);
                }
            }
        };

        const handleMouseMove = (e) => {
            if (lastPosition) {
                const latlngs = [lastPosition, [e.latlng.lat, e.latlng.lng]];
                if (!lineRef.current) {
                    lineRef.current = L.polyline(latlngs, polylineOptions).addTo(map);
                } else {
                    lineRef.current.setLatLngs(latlngs);
                }
            }
        };

        const handleKeyPress = (e) => {
            if (e.originalEvent.key == "Escape") {
                setPositions([]);
                setLastPosition(null);
                setDraw(false);
                lineRef.current.removeFrom(map);
            }
        }


        useMapEvents({
            click: handleClick,
            dblclick: handleDoubleClick,
            mousemove: handleMouseMove,
            keydown: handleKeyPress,
        });

        return null;
    }

    const removeSelected = () => {
        setPositions([]);
        setLastPosition(null);
        setDraw(false);
    }
    return (
        <MapContainer center={position} zoom={15} className="w-full h-[75vh] cursor-crosshair" doubleClickZoom={false}>
            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <MapEvents />
            {positions.length > 1 && <Polygon positions={positions} pathOptions={polygonOptions}>
                <Popup>
                    <div className="buttons flex gap-2">
                        <div><Button onClick={() => complateSelected(1)} className="cursor-pointer z-20" variant="contained" size="small" color="success">Tamamla</Button></div>
                        <div><Button onClick={() => removeSelected()} className="cursor-pointer z-20" variant="contained" size="small" color="error">Bölgeyi Sil</Button></div>
                    </div>
                </Popup>
            </Polygon>}
            <ZoomControl position="bottomleft" />
        </MapContainer>
    )
}

export default MapPolygone