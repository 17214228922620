import React, { useEffect, useState } from 'react';

import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import BusinessIcon from '@mui/icons-material/Business';
import HistoryIcon from '@mui/icons-material/History';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import Nav from 'react-bootstrap/Nav';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import MapIcon from '@mui/icons-material/Map';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import './Sidebar.css';

const Sidebar = () => {
  useEffect(() => {
    function siblings(el) {
      const siblings = [];
      for (let sibling of el.parentNode.children) {
        if (sibling !== el) {
          siblings.push(sibling);
        }
      }
      return siblings;
    }

    function find(el, selector) {
      let finded;
      return (finded = el.querySelector(selector)) ? finded : null;
    }

    document
      .querySelector('.sidebar .categories')
      .addEventListener('click', function (event) {
        const item = event.target.closest('.has-dropdown');
        if (!item) {
          return;
        }
        /*  */
        item.classList.toggle('opened');

        siblings(item).forEach((sibling) => {
          sibling.classList.remove('opened');
        });

        if (item.classList.contains('opened')) {
          const toOpen = find(item, '.sidebar-dropdown');

          if (toOpen) {
            toOpen.classList.add('active');
          }

          siblings(item).forEach((sibling) => {
            const toClose = find(sibling, '.sidebar-dropdown');

            if (toClose) {
              toClose.classList.remove('active');
            }
          });
        } else {
          find(item, '.sidebar-dropdown').classList.toggle('active');
        }
      });

    document
      .querySelector('.sidebar .close-aside')
      .addEventListener('click', function () {
        document
          .getElementById(`#${this.dataset.close}`)
          .classList.add('show-sidebar');
        let wrapper = document.getElementById('wrapper');
        wrapper.classList.remove('margin');
      });
  });

  //Handle Logout
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    localStorage.removeItem('user');
    localStorage.removeItem('companyLogo');
    navigate('/giris');
  };

  // //Responsive Bar
  // const responsiveBar = () => {
  //   console.log(document.body.offsetWidth);
  //   if (document.body.offsetWidth < 760) {
  //     console.log("760 altına düştü")

  //   }
  // }
  // responsiveBar()

  return (
    <>
      <aside
        className='sidebar position-fixed top-0 left-0 overflow-auto h-100 float-left'
        id='show-side-navigation1'
      >
        <i
          className='uil-bars close-aside d-md-none d-lg-none'
          data-close='show-side-navigation1'
        ></i>
        <div className='sidebar-header d-flex justify-content-center align-items-center px-3 py-4'>
          {localStorage.getItem('companyLogo') ? (
            <img
              className='rounded img-fluid'
              width='30%'
              height='30%'
              src={localStorage.getItem('companyLogo')}
              alt=''
            />
          ) : (
            <img
              className='rounded img-fluid'
              width='65'
              height='65'
              src='https://media.licdn.com/dms/image/C4D0BAQGfzrPUmhfxrQ/company-logo_200_200/0/1675868866719?e=2147483647&v=beta&t=ui20x8RFizVv6oB6fIAe9lPDm5NBtvqQ21gE6DLEqq4'
              alt=''
            />
          )}
          <div className='ms-2'>
            <h5 className='fs-6 mb-0'>
              <Nav.Link className='text-decoration-none' href='#'>
                {localStorage.getItem('user') &&
                  localStorage.getItem('user').split('@')[0].toUpperCase()}
              </Nav.Link>
            </h5>
            <p className='mt-1 mb-0'>{localStorage.getItem('userRole')}</p>
          </div>
        </div>

        <ul className='categories list-unstyled'>
          <li className=''>
            <Nav.Link href='/'>
              <DashboardRoundedIcon /> Dashboard
            </Nav.Link>
          </li>
          {localStorage.getItem('userRole') == 'superadmin' ? (
            <li className=''>
              <Nav.Link href='/kullanicilar'>
                <PeopleRoundedIcon /> Kullanıcılar
              </Nav.Link>
            </li>
          ) : null}
          <li className=''>
            <Nav.Link href='/suruculer'>
              <PersonIcon /> Sürücüler
            </Nav.Link>
          </li>

          {localStorage.getItem('userRole') == 'superadmin' ? (
            <li className=''>
              <Nav.Link href='/firmalar'>
                <BusinessIcon /> Firmalar
              </Nav.Link>
            </li>
          ) : null}
          <li className=''>
            <Nav.Link href='/departmanlar'>
              <WorkspacesIcon /> Departmanlar
            </Nav.Link>
          </li>
          {localStorage.getItem('userRole') == 'superadmin' ? (
            <li className=''>
              <Nav.Link href='/cihazlar'>
                <SettingsRemoteIcon /> Cihazlar
              </Nav.Link>
            </li>
          ) : null}
          <li className=''>
            <Nav.Link href='/araclar/liste'>
              <LocalShippingIcon /> Araç Listesi{' '}
            </Nav.Link>
          </li>
          <li className=''>
            <Nav.Link href='/vardiyalar'>
              <AccessAlarmIcon /> Vardiyalar{' '}
            </Nav.Link>
          </li>
          <li className='has-dropdown'>
            <Nav.Link href='#'>
              {' '}
              <HistoryIcon /> Raporlar
            </Nav.Link>
            <ul className='sidebar-dropdown list-unstyled'>
              <li>
                <Nav.Link href='/arac/raporlar'>
                  <HistoryToggleOffIcon /> Araç Raporları
                </Nav.Link>
              </li>
              {/* <li>
                <Nav.Link href='/vardiya/raporlar'>
                  <HistoryToggleOffIcon /> <span>Araç-Vardiya Rap..</span>
                </Nav.Link>
              </li> */}
              <li>
                <Nav.Link href='/surucu/raporlar'>
                  <HistoryToggleOffIcon /> Sürücü Raporları
                </Nav.Link>
              </li>
            </ul>
          </li>
          <li className=''>
            <Nav.Link href='/arac/bolgetanimlama'>
              <MapIcon /> Bölge Tanımlama{' '}
            </Nav.Link>
          </li>
          {/* <li className=''>
            <Nav.Link href='/arac/gecmis'>
              <ManageHistoryIcon /> Araç Cihaz Geçmişi{' '}
            </Nav.Link>
          </li> */}
          {/* <li className='has-dropdown'>
            <Nav.Link href='#'>
              {' '}
              <HistoryIcon /> Kayıtlar
            </Nav.Link>
            <ul className='sidebar-dropdown list-unstyled'>
              <li>
                <Nav.Link href='/logs'>
                  <HistoryToggleOffIcon /> Cihaz Kayıtları
                </Nav.Link>
              </li>
              <li>
                <Nav.Link href='/gpslogs'>
                  <HistoryToggleOffIcon /> Gps Kayıtları
                </Nav.Link>
              </li>
            </ul>
          </li> */}
          <li className=''>
            <Nav.Link href='/kullanici/ayarlar'>
              <SettingsIcon /> Ayarlar{' '}
            </Nav.Link>
          </li>
          <li className=''>
            <Nav.Link onClick={handleLogout} href='#'>
              <LogoutIcon /> Çıkış
            </Nav.Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
