import devicesTestDataSlice from '../slices/devicesTestDataSlice';
import getAllDevicesTestData from '../../handlers/getAllDevicesTestData';

export const devicesTestDataActions = devicesTestDataSlice.actions;

//Get all devices
export const fetchDevicesTestData = () => {
  return async (dispatch) => {
    const response = await getAllDevicesTestData();
    dispatch(devicesTestDataActions.setDevicesTestData(response));
  };
};