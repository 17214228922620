import axios from 'axios';

//Get All Users
const getAllDevices = async () => {
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/tumcihazlar?userRole=${localStorage.getItem('userRole')}&userCompany=${localStorage.getItem('userCompany')}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => response.data);
};

export default getAllDevices;