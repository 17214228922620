import React from 'react'
import {
    MapContainer,
    TileLayer,
    ZoomControl,
    Popup,
    Marker
} from "react-leaflet";
import L from "leaflet"
import moment from 'moment';
import ReactDOMServer from 'react-dom/server';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import SpeedIcon from '@mui/icons-material/Speed';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';

const MapArea = ({ data, limits, markerRef, mapRef, setFocusIndex }) => {

    const position = [41.2878016, 28.7746];
    let alarmTypes = {
        1: 'Ani Hızlanma',
        2: 'Ani Fren',
        3: 'Sert Dönüş',
    }

    const alarmData = [];
    data.map((item, index) => {
        if (item.alarms) {
            alarmData.push({
                index: index,
                deviceImei: item.deviceImei,
                alarmCode: item.alarms,
                lat: item.latitude,
                lng: item.longitude,
                alarmType: alarmTypes[item.alarms],
                otherData: item,
                date: item.deviceDate,
                color: "#dc3545"
            })
        }
        if (item.vehiclespeed > limits.speed) {
            alarmData.push({
                index: index,
                deviceImei: item.deviceImei,
                lat: item.latitude,
                lng: item.longitude,
                alarmType: "Hız Limiti Aşıldı",
                alarmCode: 0,
                alarm: item.vehiclespeed,
                type: "Km/s",
                otherData: item,
                date: item.deviceDate,
                color: "#fd7e14"
            })
        }

        if (parseFloat(item.fuelconsumption) > limits.fuelConsumption) {
            alarmData.push({
                index: index,
                deviceImei: item.deviceImei,
                lat: item.latitude,
                lng: item.longitude,
                alarmType: "Yakıt Tüketimi",
                alarm: 4,
                alarmCode: item.fuelconsumption,
                type: "L/s",
                date: item.deviceDate,
                otherData: item,
                color: "#ffc107"
            })
        }

        if (item.fuellevel < limits.fuellevel) {
            alarmData.push({
                index: index,
                deviceImei: item.deviceImei,
                lat: item.latitude,
                lng: item.longitude,
                alarmType: "Yakıt Seviyesi",
                alarm: item.fuellevel,
                otherData: item,
                alarmCode: 5,
                type: "%",
                date: item.deviceDate,
                color: "#28a745"
            })
        }
    })
    const setIcon = (item) => {
        /* item.alarm
            0 -> hız
            1 -> Ani hızlanma
            2 -> Ani Fren
            3 -> Sert Dönüş
            4 -> yakıt tüketimi
            5 -> fuel level
        */
        const gasIcon = <LocalGasStationOutlinedIcon />;
        const turnIcon = <UTurnLeftIcon />
        const speedIcon = <SpeedIcon />
        const breakIcon = <TaxiAlertIcon />
        //ReactDOMServer.renderToString(iconJSX)
        const svgCode = `
        <div style="width:32px; height:32px; font-size:10px; border:3px solid #fff; font-weight: bold; border-radius:50%; background:${item.color}; color:#fff; display:flex; justify-content: center; align-items:center;">
            <span>
            ${item.alarmCode === 0 ? ReactDOMServer.renderToString(speedIcon) :
                item.alarmCode === 1 ? ReactDOMServer.renderToString(speedIcon) :
                    item.alarmCode === 2 ? ReactDOMServer.renderToString(breakIcon) : 
                        item.alarmCode === 3 ? ReactDOMServer.renderToString(turnIcon) :
                            item.alarmCode === 4 ? ReactDOMServer.renderToString(gasIcon) :
                                item.alarmCode === 5 ? ReactDOMServer.renderToString(gasIcon) : ""
            }
            </span>
        </div>
        `;

        // Özelleştirilmiş SVG öğesini oluşturun
        const customIcon = L.divIcon({
            html: svgCode,
            className: "transition duration-500 ease-all",
            popupAnchor: [0, -15],
            iconSize: [32, 32],

        });
        return customIcon;
    }

    function handleMarkerClick(e, item) {

        if (mapRef.current) {
            let markerPosition = [e.target.getLatLng().lat, e.target.getLatLng().lng];
            mapRef.current.setView(markerPosition, 16, { AnimationEffect: true, AnimationDuration: 1, pan: { animate: true, duration: 1 } });
            setFocusIndex(item.index);
        }
    }


    return (
        <>
            <MapContainer center={position} zoom={14} className="w-full h-full cursor-crosshair" doubleClickZoom={false} ref={mapRef}>
                <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
                <ZoomControl position="bottomleft" />
                {alarmData.map((item, index) => {

                    return (
                        <Marker
                            icon={setIcon(item)}
                            position={[item.lat, item.lng]}
                            key={index}
                            //animate={true} key={car.deviceImei}
                            eventHandlers={{ click: e => handleMarkerClick(e, item) }}
                            ref={(marker) => (markerRef.current[item.index] = marker)}
                        >
                            <Popup>
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <div className="font-bold">Alarm Tipi:</div>
                                        <div className="ml-2">{item.alarmType}</div>
                                    </div>
                                    {item.alarm &&
                                        <div className="flex flex-row">
                                            <div className="font-bold">Değer:</div>
                                            <div className="ml-2">{parseInt(item.alarm) + item.type}</div>
                                        </div>

                                    }
                                    <div className="flex flex-row">
                                        <div className="font-bold">Tarih:</div>
                                        <div className="ml-2">{moment(item.date).format("YYYY-MM-DD HH:mm")}</div>
                                    </div>
                                    <hr className='my-2' />
                                    <div className="flex flex-row">
                                        <div className="font-bold">Hız:</div>
                                        <div className="ml-2">{parseInt(item.otherData.vehiclespeed)} Km/s</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="font-bold">Devir:</div>
                                        <div className="ml-2">{parseInt(item.otherData.enginecycle)} Rpm</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="font-bold">Yakıt:</div>
                                        <div className="ml-2">{parseInt(item.otherData.fuellevel)}%</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="font-bold">Gaz pedal:</div>
                                        <div className="ml-2">{parseInt(item.otherData.gaspedalposition)}%</div>
                                    </div>
                                    <div className="flex flex-row">
                                        <div className="font-bold">Tork / Yük:</div>
                                        <div className="ml-2">{parseFloat(item.otherData.enginetorque)}% / {parseFloat(item.otherData.engineload)}%</div>
                                    </div>
                                </div>
                            </Popup>
                        </Marker>
                    )
                })
                }

            </MapContainer>
        </>
    )
}

export default MapArea