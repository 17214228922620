import React, { useState, useEffect, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';
import './Login.css';
import { color } from '@mui/system';
import { Backdrop, CircularProgress } from '@mui/material';

export default function Login() {
  const [userEmail, setUserEmail] = useState(null);
  const [userPassword, setUserPassword] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const buttonRef = useRef();

  useEffect(() => {
    if (localStorage.getItem('token')) navigate('/');
  }, []);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        buttonRef.current.click();
      }
    };
    document.addEventListener('keydown', listener);
  }, []);

  const handleIPLog = async (userIPAdress, token) => {
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/kayitlar/kullaniciip`,
      {
        userEmail: userEmail,
        loginDate: new Date(),
        userIPAdress: userIPAdress,
      },
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + token,
        },
      }
    );
    return setLoader(false);
  };

  const handleClick = async () => {
    try {
      setLoader(true);
      await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/giris`,
          {
            userEmail: userEmail,
            userPassword: userPassword,
          },
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )
        .then(async (response) => {
          console.log("login=>",response);
          localStorage.setItem('token', response.data.token);
          localStorage.setItem('userRole', response.data.userRole);
          localStorage.setItem('user', response.data.userEmail);
          localStorage.setItem('companyLogo', response.data.companyLogo);
          localStorage.setItem('userCompany', response.data.userCompany);
          let userIPAdress = await axios
            .get('https://api.my-ip.io/ip.json')
            .then((res) => {
              return res.data.ip;
            });
          handleIPLog(userIPAdress, response.data.token);
          navigate('/');
        })
        .catch((err) => {
          if (!err.response.data.success) {
            navigate('/giris');
            setLoader(false);
          }
        });
    } catch (err) {
      navigate('/giris');
      setLoader(false);
    }
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setUserEmail(email);
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setUserPassword(password);
  };

  const myStyle = {
    backgroundImage:
      "url('https://www.indyturk.com/sites/default/files/styles/1368x911/public/article/main_image/2021/11/04/791066-1404529847.jpg?itok=R9-HHqcY')",
    height: '100vh',
    marginTop: '-70px',
    fontSize: '5px',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  const isButtonDisabled = !userEmail || !userPassword; // Eposta veya şifre boş ise butonu devre dışı bırakır.

  return (
    <>
      <div
        className='container d-flex justify-content-center align-items-center'
        style={{ height: '100vh' }}
      >
        <div className='header'>
          <div className='inner-header flexx'></div>
          <div className=''>
            <svg
              className='waves'
              xmlns='http://www.w3.org/2000/svg'
              xmlnsXlink='http://www.w3.org/1999/xlink'
              viewBox='0 24 150 28'
              preserveAspectRatio='none'
              shapeRendering='auto'
            >
              <defs>
                <path
                  id='gentle-wave'
                  d='M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z'
                />
              </defs>
              <g className='parallax'>
                <use
                  xlinkHref='#gentle-wave'
                  x='48'
                  y='0'
                  fill='rgba(255,255,255,0.7'
                />
                <use
                  xlinkHref='#gentle-wave'
                  x='48'
                  y='3'
                  fill='rgba(255,255,255,0.5)'
                />
                <use
                  xlinkHref='#gentle-wave'
                  x='48'
                  y='5'
                  fill='rgba(255,255,255,0.3)'
                />
                <use xlinkHref='#gentle-wave' x='48' y='7' fill='#fff' />
              </g>
            </svg>
          </div>
        </div>

        <div className='logo'>
          <img
            src='\images\logo4en.png'
            className='img-fluid'
            alt=''
            srcSet=''
          />
        </div>

        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loader}
        >
          <CircularProgress color='inherit' />
        </Backdrop>

        <Form>
          <Form.Group
            className='mb-3 inputBox bdy'
            controlId='formBasicEmail'
          >
            <div className='box'>
              <h2 className='text-[1.5rem] font-bold Lato'>GİRİŞ</h2>
              <div className='inputBox'>
                <input
                  type='text'
                  name=''
                  required
                  onChange={handleEmailChange}
                />
                <label>Email adresi</label>
              </div>
              <div className='inputBox'>
                <input
                  type='password'
                  name=''
                  required
                  onChange={handlePasswordChange}
                />
                <label>Şifre</label>
              </div>
              <Button
                variant='contained'
                ref={buttonRef}
                className='bg-red-200'
                onClick={handleClick}
                disabled={isButtonDisabled} // Butonu devre dışı bırakan prop
              >
                Giriş
              </Button>
            </div>
          </Form.Group>
        </Form>
      </div>
    </>
  );
}
