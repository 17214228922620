import React, { useEffect, useState } from 'react'
import MapArea from './MapArea'
import ListArea from './ListArea'
import SelectDate from './SelectDate'
import axios from 'axios'
import { useSearchParams } from 'react-router-dom'
import moment from 'moment'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Default = () => {

    const [searchParams] = useSearchParams();
    let deviceImei = searchParams.get('deviceImei');
    const [responseData, setResponseData] = useState();
    const [selectDate, setSelectDate] = useState(new Date())
    const [loader, setLoader] = useState(false)
    const markerRef = React.useRef({});
    const mapRef = React.useRef({});
    const [focusIndex, setFocusIndex] = useState();
    const [dateCollapse, setDateCollapse] = useState(false);


    const limits = {
        speed: 50,
        fuelConsumption: 13,
        fuelLevel: 10
    }

    useEffect(() => {
        setLoader(true)
        axios
            .post(`${process.env.REACT_APP_BASE_URL}/alarmlar/gecmis`, { deviceImei, select: 'all', startDate: moment(selectDate).format('YYYY-MM-DD 00:00:00'), endDate: moment(selectDate).format('YYYY-MM-DD 23:59:59') },
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization:
                            'Bearer ' + localStorage.getItem('token'),
                    },
                })
            .then(function (response) {
                setLoader(false)
                setResponseData(response.data);
            });
    }, [deviceImei, selectDate]);


    if (!responseData)
        return (
            <div className="content h-[100vh] relative">
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true} >
                    <CircularProgress color='inherit' />
                </Backdrop>
            </div>
        );

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color='inherit' />
            </Backdrop>


            <div className='content h-[100vh] bg-white relative'>
            <div className="flex flex-col h-full">
                {responseData.length === 0 ? (
                    <>
                        <div className="h-full flex justify-center items-center bg-gradient-to-b from-slate-100 to-blue-100">
                            <div className="text-center">
                                <h1 className="text-2xl font-bold">{moment(selectDate).format("YYYY/MM/DD")} Tarihine ilişkin herhangi bir ihlal henüz gerçekleşmedi.</h1>
                                <p className="text-slate-500">
                                    <span className='animate-pulse cursor-pointer select-none text-sky-700' onClick={() => setDateCollapse(!dateCollapse)}>Buraya</span> tıklayarak ya da sol üst köşeden farklı bir tarih seçebilirsiniz..
                                </p>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="h-3/5">
                            <MapArea data={responseData} markerRef={markerRef} mapRef={mapRef} setFocusIndex={setFocusIndex} limits={limits} />
                        </div>

                        <div className="h-2/5 overflow-scroll">
                            <ListArea markerRef={markerRef} mapRef={mapRef} focusIndex={focusIndex} setFocusIndex={setFocusIndex} data={responseData} limits={limits} />
                        </div>
                    </>
                )}
            </div>

                <SelectDate setSelectDate={setSelectDate} selectDate={selectDate} dateCollapse={dateCollapse} setDateCollapse={setDateCollapse} />
            </div>
        </>
    )
}

export default Default