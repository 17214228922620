import axios from 'axios';

//Get all users from the api
const getAllUsers = async () => {
  return await axios
    .get(`${process.env.REACT_APP_BASE_URL}/kullanicilar`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    .then((response) => response.data);
};

export default getAllUsers;
