import React from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DayPicker } from 'react-day-picker';
import { subDays } from 'date-fns';
import { tr } from 'date-fns/locale';
import 'react-day-picker/dist/style.css';


const SelectDate = ({ selectDate, setSelectDate, dateCollapse, setDateCollapse, handleDateChange }) => {

    const modifiersStyles = {
        selected: {
            color: 'white',
            backgroundColor: '#0d6dfd',
        },
    };

    // const pastMonth = new Date();
    // const defaultSelected = {
    //   from: subDays(pastMonth, 4),
    //   to: pastMonth,
    // };


    const handleSelect = (day) => {
        setSelectDate(day)
    }

    let footer = (
        <div className='footer flex gap-2'>
          <button
            className='btn btn-secondary mt-5 mb-1 w-100 text-[1em] px-2 py-1'
            onClick={() => setSelectDate({})}
          >
            Sıfırla
          </button>
          <button
            className='btn btn-danger mt-5 mb-1 w-100 text-[1em] px-2 py-1'
            onClick={() => setDateCollapse(false)}
          >
            İptal
          </button>
          <button
            className='btn btn-success mt-5 mb-1 w-100 text-[1em] px-2 py-1'
            onClick={() => handleDateChange(selectDate)}
          >
            Tamamla
          </button>
        </div>
      );

    return (
        <div className="relative z-[500] nerdesin">
            <div onClick={() => setDateCollapse(!dateCollapse)} className={`bg-white w-[40px] h-[40px] rounded-lg shadow-lg flex justify-center items-center cursor-pointer ${dateCollapse ? "text-lime-600" : "text-red-600"}`} ><CalendarMonthIcon /></div>
            <div className={`absolute top-0 left-10 bg-white rounded-lg shadow-lg ${dateCollapse ? "h-auto w-auto" : "h-0 overflow-hidden"}`}>
                <DayPicker
                locale={tr}
                    mode='range'
                    modifiersStyles={modifiersStyles}
                    modifiers={selectDate}
                    selected={selectDate}
                    onSelect={handleSelect}
                    footer={footer}
                />
            </div>
        </div>
    )
}

export default SelectDate