import React from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DayPicker } from 'react-day-picker';
import styles from 'react-day-picker/dist/style.module.css';
import moment from 'moment'
import 'react-day-picker/dist/style.css';
import { tr } from 'date-fns/locale';


const SelectDate = ({selectDate, setSelectDate, dateCollapse , setDateCollapse}) => {

  const handleSelect = (day) => {
    setSelectDate(moment(day).local().format('YYYY-MM-DD'))
    setDateCollapse(false)
  }
  const modifiersStyles = {
    selected: {
      color: 'white',
      backgroundColor: '#0d6dfd',
    },
  };

  const classNames = {
    ...styles,
    head: 'custom-head',
    disabled: 'custom-disabled',
  };

  return (
    <div className="absolute top-16 left-6 z-[500]">
      <div onClick={() => setDateCollapse(!dateCollapse)} className={`bg-white w-[40px] h-[40px] rounded-lg shadow-lg flex justify-center items-center cursor-pointer ${dateCollapse ? "text-lime-600 animate-none" : "text-red-600 animate-bounce"}`} ><CalendarMonthIcon /></div>
      <div className={`absolute top-0 left-10 bg-white rounded-lg shadow-lg ${dateCollapse ? "h-auto w-auto" : "h-0 overflow-hidden"}`}>
        <DayPicker
        locale={tr}
        classNames={classNames}
          mode="single"
          selected={new Date(selectDate)}
          onSelect={handleSelect}
          modifiersStyles={modifiersStyles}
          disabled={{ before: new Date('2023-01-01'), after: new Date() }}
        />
      </div>
    </div>
  )
}

export default SelectDate